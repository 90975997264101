<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "vue-loading-overlay";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "KeyWordAuthSuccess",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      error: "",
      passFlg: true,
      login_id: this.$route.params.login_id,
      keyword: "",
      user_kbn: this.$route.query.user_kbn,
      url_cd: this.$route.query.url_cd,
      loginScreen: "",
      errors: [],
      error_message: '',
      error_message_no: '',
      errorStatus: false,
      successStatus: false,
      succsssMessageNo: '',
      successMessage: '',
      systemInitialDate: '',
      isLoading: false,
    };
  },
  mounted() {
    this.user_kbn = this.$route.params.user_kbn;
    this.getSystemInitialDate();
    this.updateUserKbn();
  },
  methods: {
    updateUserKbn: function () {
      if (this.user_kbn) {
        localStorage.setItem("USER_KBN", this.user_kbn);
        switch (this.user_kbn) {
          case "1":
            this.loginScreen = "PLogin";
            break;
          case "2":
            this.loginScreen = "SLogin";
            break;
          case "3":
            this.loginScreen = "TLogin";
            break;
        }
      } else {
        this.loginScreen = "SLogin";
      }
    },
    getSystemInitialDate() {
      apiService.GetSystemInitialDate()
        .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
            }
        })
        .catch(error => {
          console.log(error)
      });
    }
  },
};
</script>

<template>
  <AppLayout>
    <loading :active.sync="isLoading" :is-full-page="true">
      <div style="vertical-align: middle">
        <img
          src="@/assets/loader.gif"
          style="dipslay: inline-block; float: left"
        />
        <h4 style="dipslay: inline-block; float: left; color: #3399f3">
          読み込み中...
        </h4>
      </div>
    </loading>
    <main class="contents-height pt-sm-3">
      <div id="contentTop"></div>
      <div class="container text-center">
        <h4 class="font-weight-bold text-black">キーワード認証成功画面</h4>
      </div>
      <br>
      <div class="container rounded text-center">
        <h5 class="font-weight-normal text-black">
          キーワード認証が完了しました。<br>
          以下のリンクからログイン画面に移動してください。
        </h5>
        <div class="form-signin">
          <router-link
            :to="{ name: loginScreen }"
            class="font-weight-normal text-primary d-block text-center"
            style="font-size: 18px"
          >
            ログイン画面へ
          </router-link>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
