<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import moment from 'moment';
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default {
    name: "SSTAYPRINT",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        screenData: {
          account: {},
          content: {},
        },
        jyukoUser: {
          login_id: "",
          nendo: "",
          kensyu_cd: "",
          kaisai_no: ""
        }

      };
    },
    mounted() {
      this.getInitialScreenData();
    },
    methods: {
      confirm: function () {
        this.isLoading = true;
        var request_data =
        {
          login_id: this.jyukoUser.login_id,
          nendo: this.jyukoUser.nendo,
          kensyu_cd: this.jyukoUser.kensyu_cd,
          kaisai_no: this.jyukoUser.kaisai_no
        };
        apiService.SStayPrintConfirm(request_data)
          .then(result => {
            if (result.status == 'error') {
              alert(result.message_no + " " + result.message)
              this.isLoading = false;
            }
            else {
              this.isLoading = false;
              this.$router.push({ name: "SSTAYREGIST" });
            }
          })
      },
      clickPrint: function () {
        this.isLoading = true;
        var request_data =
        {
          login_id: this.jyukoUser.login_id,
          nendo: this.jyukoUser.nendo,
          kensyu_cd: this.jyukoUser.kensyu_cd,
          kaisai_no: this.jyukoUser.kaisai_no
        };
        apiService.PSStayCheck001exportExcel(request_data)
          .then(result => {
            var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
            this.download(result, '宿泊予約内容結果（受講者用）' + date + '.xlsx');
          }).catch(error => {
            console.log(error);
            this.isLoading = false;
          });
      },
      getInitialScreenData() {
        this.isLoading = true;
        apiService.SStayPrintInitialScreenData()
          .then(result => {
            if (result.status == 'success') {
              if(result.uketukegai == true){
                this.$router.push({
                  name: "SSTAYMANUAL",
                });
              }
              this.screenData.account = result.account;
              this.screenData.content = result.screen;

              this.jyukoUser.login_id = result.account.login_id;
              this.jyukoUser.nendo = result.account.nendo;
              this.jyukoUser.kensyu_cd = result.account.kensyu_cd;
              this.jyukoUser.kaisai_no = result.account.kaisai_no;
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      download(result, fileName) {
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(new Blob([result]), fileName);
          this.isLoading = false;
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        }
      }
    }
  };
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3">
      <div class="container" style="margin-top: 20px">
        <div class="row progress_row">
          <div class="subCell col-4 text-center" style="border-right: 3px solid #fff">
            予約申込
          </div>
          <div class="subCell col-4 text-center" style="border-right: 3px solid #fff">
            予約確認
          </div>
          <div class="mainCell col-4 text-center bg-info text-white">予約完了</div>
        </div>
        <div class="row text-danger" style="margin-top: 20px">
          <div class="col-lg-2 col-0"></div>
          <div class="col-lg-9 col-12">
            ※予約受付完了しました。決定通知に記載の期限までに下記の口座に料金をお振込みください。<br />
            予約内容を確認するため、この画面を印刷又は画像をコピーして保存することをお勧めします。
          </div>
          <div class="col-lg-1 col-0"></div>
        </div>
        <div class="row" style="padding: 15px">
          <div class="col-lg-6 col-12 card" style="margin: 0 auto; padding: 0">
            <div class="mainContainer container" style="border-radius: 0.25rem">
              <div class="row row-bank-info">
                <div class="col-4 pr-0">銀行名</div>
                <div class="col-8">{{screenData.account.ginko_name}}</div>
              </div>
              <div class="row row-bank-info">
                <div class="col-4 pr-0">口座番号</div>
                <div class="col-8">{{screenData.account.account_no}}</div>
              </div>
              <div class="row row-bank-info">
                <div class="col-4 pr-0">口座名義人</div>
                <div class="col-8">
                  <ruby>
                    {{screenData.account.nominee_name}}
                    <rt>{{screenData.account.nominee_kana}}</rt>
                  </ruby>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="mainTable" class="row" style="margin-top: 20px">
          <div class="container" style="padding: 0 30px;">
            <div class="row mb-2">
              <div class="col-12 text-left" style="padding: 0; margin-bottom: 3px">
                <label class="mb-0" style="padding-top: 10px">
                  予約日：{{screenData.content.renewal_date}}
                </label>
                <button class="btn btn-outline-primary float-right" @click="clickPrint">
                  宿泊予約内容出力
                </button>
              </div>
            </div>
            <div class="row">
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                受講者番号
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.jyuko_no}}
              </div>
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                性別
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.jyuko_seibetu}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                カナ氏名
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.jyuko_kana}}
              </div>
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                研修コード
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.kensyu_cd}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                氏名
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.jyuko_name}}
              </div>
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                研修名
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.kensyu_name}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                都道府県市
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.jyuko_address}}
              </div>
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                所属
              </div>
              <div class="
                  subCell
                  col-md-4 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.shozoku_name}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  warnCell
                  col-md-2 col-12
                  bg-warning
                  text-white text-center
                  table_height
                ">
                研修期間
              </div>
              <div class="
                  subCell
                  col-md-10 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.kaisai_date}}
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 20px">
          <div class="container" style="padding: 0 30px;">
            <div class="row">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                宿泊期間
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.syku_riyo_date}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                前日泊
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.zenhaku_umu_str}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                宿泊室タイプ
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.syku_type_str }}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                復路直通バス利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.bus_riyo_str}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                駐車場利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.parking_riyo_str}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                駐車場利用期間
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.parking_riyo_date}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                車のナンバー
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.content.car_no}}
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary table_height" style="height: auto">
                <div class="text-light text-center" style="margin: 0 auto">
                  連絡先
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light">
                <div class="container" style="padding: 0px">
                  <div class="row table_height">
                    <div class="col-md-5 col-5">勤務先TEL</div>
                    <div class="col-md-7 col-7">{{screenData.content.kinmusaki_tel}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">(備考)</div>
                    <div class="col-md-7 col-7 break-word">{{screenData.content.kinmusaki_tel_biko}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">勤務先FAX</div>
                    <div class="col-md-7 col-7">{{screenData.content.kinmusaki_fax}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary table_height" style="height: auto">
                <div class="text-light text-center" style="margin: 0 auto">
                  料金
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light">
                <div class="container" style="padding: 0px">
                  <div class="row table_height">
                    <div class="col-md-5 col-5">宿泊料</div>
                    <div class="col-md-7 col-7">{{screenData.content.syku_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">駐車場利用料</div>
                    <div class="col-md-7 col-7">{{screenData.content.parking_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">復路バス利用料</div>
                    <div class="col-md-7 col-7">{{screenData.content.bus_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">合計</div>
                    <div class="col-md-7 col-7">{{screenData.content.total_ryokin}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 20px">
          <div class="container text-danger" style="padding: 0 30px;">
            ※予約受付後内容の修正は、部屋タイプ以外は可能です。<br />
            キャンセルがある場合は、下記までご連絡ください。<br />
            連絡先：029-879-6673(9:00～17:00)
          </div>
        </div>

        <div class="row" style="margin: 30px 0">
          <div style="max-width: 300px; margin: 0 auto">
            <button class="btn btn-primary" role="button" style="width: 140px" @click="confirm">
              変更
            </button>

            <router-link :to="{ name: 'SSTAYMANUAL' }" class="">
              <button class="btn btn-secondary ml-2" role="button" style="width: 140px">
                戻る
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row_height {
    line-height: 50px;
  }

  .text-car-num {
    width: 75px !important;
    margin-left: 5px;
  }

  .date-style {
    width: 142px;
  }

  .text-required {
    color: #ff4342 !important;
  }

  .progress_row {
    margin-top: 10px;
    background-color: #eee;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }

  .row-bank-info {
    line-height: 40px;
  }
</style>
