<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "SENQUETEKENSYU",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      login_id: '',
      nendo: '',
      kensyu_cd: '',
      kaisai_no: '',
      kensyumei: '',
      fields: [
        {
          key: "ansflg",
          label: "回答状況",
          sortable: true,
          class: "text-center",
          thClass: "text-nowrap",
        },
        {
          key: "titlename",
          label: "タイトル",
          sortable: true,
          class: "text-nowrap",
        },
        {
          key: "kaitokikan",
          label: "回答期間",
          sortable: true,
          class: "text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: "kaito",
          label: "アンケート回答",
          sortable: true,
          class: "text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: "file",
          label: "回答ダウンロード",
          sortable: true,
          class: "text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: 'kaito_flg',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'nendo',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'kensyu_cd',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'kaisai_no',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'anketo_kbn',
          thClass: 'd-none',
          tdClass: 'd-none'
        }
      ],
      items: [],
      kaito_flg: '',
      hidden_data: [],
      selected: '',
      title_name: '',
      setsumonInfo: '',
      selectedKaitoInfo: '',
      successStatus: false,
      successMessage: '',
      succsssMessageNo: '',
      errorStatus:false,
      error_message:'',
      error_message_no :'',
      jyuko_no :'',
      jyuko_name :'',
      isDisable: true,
      oldRequestData: {}
    };
  },
  mounted() {
    this.nendo = this.$store.state.Header.sessionData['nendo'];
    this.kensyu_cd = this.$store.state.Header.sessionData['kensyu_cd'];
    this.kaisai_no = this.$store.state.Header.sessionData['kaisai_no'];
    this.login_id = this.$store.state.Header.sessionData['login_id'];
    this.getKensyuName();
    this.getEnqueteList();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
  },
  methods: {
    getKensyuName() {
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        login_id: this.login_id
      }
      apiService.getSEnqueteKensyuName(request_data)
      .then(result => {
        if(result.status=='success') {
          if(result.kensyuName[0].kaisai_name) {
            this.kensyumei = result.kensyuName[0].kensyu_name + '　' + result.kensyuName[0].kaisai_name;
          } else {
            this.kensyumei = result.kensyuName[0].kensyu_name;
          }
          this.jyuko_no = result.kensyuName[0].jyuko_no;
          this.jyuko_name = result.kensyuName[0].jyuko_name;
        }else {
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    getEnqueteList() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        login_id: this.login_id,
      }
      apiService.getSEnqueteList(request_data)
      .then(result => {
        if(result.status=='success') {
          var vm = this;
          let kaito_joho;
          let kaitoflg;
          result.enqueteList.forEach(function(data) {
            if(data.entry_date == null) {
              kaito_joho = '未';
              kaitoflg = 0;
            } else {
              kaito_joho = '済';
              kaitoflg = 1;
            }
            var enqueteList = {
              selected: '',
              ansflg: kaito_joho,
              titlename: data.title_name,
              kaitokikan: data.kaitokikan,
              kaito_flg: kaitoflg,
              nendo: data.nendo,
              kensyu_cd: data.kensyu_cd,
              kaisai_no: data.kaisai_no,
              anketo_kbn: data.qu_kbn,
              simekiri_flg: data.simekiri_flg,
              file: data.qu_kbn
            }
            vm.items.push(enqueteList);
          });
        }
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    onRowSelected(items) {
      if(items.length != 0) {
        this.isLoading = true;
        if (items[0].simekiri_flg == '1') {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
        this.hidden_data = {
          kaito_flg: items[0].kaito_flg,
          nendo: items[0].nendo,
          kensyu_cd: items[0].kensyu_cd,
          kaisai_no: items[0].kaisai_no,
          anketo_kbn: items[0].anketo_kbn
        }
        this.title_name = items[0].titlename;
        var request_data = {
          nendo: items[0].nendo,
          kensyu_cd: items[0].kensyu_cd,
          kaisai_no: items[0].kaisai_no,
          anketo_kbn: items[0].anketo_kbn,
          kaito_flg: items[0].kaito_flg,
          login_id: this.login_id,
        }
        apiService.getAnketoSetsumonInfo(request_data)
        .then(result => {
          if(result.status=='success') {
            this.setsumonInfo = result.setumonInfo;
            this.setOldRequestData()
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      } else {
        this.title_name = '';
        this.setsumonInfo = '';
        this.oldRequestData = {};
        this.isDisable = true;
      }
    },
    clickCheckbox: function(index, i, e) {
      var type = e.target.getAttribute("type");
      var data_checked = e.target.getAttribute("data-checked");
      if ( type == 'checkbox') {
        if (data_checked == 1) {
          e.target.setAttribute("data-checked", 0);
          this.setsumonInfo[index][i].sentaku_umu = 0;
        } else {
          e.target.setAttribute("data-checked", 1);
          this.setsumonInfo[index][i].sentaku_umu = 1;
        }
      } else {
        var kaitoInfo = Object.values(this.setsumonInfo[index]);
        kaitoInfo.map((value) => {
          if (value.select_type == "R" || value.select_type == "RT") {
            value.sentaku_umu = 0;
          }
        });
        this.setsumonInfo[index][i].sentaku_umu = 1;
      }
    },
    downloadPSEnqueteKensyu001Excel(selectitems) {
      this.successStatus = false;
      this.errorStatus = false;
      let request_data_array = [];

      if (selectitems.length == 0 || selectitems.kaito_flg == 0) {
        this.errorStatus = true;
        this.error_message = this.$messages.MSG['E1055'];
        this.error_message_no = 'E1055';
        return;
      }

      this.isLoading = true;

      request_data_array[0] = {
        qu_kbn   : selectitems.anketo_kbn,
      };

      let request_data = {
        nendo    : this.nendo,
        kensyuCd : this.kensyu_cd,
        kaisaiNo : this.kaisai_no,
        request_data_array
      }
      apiService.PSEnqueteKensyu001exportExcel(request_data)
        .then(result => {
          this.downloadExcel(result,'研修全般アンケート回答_' + request_data['kensyuCd'] + this.jyuko_no + this.jyuko_name + '.xlsx');
        }).catch(error => {
          this.blogErrorMessages(error);
        });
    },
    downloadExcel(result, fileName) {
      this.isLoading = false;
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    blogErrorMessages(error){
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      ) {
          errormessage = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              error.data = JSON.parse(reader.result);
              resolve(Promise.reject(error));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.data);
          })
          .then(err => {
            console.log(err.data)
          });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        this.errors = '';
        this.errorStatus = true;
        this.error_message = error.data.message.replaceAll("|","\n");
        this.error_message_no = error.data.message_no;
        this.successStatus = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
    regist() {
      var answer = window.confirm(this.$messages.MSG['I3006']);
      if (answer) {
        this.isLoading = true;
        var request_data = {
          login_id: this.login_id,
          kaito_flg: this.hidden_data.kaito_flg,
          nendo: this.hidden_data.nendo,
          kensyu_cd: this.hidden_data.kensyu_cd,
          kaisai_no: this.hidden_data.kaisai_no,
          anketo_kbn: this.hidden_data.anketo_kbn,
          kaito: this.setsumonInfo
        }
        apiService.SEnqueteKensyuRegist(request_data)
        .then(result => {
          if(result.status=='success') {
            this.successStatus    = true;
            this.errorStatus      = false;
            this.succsssMessageNo = result.message_no;
            this.successMessage   = result.message.replaceAll("|","\n");
            this.items = [];
            this.getKensyuName();
            this.getEnqueteList();
            this.setOldRequestData()
          } else {
            this.successStatus    = false;
            this.errorStatus      = true;
            this.error_message_no = result.message_no;
            this.error_message   = result.message.replaceAll("|","\n");
            this.isLoading = false;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      }
    },
    setOldRequestData() {
      this.oldRequestData 
        = JSON.parse(JSON.stringify({
          anketo: this.setsumonInfo,
        }))
    },
    checkInputChange() {
      var org = JSON.stringify(this.oldRequestData, jsonReplace);
      if (org === '{}') {
        return true;
      }
      var dest = JSON.stringify({
        anketo: this.setsumonInfo,
      }, jsonReplace);
      return org === dest;
    },
  },
  beforeRouteLeave(to, from, next) {
    var input_check = this.checkInputChange();
    var answer = true;
    if (to.params.UnAuth_flg) {
      answer = window.confirm(this.$messages.MSG['I3024'].replace("|", "\n").replace("|", "\n"));
      if (answer) {
        next();
      } else {
        return;
      }
    }else 
    if (!input_check) {
      answer = window.confirm(this.$messages.MSG['I3005']);
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
function jsonReplace(key, val) {
  if(typeof val == "string" && val == ""){
    return null;
  }
  return val;
}
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3 mt-2">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">研修全般アンケート入力</h4>

        <div id="mainTable" class="row info-row text-center text-md-left border-bottom">
          <div class="mainCell col-12 col-md-2 bg-primary text-white">
            <label for class="col-form-label">研修名</label>
          </div>
          <div class="subCell col-12 col-md-10 bg-light">
            <label for class="col-form-label">{{kensyumei}}</label>
          </div>
        </div>

        <div class="row mt-2">
          <b-table
            id="bTable"
            sticky-header="300px"
            bordered
            hover
            striped
            small
            :dark="isDark"
            class="web-stacked-table"
            :items="items"
            :fields="fields"
            :head-variant="'dark'"
            select-mode="single"
            show-empty
            :empty-text="$messages.MSG['I0008']"
          >
            <template #empty="scope">{{ scope.emptyText }}</template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
              </template>
            </template>
            <template #cell(kaito)="kaito">
              <button class="btn btn-primary shadow ml-1" style="padding: 0.1rem 0.75rem" type="button"
               @click="onRowSelected([items[kaito.index]]);">
                アンケート回答
              </button>
            </template>
            <template #cell(file)="data">
              <button class="btn btn-primary shadow ml-1" style="padding: 0.1rem 0.75rem" type="button" @click="downloadPSEnqueteKensyu001Excel(data.item)">
                ダウンロード
              </button>
            </template>
          </b-table>
        </div>

        <div style="margin-bottom: 20px;">
          <span class="font-weight-bold" style="font-size:15px;">
            アンケートの回答内容は開いてから90分以内に登録してください。90分を過ぎるとサーバーとの接続が切れていまい回答内容が登録されません。
          </span>
        </div>

        <h5 class="">{{title_name}}</h5>

        <div v-bind:class="{'bg-dark': isDark}" class="card mb-2 card_shadow" style="padding: 10px" v-for="(setsumon_info, index) in setsumonInfo" v-bind:key="index">
          <div class="row border-bottom">
            <div class="col-12 pl-1">{{setsumon_info[0].qu_hyoki}}</div>
            <div class="col-12 pl-2 mb-2">
              {{setsumon_info[0].qu_content}}
            </div>
          </div>
          <div class="row mt-2 pl-3" v-for="(setsumon_data, i) in setsumon_info" v-bind:key="i">
            <div class="col-12 form-inline" v-if="setsumon_data.select_type == 'R'">
              <input type="radio" class="mr-1"
              :value="setsumon_data.select_no"
              :checked="setsumon_data.sentaku_umu == 1"
              @click="clickCheckbox(index, i, $event)"/>{{setsumon_data.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="setsumon_data.select_type == 'RT'">
              <input type="radio" class="mr-1"
              :value="setsumon_data.select_no"
              :checked="setsumon_data.sentaku_umu == 1"
              @click="clickCheckbox(index, i, $event)" />{{setsumon_data.select_content}}
              <div class="col-12 form-inline">
                <textarea style="width: 100%; height: 150px"
                v-model="setsumon_data.kaito_content"></textarea>
              </div>
            </div>
            <div class="col-12 form-inline" v-if="setsumon_data.select_type == 'C'">
              <input type="checkbox" class="mr-1"
              :value="setsumon_data.select_no"
              :checked="setsumon_data.sentaku_umu == 1"
              :data-checked="setsumon_data.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(index, i, $event)"/>{{setsumon_data.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="setsumon_data.select_type == 'CT'">
              <input type="checkbox" class="mr-1"
              :value="setsumon_data.select_no"
              :checked="setsumon_data.sentaku_umu == 1"
              :data-checked="setsumon_data.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(index, i, $event)" />{{setsumon_data.select_content}}
              <div class="col-12 form-inline">
                <textarea style="width: 100%; height: 150px"
                v-model="setsumon_data.kaito_content"></textarea>
              </div>
            </div>
            <div class="col-12" v-if="setsumon_data.select_type == 'TA'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{setsumon_data.select_content}}</label>
                <div class="col-12 form-inline">
                  <textarea style="width: 100%; height: 150px"
                    v-model="setsumon_data.kaito_content"></textarea>
                </div>
            </div>
            <div class="col-12" v-if="setsumon_data.select_type == 'TB'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{setsumon_data.select_content}}</label>
                <div class="col-12 form-inline">
                  <input type="text" style="width: 100%;" v-model="setsumon_data.kaito_content"/>
                </div>
            </div>
            <div class="col-12" style="line-height: 40px" v-if="setsumon_data.select_type == 'N'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{setsumon_data.select_content}}</label>
                <div class="col-12 form-inline">
                  <input type="number" class="mr-1" v-model="setsumon_data.kaito_content"/>
                </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-center">
            <button class="btn btn-primary shadow" style="width: 120px" :disabled="isDisable" @click="regist">
              登録
            </button>
            <router-link
              class="btn btn-secondary shadow ml-3"
              style="width: 120px"
              :to="{ name: 'SPortal' }"
            >
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
.card_shadow {
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}
.info-row {
  line-height: 50px;
}
</style>
