<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default {
    name: "SjyukoRegist",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullpage: true,
        passFlg1: true,
        passFlg2: true,
        passFlg3: true,
        login_id: "",
        kensyu_cd: "",
        kensyu_name: "",
        suisen_name: "",
        shozoku_name: "",
        mail: "",
        init_mail: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        successStatus:false,
        succsssMessageNo:'',
        successMessage:'',
        errorStatus:false,
        errors:[],
        error_message:'',
        error_message_no :'',
        regist_flg: '',
        resend_flg: '',
        initial_data: null,
        oldRequestData: {}
      };
    },
    mounted()
    {
      this.initialScreenData();
    },
    methods: {
      initialScreenData: function () {
        this.isLoading = true;
        apiService.getSjyukoRegistInitialScreenData()
        .then(result => {
            this.jyukoData = result.jyukoData;
            this.login_id = this.jyukoData.login_id;
            this.kensyu_cd = this.jyukoData.kensyu_cd;
            this.kensyu_name = this.jyukoData.kensyu_name;
            this.suisen_name = this.jyukoData.suisen_name;
            this.shozoku_name = this.jyukoData.shozoku_name;
            this.mail = this.jyukoData.jyuko_mail;
            this.init_mail = this.jyukoData.jyuko_mail;
            this.regist_flg = this.jyukoData.regist_flg;
            this.resend_flg = this.jyukoData.resend_flg;
            this.isLoading = false;
            this.setOldRequestData();
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      },
      regist: function () {
        this.isLoading = true;
        this.successStatus = false;
        this.errorStatus = false;
        if (window.confirm(this.$messages.MSG['I3006'])) {
          let param = {
            mail: this.mail,
            kensyu_name: this.kensyu_name,
            suisen_name: this.suisen_name,
            login_id: this.login_id,
            regist_flg: this.regist_flg
          }
          apiService.SjyukoRegistRegister(param)
          .then(result => {
            if (result.status == 'success') {
              this.successStatus    = true;
              this.errorStatus      = false;
              this.succsssMessageNo = result.message_no;
              this.successMessage   = result.message.replace("|", "\n");
              this.setOldRequestData();
              if (result.update_flg == true && this.regist_flg == '0') {
                this.$store.commit("Header/setRegistFlg", "1");
                this.$router.push({
                  name: "SPortal",
                });
              } else {
                this.getSystemInitialDate();
              }
            } else {
              this.errorStatus = true;
              this.successStatus = false;
              this.error_message = result.message.replaceAll("|","\n");
              this.error_message_no = result.message_no;
              this.isLoading = false;
            }
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          })
          .catch(error => {
            this.errorStatus = true
            this.successStatus = false
            this.errors = [];
            this.error_message ='';
            this.error_message_no ='';
            if(error.errors) {
              this.errors = error.errors;
            } else {
              this.error_message = error.message.replaceAll("|","\n");
              this.error_message_no = error.message_no;
            }
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          });
        } else {
          this.isLoading = false;
        }
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
        });
      },
      backclick: function () {
        if (this.regist_flg == '1') {
          this.$router.push({ name: "SPortal"});
        } else {
          this.$router.push({ name: "SLogin" });
        }
      },
      setOldRequestData() {
        this.oldRequestData 
          = JSON.parse(JSON.stringify({
            mail: this.mail,
          }))
      },
      checkInputChange() {
        var org = JSON.stringify(this.oldRequestData, jsonReplace);
        if (org === '{}') {
          return true;
        }
        var dest = JSON.stringify({
          mail: this.mail,
        }, jsonReplace);

        return org === dest;
      },
    },
    beforeRouteLeave(to, from, next) {
      var input_check = this.checkInputChange();
      var answer = true;
      if (!input_check) {
        answer = window.confirm(this.$messages.MSG['I3005']);
      }
      if (answer) {
        next();
      } else {
        next(false);
      }
    },
  };
  function jsonReplace(key, val) {
    if(typeof val == "string" && val == ""){
      return null;
    }
    return val;
  }
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3">
      <div id="contentTop"></div>
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
      <div class="col-md-12" v-if="successMessage">
        {{ succsssMessageNo }} {{ successMessage }}</div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" v-for="(value,index) in errors" :key="index">
          {{value.slice('')[0].split('/')[1]}} {{value.slice('')[0].split('/')[0]}}
        </div>
        <div class="col-md-12" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div id="mainTable" class="container" style="max-width: 90%;">
        <h4 class="mt-3">受講者登録</h4>
        <div class="row">
          <div class="
            warnCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            text-white
          ">
            研修コード
          </div>
          <div class="
            subCell
            col-md-4 col-12
            bg-light
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
          ">
          {{ this.kensyu_cd }}
          </div>
          <div class="
            warnCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            text-white
          ">
            研修名
          </div>
          <div class="
            subCell
            col-md-4 col-12
            bg-light
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
          ">
            {{ this.kensyu_name }}
          </div>
        </div>
        <div class="row row_border">
          <div class="
            warnCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            text-white
          ">
            推薦機関名
          </div>
          <div class="
            subCell
            col-md-4 col-12
            bg-light
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
          ">
            {{ this.suisen_name }}
          </div>
          <div class="
            warnCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            text-white
          ">
            所属
          </div>
          <div class="
            subCell
            col-md-4 col-12
            bg-light
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
          ">
            {{ this.shozoku_name }}
          </div>
        </div>
        <div class="row row_border">
          <div class="
            warnCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            text-white
          ">
            ID
          </div>
          <div class="
            subCell
            col-md-10 col-12
            bg-light
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
          ">
            {{ this.login_id }}
          </div>
        </div>

        <div class="row mt-4 border-bottom">
          <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
            メールアドレス
          </div>
          <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
            <input v-model="mail" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="xxxx@xxxx.co.jp" />
          </div>
        </div>

        <div class="row mt-2 mb-2">
        </div>
        <div class="w-100 text-center text-md-right mb-2">
          <button class="btn btn-primary d-inline-block" style="width: 100px" @click="regist">
            登録
          </button>
          <button class="btn btn-secondary d-inline-block ml-3" style="width: 100px" @click="backclick">
            戻る
          </button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
</style>
