var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header-height",style:(_vm.headerStyle)},[_c('b-navbar',{class:_vm.navHead,attrs:{"toggleable":"lg","align":"left"}},[_c('div',{staticClass:"container-xl"},[_c('b-row',{staticClass:"m-0",staticStyle:{"width":"100%"},attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","lg":"4"}},[_c('img',{staticClass:"d-inline",staticStyle:{"height":"95px"},attrs:{"src":_vm.logoImage,"alt":"独立行政法人教職員支援機構ロゴ画像"}})]),(!this.$route.meta.noNeedAuth)?_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('b-navbar-toggle',{staticClass:"mb-2",attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto",staticStyle:{"width":"100%"}},[_c('b-row',{staticClass:"m-0",staticStyle:{"width":"100%"}},[_c('b-col',{staticClass:"p-0 text-right mb-2",attrs:{"cols":"12"}},[_vm._v(" 文字サイズ "),_c('b-button',{attrs:{"variant":_vm.fontSizeVariant1},on:{"click":_vm.normalFontSize}},[_vm._v("標準")]),_c('b-button',{staticClass:"m-1",attrs:{"variant":_vm.fontSizeVariant2},on:{"click":_vm.expandFontSize}},[_vm._v("拡大")]),_c('span',[_vm._v(" 背景色変更 ")]),_c('b-button',{attrs:{"id":"bgButton","variant":_vm.backgroundVariant1},on:{"click":_vm.changeWhiteBackground}},[_vm._v("白")]),_c('b-button',{staticClass:"m-1",attrs:{"variant":_vm.backgroundVariant2},on:{"click":_vm.changeBlackBackground}},[_vm._v("黒")]),_c('b-dropdown',{attrs:{"id":"dropdown-1","variant":_vm.headerDropdownBtnStyle,"menu-class":_vm.headerDropdownListStyle},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"person-fill"}}),_c('span',[_vm._v(_vm._s(_vm.userName))])]},proxy:true}],null,false,607065994)},[(
                        this.$route.meta.user_kbn ==
                        this.$constants.USER_KBN_P
                      )?[_c('b-dropdown-item',{attrs:{"to":{ name: 'PTantoRegist', params: { login_id: this.$store.state.Header.sessionData['login_id'], account_flg: '0' } }}},[_vm._v("推薦担当者編集")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadManual('0')}}},[_vm._v(" マニュアル"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"download"}})],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("ログアウト")])]:_vm._e(),(
                        this.$route.meta.user_kbn ==
                        this.$constants.USER_KBN_S
                      )?[_c('b-dropdown-item',{attrs:{"to":{ name: 'SjyukoRegist' }}},[_vm._v("受講者編集")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadManual('2')}}},[_vm._v(" マニュアル"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"download"}})],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("ログアウト")])]:_vm._e(),(
                        this.$route.meta.user_kbn ==
                        this.$constants.USER_KBN_T
                      )?[_c('b-dropdown-item',{attrs:{"to":{ name: 'TKousiRegist' }}},[_vm._v("講師編集")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadManual('1')}}},[_vm._v(" マニュアル"),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"download"}})],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("ログアウト")])]:_vm._e()],2)],1),(
                    this.$route.meta.user_kbn == this.$constants.USER_KBN_S &&
                    this.$store.state.Header.registFlg == '1'
                  )?[_c('b-col',{staticClass:"p-0 text-right text-lg-center",attrs:{"md":"12","lg":"2"}},[_c('b-nav-item',{attrs:{"to":{ name: 'SKadai' }}},[_c('span',{style:(_vm.headerMenuStyle)},[_vm._v("課題")])])],1),_c('b-col',{staticClass:"p-0 text-right text-lg-center",attrs:{"md":"12","lg":"3"}},[_c('b-nav-item',{attrs:{"to":{ name: 'SSTAYMANUAL' }}},[_c('span',{style:(_vm.headerMenuStyle)},[_vm._v("施設予約")])])],1),_c('b-col',{staticClass:"p-0 text-right text-lg-center",attrs:{"md":"12","lg":"3"}},[_c('b-nav-item',{attrs:{"to":{ name: 'SENQUETEKOZA' }}},[_c('span',{style:(_vm.headerMenuStyle)},[_vm._v("講座アンケート")])])],1),_c('b-col',{staticClass:"p-0 text-right text-lg-center",attrs:{"md":"12","lg":"4"}},[_c('b-nav-item',{attrs:{"to":{ name: 'SENQUETEKENSYU' }}},[_c('span',{style:(_vm.headerMenuStyle)},[_vm._v("研修全般アンケート")])])],1)]:_vm._e()],2)],1)],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }