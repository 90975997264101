<script>
import AppLayout from "@/components/layouts/Layout.vue";
import TdfkList from '@/pages/TdfkList/index.vue'
import TosiList from '@/pages/TosiList/index.vue'
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "PCreateAcSuisen",
  data() {
    return {
      error: "",
      isActive: true,
      disable_flg_c: true,
      disable_flg_u: true,
      selectedPrefecture: {
        kenCode: "",
        kenName: ""
      },
      selectedCity: {
        tosiCode: "",
        tosiName: ""
      },
      URL_cd: "",
      login_ID: "",
      nendo: "",
      kensyu_cd: "",
      kensyu_name: "",
      Account_cd: "",
      mail: "",
      errorStatus :false,
      errorMsg: "",
      errors: [],
      oldRequestData: {}
    };
  },
  components: {
    AppLayout,
    TdfkList,
    TosiList,
  },
  mounted() {
    var request_data = {
      URL_cd: location.href
    };
    apiService.PCreateAcSuisenInitDisp(request_data)
      .then(result => {
        this.URL_cd = result.URL_cd;
        this.login_ID = result.login_id;
        this.nendo = result.nendo;
        this.kensyu_cd = result.kensyu_cd;
        this.kensyu_name = result.kensyu_name;
        this.setOldRequestData();
      })
      .catch(error => {
        console.log(error)
      });
  },
  methods: {
    onClickPrefecture() {
      this.$refs.TdfkList.setClose();
    },
    chgActivePrefecture(items){
      this.isActive = items;
    },
    clickPrefecture(items){
      this.$bvModal.hide('modal-tdfklist');
      this.selectedPrefecture.kenCode =items[0].ken_code;
      this.isActive = true;
      this.getPrefecture();
    },
    getPrefecture() {
      var request_data = {
        tdfk_cd:this.selectedPrefecture.kenCode
      };
      apiService.PCreateAcSuisenGetPrefectureData(request_data)
        .then(result => {
          this.selectedPrefecture.kenCode = result.fectureData[0].tdfk_cd;
          this.selectedPrefecture.kenName = result.fectureData[0].tdfk_name;
        })
        .catch(error => {
          this.selectedPrefecture.kenName = "";
          console.log(error)
        });
    },

    onClickCity() {
      this.$refs.TosiList.setClose();
    },
    chgActiveCity(items){
      this.disable_flg_c = items;
    },
    btnSearchCity(){
      var request_data = {
        tdfk_cd: this.selectedPrefecture.kenCode
      };
      apiService.PCreateAcSuisenPrefectureInputcheck(request_data)
      .then(result=>{
        if(result.status == 'success'){
          this.$bvModal.show('modal-tosiList');
        }
        else{
          this.errorStatus = true;
          this.errorMsg = result.message.replaceAll("|","\n");
          this.errorNo = result.message_no;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
        this.errorStatus = true;
        this.infoMsg = "";
        this.infoNo = "";
        if(error.errors) {
          this.errorMsg = "";
          var require_error = error.errors;
          this.errors = require_error;
        } else {
          this.errorMsg = error.message.replaceAll("|","\n");
          this.errorNo = error.message_no;
        }
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
    selectCity(items){
      this.$bvModal.hide('modal-tosiList');
      this.selectedCity.tosiCode = items[0].tosi_cd;
      this.getCity();
    },
    getCity() {
      var request_data = {
        tdfk_cd:this.selectedPrefecture.kenCode,
        tosi_cd: this.selectedCity.tosiCode
      };
      if (request_data.tosi_cd) {
        apiService.PCreateAccountGetTosiData(request_data)
          .then(result => {
            this.selectedCity.tosiCode
              = result.tosiData[0].tosi_cd;
            this.selectedCity.tosiName
              = result.tosiData[0].tosi_name;
          })
          .catch(error => {
            console.log(error)
          });
      } else {
        this.selectedCity.tosiName = "";
      }
    },

    clickCreateAccount() {
      var request_data = {
        tdfk_cd:this.selectedPrefecture.kenCode,
        tdfk_name:this.selectedPrefecture.kenName,
        tosi_cd: this.selectedCity.tosiCode,
        tosi_name: this.selectedCity.tosiName,
        mail: this.mail,
        Account_cd: this.Account_cd,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        URL_cd: this.URL_cd,
        kensyu_name: this.kensyu_name,
        login_ID: this.login_ID
      };
      apiService.PCreateAccountCreateStart(request_data)
        .then(result => {
          this.setOldRequestData();
          apiService.setToken(result.token);
          this.$router.push({
            name: "PTantoRegist",
            params: { login_id: this.login_ID, account_flg: '1' }
          });
        })
        .catch(error => {
          this.errorStatus = true;
          if (error.errors) {
            var require_error = error.errors;
            this.errors = require_error;
            this.errorNo = error.message_no;
          } else {
            this.errorMsg = error.message.replaceAll("|","\n");
            this.errorNo = error.message_no;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
    },
    setOldRequestData() {
        this.oldRequestData 
          = JSON.parse(JSON.stringify({
            kensyu_name: this.kensyu_name,
            tdfk_cd:this.selectedPrefecture.kenCode,
            tdfk_name:this.selectedPrefecture.kenName,
            tosi_cd: this.selectedCity.tosiCode,
            tosi_name: this.selectedCity.tosiName,
            Account_cd: this.Account_cd,
            mail: this.mail,
          }))
    },
    checkInputChange() {
      var org = JSON.stringify(this.oldRequestData, jsonReplace);
      if (org === '{}') {
        return true;
      }
      var dest = JSON.stringify({
        kensyu_name: this.kensyu_name,
        tdfk_cd:this.selectedPrefecture.kenCode,
        tdfk_name:this.selectedPrefecture.kenName,
        tosi_cd: this.selectedCity.tosiCode,
        tosi_name: this.selectedCity.tosiName,
        Account_cd: this.Account_cd,
        mail: this.mail,
      }, jsonReplace);
        return org === dest;
    },
  },
  beforeRouteLeave(to, from, next) {
    var input_check = this.checkInputChange();
    var answer = true;
    if (!input_check) {
      answer = window.confirm(this.$messages.MSG['I3005']);
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
function jsonReplace(key, val) {
  if(typeof val == "string" && val == ""){
    return null;
  }
  return val;
}
</script>

<template>
  <AppLayout>
    <main class="contents-height mb-3">
      <div id="contentTop"></div>
      <div class="text-left">
        <b-alert
          variant="danger"
          dismissible
          fade
          :show="errorStatus"
          @dismissed="errorStatus=false">
          <div class="col-md-12" style="white-space: pre !important" v-if="errorMsg">{{ errorNo }} {{ errorMsg }}</div>
          <div class="col-md-12" style="white-space: pre !important" v-for="error in errors" :key="error.index">
            {{error.slice('')[0].split('/')[1]}} {{error.slice('')[0].split('/')[0]}}
          </div>
        </b-alert>
      </div>
      <div class="container">
      <div class="row mb-2">
        <h2 class="w-100">推薦担当者アカウント発行画面</h2>
        <h6 class="font-weight-bold text-keft">
          アカウント発行用コードと推薦機関を入力して下さい。
        </h6>
      </div>
      <div id="mainTable" class="row mb-3">
          <div class="
              mainCell col-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
            研修
          </div>
          <div class="
              subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-light form-inline">
            <input v-model="kensyu_name" type="text" class="form-control bg-required" readonly tabindex="1" style="width: 400px" />
          </div>
          <div class="
              mainCell ol-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
            都道府県
          </div>
          <div class="
            subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
            text-center text-md-left table_height border bg-light form-inline">
            <div class="input-group">
              <input v-model="selectedPrefecture.kenCode" @blur="getPrefecture" type="text" class="form-control bg-required" tabindex="1" style="width:50px;" />
              <button v-b-modal.modal-tdfklist class="form-control btn btn-primary" type="button" style="width:auto;">
                <b-icon icon="search"></b-icon>
              </button>
              <input v-model="selectedPrefecture.kenName" type="text" class="form-control bg-required" tabindex="1" style="width:306px;" readonly/>
            </div>
          </div>
          <div class="
              mainCell col-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
              政令指定都市
          </div>
          <div class="
            subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
            text-center text-md-left table_height border bg-light form-inline">
            <div class="input-group">
              <input v-model="selectedCity.tosiCode" @blur="getCity" type="text" class="form-control" tabindex="1" style="width:50px;" />
              <button @click="btnSearchCity" class="form-control btn btn-primary" type="button" style="width:auto;">
                <b-icon icon="search"></b-icon>
              </button>
              <input v-model="selectedCity.tosiName" type="text" class="form-control bg-required" tabindex="1" style="width:306px;" readonly/>
            </div>
          </div>
          <div class="
              mainCell col-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
            ID
          </div>
          <div class="
              subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-light form-inline">
            <input v-model="login_ID" type="text" class="form-control" readonly tabindex="1" style="width: 400px" value="P202101H4gfGWg"/>
          </div>
          <div class="
              mainCell col-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
            アカウント発行用コード
          </div>
          <div class="
              subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-light form-inline">
            <input v-model="Account_cd" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="" />
          </div>
          <div class="
              mainCell col-md-5 col-lg-4 col-xl-3 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-primary text-white">
            メールアドレス
          </div>
          <div class="
              subCell col-md-7 col-lg-8 col-xl-9 col-12 d-flex align-items-center float-left
              text-center text-md-left table_height border bg-light form-inline">
            <input v-model="mail" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="" />
          </div>
          <div class="col-12 text-right pr-0">
            <button
              class="btn btn-primary mt-3"
              type="button"
              @click="clickCreateAccount()"
            >
              アカウント作成
            </button>
          </div>
        </div>
      </div>
      <b-modal id="modal-tdfklist" size="lg" title="都道府県一覧">
        <TdfkList ref="TdfkList" @clicked="clickPrefecture" @Active="chgActivePrefecture"/>
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="white" class="btn-outline-secondary float-right shadow" style="width:100px;"
              @click="$bvModal.hide('modal-tdfklist')">
              閉じる
            </b-button>
            <b-button variant="white" :disabled="isActive" class="btn-outline-primary float-right mr-2 shadow" style="width:100px;" @click="onClickPrefecture">
              設定
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-tosiList" size="lg" title="政令指定都市一覧">
        <TosiList ref="TosiList" @clicked="selectCity" @Active="chgActiveCity" :tdfk_cd="this.selectedPrefecture.kenCode"/>
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="white" class="btn-outline-secondary float-right shadow" style="width:100px;"
              @click="$bvModal.hide('modal-tosiList')">
              閉じる
            </b-button>
            <b-button variant="white" class="btn-outline-primary float-right mr-2 shadow" style="width:100px;" 
              @click="onClickCity" :disabled='disable_flg_c'>
              設定
            </b-button>
          </div>
        </template>
      </b-modal>
    </main>
  </AppLayout>
</template>
