import axios from 'axios';
import router from './router.js'
import constants from './constants.js'

axios.defaults.baseURL = 'https://training.nits.go.jp/api';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('AUTH_TOKEN');

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  let originalRequest = error.config
  if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
    var user_kbn = localStorage.getItem('USER_KBN');
    if(user_kbn == constants.USER_KBN_P){
      router.push({name: 'PLogin', params: { UnAuth_flg: '2' }});
    } else if(user_kbn == constants.USER_KBN_T) {
      router.push({name: 'TLogin', params: { UnAuth_flg: '2' }});
    } else {
      router.push({name: 'SLogin', params: { UnAuth_flg: '2' }});
    }
    localStorage.setItem('AUTH_TOKEN', '');
  }
  else if (error && error.response && error.response.status === 404 && !originalRequest._retry) {
      error = new Error('エラーが発生しました。');
  }
  return Promise.reject(error)
})

export class APIService {

  constructor() {}
  setUserKbn(user_kbn) {
    localStorage.setItem('USER_KBN', user_kbn);
  }

  getUserKbn() {
    return localStorage.getItem('USER_KBN');
  }

  setToken(token){
    localStorage.setItem('AUTH_TOKEN', token);
  }

  loginUser(request_data) {
    return new Promise((resolve, reject) => {
      axios.post('/login', request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        })
    });
  }

  overwriteLogin(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/overwriteLogin', request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        })
    });
  }

  logoutUser() {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/logout')
          .then(response => {
              localStorage.clear();
              resolve(response.data);
          })
          .catch(error => {
              console.log('in Logout function [API SERVICE]', error.response);
              reject(error);
          })
    });
  }

  PloginLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Plogin/login', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  updateMLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Plogin/updateMLogin', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  SloginLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Slogin/login', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  SloginUpdateMLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Slogin/updateMLogin', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  TloginLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Tlogin/login', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  TloginUpdateMLogin(request_data)
  {
    return new Promise((resolve, reject) => {
      axios.post('/Tlogin/updateMLogin', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  changepassword(request_data) {
    return new Promise((resolve, reject) => {
      axios.post('/changepassword', request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        })
    });
  }

  getJyukosyaData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/PZyukosyaRegistDL', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  exportJyukosyaData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/PZyukosyaRegistDL/export', request_data, { responseType: 'blob' })
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  portalinfolist(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/portalinfolist', request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        })
    });
  }


  kadaiEdit(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/kadaiEdit',request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  jukoshaEdit(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/edit', request_data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  portalInfoDownload() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pportal')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  portalDownloadNotice() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pportal/downloadNotice')
        .then(response => {
          resolve(response.data);

        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getDownloadFilePath() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pportal/export', { responseType: 'blob' })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }

  exportExcelForDownload(request_param) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      var constant;
      if(request_param.kinou_id == "0001"){
        constant = '/pportal/export/excel';
      }else if(request_param.kinou_id == "0003"){
        constant = '/PPportal002';
      }else if(request_param.kinou_id == "0002"){
        constant = '/PTSE010A002';
      }
      axios.post(constant,request_param, { responseType: 'blob' })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }

  exportExcelForNoticeDownload(request_param) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pportal/export/noticeExcel', request_param, { responseType: 'blob' })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }

  getSuisenData() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/ptantoregist')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  PTantosave(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/ptantoregist/save', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
  }
  KeyWordAuthProcess(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/keyWordAuth/authProcess', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });
  }

  getKensyuData() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getKaisaiData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/kaisaiinfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getKadaiInfo(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/kadaiinfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getYousikiData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/yousikiinfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getKozaData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/kozainfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getPortalInfo(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/portalinfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }
  getDownloadFile(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TPortal/download/file', request_data, {responseType:'blob'})
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getKensyuInfo(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getJyukoData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/jyukoinfo', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getTodofukenName(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/todofuken', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getSeibetsu() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/seibetsu')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getShokuName() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/shokuname')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getGakkoShu() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/gakkoshu')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  fileUpload(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/upload',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  getMaxRow() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/maximumrow',)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  PZyukosyaTempSave(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/tempsave',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  PZyukosyaConfirm(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/pzyukosyaregist/confirm',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  downloadFormatFile(request_data) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/pzyukosyaregist/download', request_data, {responseType:'blob'})
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
      });
  }

  GetSystemInitialDate() {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/systemdate')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
  }

  getKeyWordResendData(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/KeyWordResend/keyWordResend', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  passWordResend(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/PassWordResend/passWordResend', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  getTKousiRegistInitialScreenData()
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TKousiRegist')
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response.data);
        })
    });
  }

  TKousiRegistRegister(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TKousiRegist/register', request_data)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response.data);
        })
    });
  }

  getYousikiTitle(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Tyosiki', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getKousiID(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Tyosiki/kousiid', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  getYousikiNaiyou(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Tyosiki/yousikinaiyou', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  kaitoDataSave(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Tyosiki/save', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  TKadaiGetKensyuNameAndKaisaiName(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/TKadai/name', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
  }

  TKadaiGetKadaiInfo(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/TKadai/issueInfo', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
  }

  TkadaiDownloadExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/TKadai/download', request_data, {responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  SStayManualInitialScreenData()
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstaymanual/screen')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  TdfkListGetTdfkData(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/TdfkList/tdfk',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          })
    });
  }

  TosiListGetCityData(request_data){
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/TosiList/city', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  DaigakuListGetUniversityData(request_data){
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/DaigakuList/university', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAcSuisenInitDisp(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAcSuisen/init', request_data)
            .then(response => {
                // console.log(response.data);
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountInitDisp(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/init', request_data)
            .then(response => {
                // console.log(response.data);
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountGetPrefectureData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/prefecture', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }
  PCreateAccountGetKikanData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/kikan', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAcSuisenGetPrefectureData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAcSuisen/prefecture', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAcSuisenPrefectureInputcheck(request_data){
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAcSuisen/PrefectureInputcheck', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountPrefectureInputcheck(request_data){
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/PrefectureInputcheck', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountGetTosiData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/Tosi', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountGetDaigakuData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/Daigaku', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PCreateAccountCreateStart(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAcSuisen/Create', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.log(error.response.data);
                reject(error.response.data);
            })
    });
  }

  PCreateAccountCreateData(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PCreateAccount/Create', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // console.log(error.response.data);
                reject(error.response.data);
            })
    });
  }

  getJyukoInfo() {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
      axios.post('/SPortal/jyukoinfo')
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  getSPortalInfo() {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
      axios.post('/SPortal/protalinfo')
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  SPortalOutputProcess(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/SPortal/downloadProcess', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
  }

  portalTyouhyouInfoDownload() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SPortal/downloadtyohyou')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  portalSyuryosyoInfoDownload() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SPortal/downloadsyuryosyo')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  downloadPortal(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SPortal/download', request_data, {responseType:'blob'})
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      });
    });
  }

  PTSE030A001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTSE030A001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  SStayRegistInitialScreenData(){
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstayregist/screen')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  SStayRegistConfirm(request_data){
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstayregist/confirm', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  getSjyukoRegistInitialScreenData()
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SjyukoRegist')
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response.data);
        })
    });
  }

  SjyukoRegistRegister(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SjyukoRegist/register', request_data)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response.data);
        })
    });
  }

  SjyukoRegistBackClick(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SjyukoRegist/back', request_data)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response.data);
        })
    });
  }

  PKadaiGetHeaderSection(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/headersection', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiGetKadaiAndKadaiUpload(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/getKadaiAndKadaiUpload', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiGetDetailSectionDisplayItem(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/detailSectionDisplayItem', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiUploadRecommenderSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/uploadRecommenderSubmission', request_data,{'content-type': 'multipart/form-data'})
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiDownloadRecommenderSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/downloadRecommenderSubmission', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiDeleteRecommenderSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/deleteRecommenderSubmission', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiUploadStudentSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/uploadStudentSubmission', request_data,{'content-type': 'multipart/form-data'})
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiDownloadStudentSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/downloadStudentSubmission', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiDeleteStudentSubmission(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/deleteStudentSubmission', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiGetKaidaiSampleFormDownloadURL(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/getKadaiSampleFormDownloadURL', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PKadaiDownloadSampleForm(request_data){
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/PKadai/downloadSampleForm', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  CommonLoginInfo() {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/loginInfo')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  setLoginInfo(login_id,user_name){
    localStorage.setItem('LOGIN_ID', login_id);
    localStorage.setItem('USER_NAME', user_name);
  }

  SStayCheckInitialScreenData(request_data){
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstaycheck/screen',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  SStayCheckRegist(request_data){
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstaycheck/register',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  SStayPrintInitialScreenData() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstayprint/screen')
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  SStayPrintConfirm(request_data){
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/sstayprint/confirm', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  PTodokedeGetHeaderSection(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/Ptodokede/headersection', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  getJyukoshaTodokede(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/jyukoshatodokede', request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
    });
  }

  yousikiDownload(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/yousikidownload',request_data, {responseType:'blob'})
                .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  PSStayCheck001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PSStayCheck001', request_data, {responseType: 'blob' })
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  fileDownload(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/filedownload',request_data, {responseType:'blob'})
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  PtodokedeFileUpload(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/fileupload',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  importFile(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/import',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  PtodokedeDelete(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/Ptodokede/delete',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  getSEnqueteKensyuName(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKensyu/kensyuname', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  getSEnqueteList(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKensyu/enquetelist', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  getAnketoSetsumonInfo(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKensyu/anketosetsumoninfo', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }

  SEnqueteKensyuRegist(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKensyu/register', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      })
    });
  }
  PPportal002exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PPportal002', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PPportal003exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PPportal003', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTSE010A002exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTSE010A002', request_data, {responseType: 'blob' })
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error.response);
        });
    });
  }

  PPZyukosyaRegist001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PPZyukosyaRegist001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  getJisshiAndSimekiriCheck(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKoza/jisshiandsimekiricheck',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  getKensyuMei(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKoza/kensyuname',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  getKozaInfo(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKoza/kozaInfo',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  getAnketoKomoku(request_data)
  {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKoza/anketokomoku',request_data)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error.response);
          });
    });
  }

  SEnqueteKozaRegist(request_data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('AUTH_TOKEN');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.post('/SEnqueteKoza/register', request_data)
      .then(response => {
          resolve(response.data);
      })
      .catch(error => {
          reject(error.response.data);
      });
    });
  }

  SKadaiGetKensyuName() {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/SKadai/name')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  SKadaiGetKadaiInfo(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/SKadai/kadailist', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  SKadaiUpload(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/SKadai/upload', request_data,{'content-type': 'multipart/form-data'})
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  SKadaiDownloadKadai(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/SKadai/downloadkadai', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            })
    });
  }

  SKadaiGetYoshikiDownloadURL() {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/SKadai/downloadurl')
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  SKadaiDelete(request_data) {
    const token = localStorage.getItem('AUTH_TOKEN');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return new Promise((resolve, reject) => {
        axios.post('/SKadai/delete', request_data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            })
    });
  }

  PPportal001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PPportal001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTSE030A003exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTSE030A003', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PSEnqueteKensyu001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PSEnqueteKensyu001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PSEnqueteKoza001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PSEnqueteKoza001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTRE010001exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTRE010001', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTRE010002exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTRE010002', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTRE010003exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTRE010003', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTRE010004exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTRE010004', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  PTRE010007exportExcel(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/PTRE010007', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

  HeaderexportManual(request_data) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('/Header', request_data, { responseType: 'blob' })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response);
            });
    });
  }

}
