<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "KeyWordResend",
  components: {
    AppLayout,
  },
  data() {
    return {
      error: "",
      passFlg: true,
      loginId: "",
      password: "",
      mailAddress: "",
      updateFlg: false,
      errorMsg: [],
      errorNo: [],
      errorStatus: false,
      systemInitialDate: null
    };
  },
  mounted() {
    this.getSystemInitialDate();
  },
  methods: {
    clickButton: function() {
      this.clearMessage();

      //メールアドレスを更新する場合は確認メッセージを表示する。
      if(this.updateFlg && !confirm(this.$messages.MSG["I3019"])){
        return;
      }
      let request_data = {
        'login_id': this.loginId,
        'password': this.password,
        'mail_address': this.mailAddress,
        'update_flg': this.updateFlg,
        'system_initial_date': this.systemInitialDate
      }
      apiService.getKeyWordResendData(request_data)
      .then(result => {
        console.log(result);
        if(result.status == "error") {
          this.errorStatus = true;
          this.errorNo[0] = result.message_no;
          this.errorMsg[0] = result.message.replaceAll("|","\n");
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          return;
        }
        if(!alert(this.$messages.MSG["I3002"].replaceAll("|","\n"))){
          this.$router.push({
            name: 'KeyWordAuth',
            query: {
              url_cd : result.url_cd,
              user_kbn : result.user_kbn,
            }
          });
        }
      })
      .catch(error => {
        if (error.errors) {
          this.errorStatus = true;
          let msgs = Object.entries(error.errors);
          for (let i = 0; i < msgs.length; i++) {
            this.errorMsg[i] = msgs[i][1][0].split("/")[0];
            this.errorNo[i] = msgs[i][1][0].split("/")[1];
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
        console.log(this.errorMsg)
        console.log(this.errorNo)
      });
    },
    clearMessage(){
      this.errorStatus = false;
      this.errorMsg = [];
      this.errorNo = [];
    },
    getSystemInitialDate() {
      apiService.GetSystemInitialDate()
        .then(result => {
              if(result.status == 'success'){
                this.systemInitialDate = result.systemdate;
              }
          })
          .catch(error => {
            console.log(error)
      });
    }
  }
};
</script>

<template>
  <AppLayout>
    <main class="contents-height">
      <div id="contentTop"></div>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus = false"
      >
        <div class="col-md-12 mb-0" style="white-space: pre !important" v-for="(msg, index) in errorMsg" :key="index">{{ errorNo[index] }} {{ msg.replace("|", "\n") }}</div>
      </b-alert>
      <div class="container text-center">
        <h4 class="font-weight-bold text-black">
          キーワード再発行画面
        </h4>
      </div>
      <h6 class="font-weight-normal text-center">
        IDとメールアドレスを入力して下さい。<br>
        メールアドレスを変更する場合はパスワードも入力してください。
      </h6>
      <div class="container login-body rounded text-center">
        <form class="form-keyword">
          <label for="login-id" class="sr-only">ID</label>
          <input
            type="text"
            id="login-id"
            class="form-control"
            style="ime-mode: disabled"
            placeholder="ID"
            v-model="loginId"
            autofocus
          />
          <label for="inputPassword" class="sr-only">パスワード</label>
          <div v-if="updateFlg"  style="position: relative">
            <input type="password"
            id="password"
            class="form-control password"
            style="padding-right: 20px;"
            placeholder="パスワード"
            autocomplete="new-password"
            v-model="password">
            <label class="icon-pass" @click="passFlg = false">
              <b-icon icon="eye-fill"></b-icon>
            </label>
          </div>
          <label for="inputPassword" class="sr-only">送信メールアドレス</label>
          <input type="email"
          id="email"
          class="form-control"
          style="padding-right: 20px;"
          placeholder="送信メールアドレス"
          v-model="mailAddress">
          <div style="text-align:right;">
            <input
              id="update-check"
              type="checkbox"
              style="margin-right:5px;"
              v-model="updateFlg"
              :true-value='1'
              :false-value='0'
            />
            <label
              for="update-check"
              style="font-size:14px;"
              >送信メールアドレスに更新する</label>
          </div>
          <button
            type="button"
            class="btn btn-lg btn-block btn-outline-primary mt-3"
            @click="clickButton"
          >
            送信
          </button>
        </form>
      </div>
    </main>
  </AppLayout>
</template>
