<script>
  import Vue from 'vue';
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default Vue.extend({
    name: "TdfkList",
    data() {
      return {
        modes: ['multi', 'single', 'range'],

        fields: [
          { key: 'selected', label: '選択', sortable: false, thStyle: "width:60px;", class: "text-center" },
          { key: 'ken_code', label: 'コード', sortable: true, thStyle: "width:80px;", class: "text-center" },
          { key: 'ken_name', label: '名称', sortable: true }
        ],
        items: [],
        selectMode: 'single',
        tdfk_cd: '',
        selected: []
      };
    },
    mounted() {
      apiService.TdfkListGetTdfkData()
      .then(result => {
        for (var i = 0; i < result.tdfkData.length; i++) {
          this.items.push({
            ken_code: result.tdfkData[i].tdfk_cd,
            ken_name: result.tdfkData[i].tdfk_name,
            del_flg: result.tdfkData[i].del_flg
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    methods: {
      onRowSelected(items) {
        this.tdfk_cd = items;
        if(items.length > 0){
          this.$emit('Active', false)
        } else {
          this.$emit('Active', true)
        }
      },
      setClose() {
        this.$emit('clicked', this.tdfk_cd)
      },
      rowClass(row) {
        if(row){
          if (row.del_flg === "1") {
            return 'TableRowDelBackcolor'
          }
        }
        return;
      },
    },
  });
</script>
<template>
  <main>
    <b-table sticky-header="450px" bordered hover striped small :items="items" :fields="fields" :head-variant="'dark'" 
      :tbody-tr-class="rowClass" selectable :select-mode="'single'" @row-selected="onRowSelected" show-empty 
      :empty-text="$messages.MSG['I0008']">
      <template #empty="scope">{{ scope.emptyText }}</template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
  </main>
</template>
