<script>
  export default {
    name: "HeaderS",
    data() {
      return {
        SFlg: this.$route.meta.user_kbn == this.$constants.USER_KBN_S,
        mainTable: null,
        bTable: null,
        mainCell: null,
        subCell: null,
        warnCell: null,
        warningFlag: false,
        containerFlag: false,
      };
    },
    beforeMount() {
      if (this.$store.state.Header.fontSize === "secondary") {
        document.body.style.fontSize = "16px";
      } else if (this.$store.state.Header.fontSize === "outline-secondary") {
        document.body.style.fontSize = "18px";
      }

      if (this.$store.state.Header.backGroundColor === "secondary") {
        document.body.style.background = "white";
        document.body.style.color = "black";
      } else if (this.$store.state.Header.backGroundColor === "outline-secondary") {
        document.body.style.background = "black";
        document.body.style.color = "white";
      }
    },
    mounted() {
      this.mainTable = document.getElementById("mainTable");
      this.mainCell = document.getElementsByClassName("mainCell");
      this.subCell = document.getElementsByClassName("subCell");
      this.warnCell = document.getElementsByClassName("warnCell");
      this.mainContainer = document.getElementsByClassName("mainContainer");
      if (this.warnCell.length > 0) {
        this.warningFlag = true;
      }
      if (this.mainContainer.length > 0) {
        this.containerFlag = true;
      }
      if (this.$store.state.Header.backGroundColor === "secondary") {
        this.changeWhiteBackground();
        if (this.mainTable) {
          this.mainTable.style.color = "black";
        }
      } else if (this.$store.state.Header.backGroundColor === "outline-secondary") {
        this.changeBlackBackground();
        if (this.mainTable) {
          this.mainTable.style.color = "white";
        }
      }
    },
    methods: {
      logout() {
        if (this.$route.meta.user_kbn == this.$constants.USER_KBN_P) {
          this.$router.push({ name: "PLogin" });
        } else if (this.$route.meta.user_kbn == this.$constants.USER_KBN_S) {
          this.$router.push({ name: "SLogin" });
        } else {
          this.$router.push({ name: "TLogin" });
        }
      },
      normalFontSize() {
        document.body.style.fontSize = "16px";
        this.$store.commit("Header/setFontSize", "secondary");
      },
      expandFontSize() {
        document.body.style.fontSize = "18px";
        this.$store.commit("Header/setFontSize", "outline-secondary");
      },
      changeWhiteBackground() {
        document.body.style.background = "white";
        document.body.style.color = "black";
        if (this.mainTable) {
          this.mainTable.style.color = "black";
          for (var i = 0; i < this.mainCell.length; i++) {
            if (this.infoFlag == true) {
              this.mainCell[i].classList.remove("bg-primary");
              this.mainCell[i].classList.add("bg-info");
              this.infoFlag = false;
            } else {
              this.mainCell[i].classList.remove("bg-darkGray");
              this.mainCell[i].classList.add("bg-primary");
            }
          }
          for (var j = 0; j < this.subCell.length; j++) {
            this.subCell[j].classList.remove("bg-dark");
            this.subCell[j].classList.add("bg-light");
          }
          if (this.warningFlag == true) {
            for (var k = 0; k < this.warnCell.length; k++) {
              this.warnCell[k].classList.remove("bg-darkBlue");
              this.warnCell[k].classList.add("bg-warning");
            }
          }
          if (this.containerFlag == true) {
            this.mainContainer[0].classList.remove("bg-darkGray");
          }
        }

        this.$store.commit("Header/setBackGroundColor", "secondary");
      },
      changeBlackBackground() {
        document.body.style.background = "black";
        document.body.style.color = "white";
        if (this.mainTable) {
          this.mainTable.style.color = "white";
          for (var i = 0; i < this.mainCell.length; i++) {
            this.mainCell[i].classList.remove("bg-primary");
            if (this.mainCell[i].classList.contains("bg-info")) {
              this.mainCell[i].classList.remove("bg-info");
              this.mainCell[i].classList.add("bg-blue");
              this.infoFlag = true;
            } else {
              this.mainCell[i].classList.add("bg-darkGray");
            }
          }
          for (var j = 0; j < this.subCell.length; j++) {
            this.subCell[j].classList.remove("bg-light");
            this.subCell[j].classList.add("bg-dark");
          }
        }
        if (this.warningFlag == true) {
          for(var k = 0; k < this.warnCell.length; k++) {
            this.warnCell[k].classList.remove("bg-warning");
            this.warnCell[k].classList.add("bg-darkBlue");
          }
        }
        if (this.containerFlag == true) {
            this.mainContainer[0].classList.add("bg-darkGray");
        }

        this.$store.commit("Header/setBackGroundColor", "outline-secondary");
      },
    },
    computed: {
      navHead: function () {
        if (this.$route.meta.user_kbn == undefined) {
          return "nav-head-blue";
        }
        if (this.$route.meta.user_kbn == this.$constants.USER_KBN_P) {
          return "nav-head-orange";
        } else if (this.$route.meta.user_kbn == this.$constants.USER_KBN_S) {
          return "nav-head-blue";
        } else {
          return "nav-head-green";
        }
      },
      userName() {
        return (
          this.$store.state.Header.loginUserName
        );
      },
      logoImage() {
        return this.$store.state.Header.backGroundColor === "secondary" ? require("@/assets/logo_black.png") : require("@/assets/logo_white.png");
      },
      headerStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "background-color: #fff !important;" : "background-color: #000 !important;";
      },
      headerMenuStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "color: #000 !important;" : "color: #fff !important;";
      },
      fontSizeVariant1() {
        return this.$store.state.Header.fontSize === "secondary" ? "secondary" : "outline-secondary";
      },
      fontSizeVariant2() {
        return this.$store.state.Header.fontSize === "secondary" ? "outline-secondary" : "secondary";
      },
      backgroundVariant1() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "secondary" : "outline-secondary";
      },
      backgroundVariant2() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "outline-secondary" : "secondary";
      },
      headerNavbarStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "light" : "dark";
      },
      headerDropdownStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "light" : "dark";
      },
      headerDropdownBtnStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "outline-primary" : "outline-secondary";
      },
      headerDropdownListStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "light" : "dark";
      },
      headerNavbarToggleStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "btn-outline-primary" : "btn-outline-secondary";
      },
    },
  };
</script>
<template>
  <header class="header-height" :style="headerStyle">
    <b-navbar toggleable="lg" :class="navHead" :type="headerNavbarStyle">
      <div class="container">

        <div align-v="start" class="col-12 m-0 p-0">
          <b-col cols="3" class="float-left p-0">
            <img :src="logoImage" alt="独立行政法人教職員支援機構ロゴ画像" style="height: 45px" />
          </b-col>
          <b-col cols="9" v-if="
              this.$route.name !== 'PLogin' &&
              this.$route.name !== 'SLogin' &&
              this.$route.name !== 'TLogin' &&
              this.$route.name !== 'KeyWordAuth' &&
              this.$route.name !== 'KeyWordResend' &&
              this.$route.name !== 'PassWordResend' &&
              this.$route.name !== 'PCreateAccount'
            " class="float-right text-right m-md-auto p-0" :class="{ 'text-right float-right': !SFlg }">
            <b-dropdown id="dropdown-1" :variant="headerDropdownBtnStyle" :menu-class="headerDropdownListStyle" class="mx-0 pr-1" style="padding-top: 1px">
              <template #button-content>
                <b-icon icon="person-fill"></b-icon>
                <div class="d-inline-block d-lg-block p-0" style="
                    width: 135px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ">
                  {{ userName }}
                </div>
              </template>
              <template v-if="this.$route.meta.user_kbn == this.$constants.USER_KBN_P">
                <b-dropdown-item :to="{ name: 'PTantoRegist', params: { login_id: this.$store.state.Header.sessionData['login_id'], account_flg: '0' } }">推薦担当者編集</b-dropdown-item>
                <b-dropdown-item :to="{ name: '' }">
                  マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
              </template>
              <template v-if="this.$route.meta.user_kbn == this.$constants.USER_KBN_S">
                <b-dropdown-item :to="{ name: 'SjyukoRegist' }">受講者編集</b-dropdown-item>
                <b-dropdown-item :to="{ name: '' }">
                  マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
              </template>
              <template v-if="this.$route.meta.user_kbn == this.$constants.USER_KBN_T">
                <b-dropdown-item :to="{ name: 'TKousiRegist' }">講師編集</b-dropdown-item>
                <b-dropdown-item :to="{ name: '' }">
                  マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
              </template>
            </b-dropdown>
            <b-col class="col-2 float-right text-right m-md-auto p-0">
              <b-navbar-toggle style="margin-top: 2px;" :class="headerNavbarToggleStyle" target="nav-collapse" v-b-toggle.sidebar-backdrop></b-navbar-toggle>
            </b-col>
          </b-col>
          <template v-if="SFlg"> </template>
        </div>
      </div>
    </b-navbar>
    <b-sidebar id="sidebar-backdrop" :bg-variant="headerNavbarStyle" backdrop shadow right no-header style="z-index: 3;">
      <template #default="{ hide }">
        <b-list-group v-if="SFlg">
          <b-list-group-item style="background-color: rgba(0, 0, 0, 0); border-radius: 0px;" class="border-secondary" :to="{ name: 'SKadai' }"><span :style="headerMenuStyle">課題</span>
          </b-list-group-item>
          <b-list-group-item style="background-color: rgba(0, 0, 0, 0); border-radius: 0px;" class="border-secondary" :to="{ name: 'SSTAYMANUAL' }"><span :style="headerMenuStyle">施設予約</span>
          </b-list-group-item>
          <b-list-group-item style="background-color: rgba(0, 0, 0, 0); border-radius: 0px;" class="border-secondary" :to="{ name: 'SENQUETEKOZA' }"><span :style="headerMenuStyle">講座アンケート</span>
          </b-list-group-item>
          <b-list-group-item style="background-color: rgba(0, 0, 0, 0); border-radius: 0px;" class="border-secondary" :to="{ name: 'SENQUETEKENSYU' }"><span :style="headerMenuStyle">研修全般アンケート</span>
          </b-list-group-item>
        </b-list-group>

        <div class="ml-3 mt-3">
          <span :style="headerMenuStyle" class="mr-2">文字サイズ</span>
          <b-button @click="normalFontSize" :variant="fontSizeVariant1">標準</b-button>
          <b-button @click="expandFontSize" :variant="fontSizeVariant2" class="m-1">拡大</b-button>
        </div>
        <div class="ml-3">
          <span :style="headerMenuStyle" class="mr-2">背景色変更</span>
          <b-button id="bgButton" @click="changeWhiteBackground" :variant="backgroundVariant1">白</b-button>
          <b-button @click="changeBlackBackground" :variant="backgroundVariant2" class="m-1">黒</b-button>
        </div>
        <div class="mt-4 p-3">
          <b-button block v-b-modal.modal-tmp010 :variant="backgroundVariant1" class="border-secondary" @click="hide">
            <b-icon icon="x" font-scale="2" class="align-middle"></b-icon>
            <span class="align-middle">CLOSE</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </header>
</template>

<style scoped>
  .nav-head-orange {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(243, 152, 1);
  }

  .nav-head-blue {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(1, 99, 234);
  }

  .nav-head-green {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(18, 181, 176);
  }

  .nav-link {
    color: black !important;
    text-decoration: underline !important;
  }

  .nav-link:hover {
    color: blue !important;
  }

  .navbar-dark .navbar-toggler {
    border-color: #999;
  }
</style>
