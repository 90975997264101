<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "PEENQUETE",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      login_id: '',
      nendo: '',
      kensyu_cd: '',
      kaisai_no: '',
      koza_qu_jissi_flg: '',
      koza_qu_simekiri_flg: '',
      kensyuMei: '',
      kaisai_date_start: '',
      kaisai_date_end: '',
      select_koza_no_type_kbn_1: '',
      select_koza_no_type_kbn_2: '',
      modes: ["multi", "single", "range"],
      selectMode: "single",
      selected: [],
      fields: [
        {
          key: "ansflg",
          label: "回答状況",
          sortable: true,
          class: "text-center",
          thClass: "text-nowrap",
        },
        {
          key: "kouza_no",
          label: "講座番号",
          sortable: true,
          class: "text-center",
          thClass: "text-nowrap",
        },
        {
          key: "jissidate",
          label: "実施日",
          sortable: true,
          class: "text-center",
          thClass: "text-nowrap",
        },
        {
          key: "kouzaname",
          label: "講座名",
          sortable: true,
          thClass: "text-left text-nowrap",
          tdClass: "text-left text-nowrap",
          thStyle: "min-width:400px;!important",
        },
        {
          key: "kousiname",
          label: "講師名",
          sortable: true,
          class: "text-nowrap",
          thClass: "text-nowrap",
        },
        {
          key: "kaito",
          label: "アンケート回答",
          sortable: true,
          class: "text-nowrap",
          tdClass: "text-nowrap text-center",
        },
        {
          key: 'kaitoflg',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'nendo',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'kensyu_cd',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'kaisai_no',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'koza_type',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'type_kbn',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'jissi_date_YYYYMMDD',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
      ],
      items: [],
      hidden_data: '',
      anketoKomoku: '',
      forday_flg: true,
      disable_flg: false,
      regist_flg: false,
      title_name: '',
      successStatus: false,
      successMessage: '',
      succsssMessageNo: '',
      errorStatus:false,
      error_message:'',
      error_message_no :'',
      jyuko_no :'',
      jyuko_name :'',
      oldRequestData: {}
    };
  },
  mounted() {
    this.nendo = this.$store.state.Header.sessionData['nendo'];
    this.kensyu_cd = this.$store.state.Header.sessionData['kensyu_cd'];
    this.kaisai_no = this.$store.state.Header.sessionData['kaisai_no'];
    this.login_id = this.$store.state.Header.sessionData['login_id'];
    this.getJisshiAndSimekiriCheck();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
  },
  methods: {
    getJisshiAndSimekiriCheck() {
      this.isLoading = true;
      var request_data = {
        login_id: this.login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no
      }
      apiService.getJisshiAndSimekiriCheck(request_data)
      .then(result => {
        if(result.status=='success') {
          this.koza_qu_jissi_flg = result.jisshiSimekiri[0]['koza_qu_jissi_flg'];
          this.koza_qu_simekiri_flg = result.jisshiSimekiri[0]['koza_qu_simekiri_flg'];
          this.select_koza_no_type_kbn_1 = result.jisshiSimekiri[0]['select_koza_no_type_kbn_1'];
          this.select_koza_no_type_kbn_2 = result.jisshiSimekiri[0]['select_koza_no_type_kbn_2'];
          this.jyuko_no = result.jisshiSimekiri[0]['jyuko_no'];
          this.jyuko_name = result.jisshiSimekiri[0]['jyuko_name'];
          if(this.koza_qu_jissi_flg == 1) {
            if(this.koza_qu_simekiri_flg != 1) {
              this.getKensyuMei(request_data);
              this.getKozaInfo(this.forday_flg);
            } else {
              this.disable_flg = true;
              this.successStatus = false
              this.errorStatus = true
              this.error_message = this.$messages.MSG['E1057'];
              this.error_message_no = 'E1057';
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            }
          } else {
            this.disable_flg = true;
            this.successStatus = false
            this.errorStatus = true
            this.error_message = this.$messages.MSG['E1110'];
            this.error_message_no = 'E1110';
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        }else {
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    getKensyuMei(request_data) {
      apiService.getKensyuMei(request_data)
      .then(result => {
        if(result.status=='success') {
          if(result.kensyuName[0]['kaisai_name']) {
            this.kensyuMei = result.kensyuName[0]['kensyu_name'] + '　' + result.kensyuName[0]['kaisai_name'];
          } else {
            this.kensyuMei = result.kensyuName[0]['kensyu_name'];
          }
          this.kaisai_date_start = result.kensyuName[0]['kaisai_date_start'];
          this.kaisai_date_end = result.kensyuName[0]['kaisai_date_end'];
        }else {
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    getKozaInfo(flg) {
      this.isLoading = true;
      var request_data = {
        login_id: this.login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        select_koza_no_type_kbn_1: this.select_koza_no_type_kbn_1,
        select_koza_no_type_kbn_2: this.select_koza_no_type_kbn_2,
        day_flg: flg,
      }
      apiService.getKozaInfo(request_data)
      .then(result => {
        if(result.status=='success') {
          let kaito_joho;
          let kaitoflg;
          let tempItem = [];
          result.kozaInfo.forEach(function(data) {
            if(data.entry_date == null) {
              kaito_joho = '未';
              kaitoflg = 0;
            } else {
              kaito_joho = '済';
              kaitoflg = 1;
            }

            let kousiname = '';
            kousiname = data.kousi_name1;
            if(data.kousi_name2){
              kousiname += '、' + data.kousi_name2;
            }
            if(data.kousi_name3){
              kousiname += '、' + data.kousi_name3;
            }
            if(data.kousi_name4){
              kousiname += '、' + data.kousi_name4;
            }

            var kozaInfo = {
              kouzano: data.meisai_no,
              kouza_no: data.koza_no,
              jissidate: data.jissi_date,
              kouzaname: data.koza_name,
              kousiname: kousiname,
              ansflg: kaito_joho,
              kaito_flg: kaitoflg,
              nendo: data.nendo,
              kensyu_cd: data.kensyu_cd,
              kaisai_no: data.kaisai_no,
              koza_type: data.koza_type,
              type_kbn: data.type_kbn,
              jissi_date_YYYYMMDD: data.jissi_date_yyyymmdd
            }
            tempItem.push(kozaInfo);
          });
          this.items = tempItem;
        }
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    onRowSelected(items) {
      if(items.length != 0) {
        this.isLoading = true;
        this.hidden_data = {
          kaito_flg: items[0].kaito_flg,
          nendo: items[0].nendo,
          kensyu_cd: items[0].kensyu_cd,
          kaisai_no: items[0].kaisai_no,
          meisai_no: items[0].kouzano,
          koza_type: items[0].koza_type,
          type_kbn: items[0].type_kbn,
          jissi_date: items[0].jissi_date_YYYYMMDD
        }
        var request_data = {
          kaito_flg: items[0].kaito_flg,
          nendo: items[0].nendo,
          kensyu_cd: items[0].kensyu_cd,
          kaisai_no: items[0].kaisai_no,
          meisai_no: items[0].kouzano,
          koza_type: items[0].koza_type,
          type_kbn: items[0].type_kbn,
          login_id: this.login_id,
        }
        apiService.getAnketoKomoku(request_data)
        .then(result => {
          if(result.status=='success') {
            this.title_name = items[0].kouzaname + 'に関するアンケート';
            this.anketoKomoku = result.anketoKomoku;
            this.regist_flg = true;
            this.setOldRequestData();
          }
          if(this.anketoKomoku.length == 0 || result.status=='error') {
            this.title_name = '';
            this.anketoKomoku = '';
            this.regist_flg = false;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      } else {
        this.title_name = '';
        this.anketoKomoku = '';
        this.oldRequestData = {};
        this.regist_flg = false;
      }
    },
    clickCheckbox: function(index, i, e) {
      var type = e.target.getAttribute("type");
      var data_checked = e.target.getAttribute("data-checked");
      if ( type == 'checkbox') {
        if (data_checked == 1) {
          e.target.setAttribute("data-checked", 0);
          this.anketoKomoku[index][i].sentaku_umu = "0";
        } else {
          e.target.setAttribute("data-checked", 1);
          this.anketoKomoku[index][i].sentaku_umu = "1";
        }
      } else {
        var kaitoInfo = Object.values(this.anketoKomoku[index]);
        kaitoInfo.map((value) => {
          if (value.select_type == "R" || value.select_type == "RT") {
            value.sentaku_umu = 0;
          }
        });
        this.anketoKomoku[index][i].sentaku_umu = 1;
      }
    },
    downloadPSEnqueteKoza001Excel() {
      this.successStatus = false;
      this.errorStatus = false;
      let request_data_array = [];
      let index = 0;
      let error_message_flg = true;
      this.items.forEach(function (val) {
        if (val.kaito_flg == 1) {
          request_data_array[index] = {
            kozaNo   : val.kouzano,
            kozaType : val.koza_type,
            typeKbn  : val.type_kbn,
          };
          index++;
          error_message_flg = false;
        }
      });

      if (this.items.length == 0 || error_message_flg) {
        this.errorStatus = true;
        this.error_message = this.$messages.MSG['E1055'];
        this.error_message_no = 'E1055';
        return;
      }

      this.isLoading = true;

      let request_data = {
        nendo    : this.nendo,
        kensyuCd : this.kensyu_cd,
        kaisaiNo : this.kaisai_no,
        request_data_array
      }
      apiService.PSEnqueteKoza001exportExcel(request_data)
        .then(result => {
          this.downloadExcel(result,'講座アンケート回答_' + request_data['kensyuCd'] + this.jyuko_no + this.jyuko_name + '.xlsx');
        }).catch(error => {
          this.blogErrorMessages(error);
        });
    },
    downloadExcel(result, fileName) {
      this.isLoading = false;
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    blogErrorMessages(error){
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      ) {
          errormessage = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              error.data = JSON.parse(reader.result);
              resolve(Promise.reject(error));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.data);
          })
          .then(err => {
            console.log(err.data)
          });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        this.errors = '';
        this.errorStatus = true;
        this.error_message = error.data.message.replaceAll("|","\n");
        this.error_message_no = error.data.message_no;
        this.successStatus = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
    regist() {
      var answer = window.confirm(this.$messages.MSG['I3006']);
      if (answer) {
        this.isLoading = true;
        var request_data = {
          login_id: this.login_id,
          kaito_flg: this.hidden_data.kaito_flg,
          nendo: this.hidden_data.nendo,
          kensyu_cd: this.hidden_data.kensyu_cd,
          kaisai_no: this.hidden_data.kaisai_no,
          meisai_no: this.hidden_data.meisai_no,
          koza_type: this.hidden_data.koza_type,
          type_kbn: this.hidden_data.type_kbn,
          anketo: this.anketoKomoku,
          jissi_date: this.hidden_data.jissi_date,
          kaisai_date_start: this.kaisai_date_start,
          kaisai_date_end: this.kaisai_date_end
        }
        apiService.SEnqueteKozaRegist(request_data)
        .then(result => {
          if(result.status=='success') {
            this.successStatus    = true;
            this.errorStatus      = false;
            this.succsssMessageNo = result.message_no;
            this.successMessage   = result.message.replaceAll("|","\n");
            this.getJisshiAndSimekiriCheck();
          } else {
            this.successStatus    = false;
            this.errorStatus      = true;
            this.error_message_no = result.message_no;
            this.error_message   = result.message.replaceAll("|","\n");
            this.isLoading = false;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      }
    },
    setOldRequestData() {
      this.oldRequestData 
        = JSON.parse(JSON.stringify({
          anketo: this.anketoKomoku,
        }))
    },
    checkInputChange() {
      var org = JSON.stringify(this.oldRequestData, jsonReplace);
      if (org === '{}') {
        return true;
      }
      var dest = JSON.stringify({
        anketo: this.anketoKomoku,
      }, jsonReplace);
      return org === dest;
    },
  },
  beforeRouteLeave(to, from, next) {
    var input_check = this.checkInputChange();
    var answer = true;
    if (!input_check) {
      answer = window.confirm(this.$messages.MSG['I3005']);
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
function jsonReplace(key, val) {
  if(typeof val == "string" && val == ""){
    return null;
  }
  return val;
}
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important;" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">講座アンケート入力</h4>

        <div id="mainTable" class="row info-row text-center text-md-left border-bottom">
          <div class="mainCell col-12 col-md-2 bg-primary text-white">
            <label for class="col-form-label">研修名</label>
          </div>
          <div class="subCell col-12 col-md-10 bg-light">
            <label for class="col-form-label">{{ kensyuMei }}</label>
          </div>
        </div>

        <div class="row mt-3 mb-1">
          <div class="col-12 form-inline pl-0">
            <label for class="col-form-label">講座選択</label>
            <button class="btn btn-outline-secondary ml-3" style="width: 80px"  v-if="disable_flg == true" disabled>
              当日分
            </button>
            <button class="btn btn-outline-secondary ml-3" style="width: 80px"  v-else @click="getKozaInfo('true')">
              当日分
            </button>
            <button class="btn btn-outline-secondary ml-1" style="width: 80px"  v-if="disable_flg == true" disabled>
              全件
            </button>
            <button class="btn btn-outline-secondary ml-1" style="width: 80px" v-else @click="getKozaInfo('false')">
              全件
            </button>
            <button class="btn btn-primary shadow ml-1" type="button" @click="downloadPSEnqueteKoza001Excel()">
              回答ダウンロード
            </button>
          </div>
        </div>
        <div class="row">
          <b-table
            id="bTable"
            sticky-header="300px"
            bordered
            hover
            striped
            small
            :dark="isDark"
            class="web-stacked-table"
            :items="items"
            :fields="fields"
            :head-variant="'dark'"
            :select-mode="selectMode"
            show-empty
            :empty-text="$messages.MSG['I0008']"
          >
            <template #empty="scope">{{ scope.emptyText }}</template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
              </template>
            </template>
            <template #cell(kaito)="kaito">
              <button class="btn btn-primary shadow ml-1" style="padding: 0.1rem 0.75rem" type="button"
               @click="onRowSelected([items[kaito.index]]);">
                アンケート回答
              </button>
            </template>
          </b-table>
        </div>

        <div style="margin-bottom: 20px;">
          <span class="font-weight-bold" style="font-size:15px;">
            アンケートの回答内容は開いてから90分以内に登録してください。90分を過ぎるとサーバーとの接続が切れていまい回答内容が登録されません。
          </span>
        </div>

        <h5 class="">{{title_name}}</h5>

        <div class="card mb-2 card_shadow" style="padding: 10px" v-for="(anketo_data, index) in anketoKomoku" v-bind:key="index">
          <div class="row border-bottom">
            <div class="col-12 pl-1">{{anketo_data[0].qu_hyoki}}</div>
            <div class="col-12 pl-2 mb-2">
              {{anketo_data[0].qu_content}}
            </div>
          </div>
          <div class="row mt-2 pl-3" v-for="(anketo, i) in anketo_data" v-bind:key="i">
            <div class="col-12 form-inline" v-if="anketo.select_type == 'R'">
              <input type="radio" class="mr-1"
              :value="anketo.select_no"
              :checked="anketo.sentaku_umu == 1"
              @click="clickCheckbox(index, i, $event)"/>{{anketo.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="anketo.select_type == 'RT'">
              <input type="radio" class="mr-1"
              :value="anketo.select_no"
              :checked="anketo.sentaku_umu == 1"
              @click="clickCheckbox(index, i, $event)" />{{anketo.select_content}}
              <div class="col-12 form-inline">
                <textarea style="width: 100%; height: 150px"
                v-model="anketo.kaito_content"></textarea>
              </div>
            </div>
            <div class="col-12 form-inline" v-if="anketo.select_type == 'C'">
              <input type="checkbox" class="mr-1"
              :value="anketo.select_no"
              :checked="anketo.sentaku_umu == 1"
              :data-checked="anketo.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(index, i, $event)"/>{{anketo.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="anketo.select_type == 'CT'">
              <input type="checkbox" class="mr-1"
              :value="anketo.select_no"
              :checked="anketo.sentaku_umu == 1"
              :data-checked="anketo.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(index, i, $event)" />{{anketo.select_content}}
              <div class="col-12 form-inline">
                <textarea style="width: 100%; height: 150px"
                v-model="anketo.kaito_content"></textarea>
              </div>
            </div>
            <div class="col-12" v-if="anketo.select_type == 'TA'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{anketo.select_content}}</label>
                <div class="col-12 form-inline">
                  <textarea style="width: 100%; height: 150px"
                    v-model="anketo.kaito_content"></textarea>
                </div>
            </div>
            <div class="col-12" v-if="anketo.select_type == 'TB'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{anketo.select_content}}</label>
                <div class="col-12 form-inline">
                  <input type="text" style="width: 100%;" v-model="anketo.kaito_content"/>
                </div>
            </div>
            <div class="col-12" style="line-height: 40px" v-if="anketo.select_type == 'N'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{anketo.select_content}}</label>
                <div class="col-12 form-inline">
                  <input type="number" v-model="anketo.kaito_content"/>
                </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-center">
            <button class="btn btn-primary shadow" style="width: 120px"  v-if="disable_flg == true || regist_flg == false" disabled>
              登録
            </button>
            <button class="btn btn-primary shadow" style="width: 120px"  v-else @click="regist">
              登録
            </button>
            <router-link
              class="btn btn-secondary shadow ml-3"
              style="width: 120px"
              :to="{ name: 'SPortal' }"
            >
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
.card_shadow {
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}

.info-row {
  line-height: 50px;
}
</style>
