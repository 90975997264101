<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "TYOSIKI",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullpage: true,
      nendo: '',
      kensyu_cd: '',
      kaisai_no: '',
      meisai_no: '',
      koza_type: '',
      type_kbn: '',
      yousiki_no: '',
      yosuiki_kbn: '',
      login_id: '',
      yousiki_title: '',
      kousi_id: '',
      yousiki_naiyo: '',
      kaito_content: '',
      checked: '',
      checkedValue: [],
      successStatus: false,
      successMessage: '',
      succsssMessageNo: '',
      errorStatus:false,
      error_message:'',
      error_message_no :'',
      oldRequestData: {}
    };
  },
  mounted() {
    this.nendo = this.$store.state.TPortal.nendo;
    this.kensyu_cd = this.$store.state.TPortal.kensyu_cd;
    this.kaisai_no = this.$store.state.TPortal.kaisai_no;
    this.meisai_no = this.$store.state.TPortal.meisai_no;
    this.koza_type = this.$store.state.TPortal.koza_type;
    this.type_kbn = this.$store.state.TPortal.type_kbn;
    this.yousiki_no = this.$store.state.TPortal.youshiki_no;
    this.yosuiki_kbn = this.$store.state.TPortal.yosuiki_kbn;
    this.login_id = this.$store.state.Header.sessionData['login_id'];
    this.getYousikiTitle();
    this.getKousiID();
  },
  computed: {
    isDark () {
        return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
  },
  methods: {
    getYousikiTitle() {
      this.isLoading = true;
      var request_data = {
        yousiki_no: this.yousiki_no,
        yosuiki_kbn: this.yosuiki_kbn
      }
      apiService.getYousikiTitle(request_data)
      .then(result => {
        if(result.status=='success') {
          this.yousiki_title = result.yousikiTitle[0]['yousiki_name'];
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    getKousiID() {
      var request_data = {
        login_id: this.login_id
      }
      apiService.getKousiID(request_data)
      .then(result => {
        if(result.status=='success') {
          this.kousi_id = result.kousiID[0]['kousi_id'];
          this.getYousikiNaiyou();
        }else {
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    getYousikiNaiyou() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        meisai_no: this.meisai_no,
        koza_type: this.koza_type,
        type_kbn: this.type_kbn,
        yousiki_no: this.yousiki_no,
        kousi_id: this.kousi_id
      }
      apiService.getYousikiNaiyou(request_data)
      .then(result => {
        if(result.status=='success') {
          this.yousiki_naiyo = result.yousikiNaiyou;
          this.setOldRequestData();
        }
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    regist: function () {
      var answer = window.confirm(this.$messages.MSG['I3006']);
      if (!answer) {
        return;
      }
      this.isLoading = true;
      var request_data = {
        login_id: this.login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        meisai_no: this.meisai_no,
        koza_type: this.koza_type,
        type_kbn: this.type_kbn,
        yousiki_no: this.yousiki_no,
        kousi_id: this.kousi_id,
        yousiki_naiyo: this.yousiki_naiyo
      };
      apiService.kaitoDataSave(request_data)
      .then(result => {
        if(result.status=='success') {
          this.successStatus    = true;
          this.errorStatus      = false;
          this.succsssMessageNo = result.message_no;
          this.successMessage   = result.message.replaceAll("|","\n");
          this.setOldRequestData();
        } else {
          this.errorStatus = true
          this.successStatus = false
          this.errors = [];
          this.error_message ='';
          this.error_message_no ='';
          if(result.errors) {
            this.errors = result.errors;
          } else {
            this.error_message = result.message.replaceAll("|","\n");
            this.error_message_no = result.message_no;
          }
        }
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      })
      .catch(error => {
        this.errorStatus = true
        this.successStatus = false
        this.errors = [];
        this.error_message ='';
        this.error_message_no ='';
        if(error.errors) {
          this.errors = error.errors;
        } else {
          this.error_message = error.message.replaceAll("|","\n");
          this.error_message_no = error.message_no;
        }
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
    clickCheckbox: function(i, index, e) {
      var type = e.target.getAttribute("type");
      var data_checked = e.target.getAttribute("data-checked");
      if ( type == 'checkbox') {
        if (data_checked == 1) {
          e.target.setAttribute("data-checked", 0);
          this.yousiki_naiyo[i][index].sentaku_umu = "0";
        } else {
          e.target.setAttribute("data-checked", 1);
          this.yousiki_naiyo[i][index].sentaku_umu = "1";
        }
      } else {
        var yousikiObject = Object.values(this.yousiki_naiyo);
        var qu_no = this.yousiki_naiyo[i][index].qu_no;
        yousikiObject.map((value) => {
          value.map((v) => {
            if (v.select_type != "C") {
              if(v.qu_no == qu_no){
                v.sentaku_umu = "0";
              }
            }
          });
        });
        this.yousiki_naiyo[i][index].sentaku_umu = "1";
      }
    },
    setOldRequestData() {
      this.oldRequestData 
        = JSON.parse(JSON.stringify({
          yousiki_naiyo: this.yousiki_naiyo
      }))
    },
    checkInputChange() {
      var org = JSON.stringify(this.oldRequestData, jsonReplace);
      if (org === '{}') {
        return true;
      }
      var dest = JSON.stringify({
        yousiki_naiyo: this.yousiki_naiyo
      }, jsonReplace);
      return org === dest;
    },
  },
  beforeRouteLeave(to, from, next) {
    var input_check = this.checkInputChange();
    var answer = true;
    if (!input_check) {
      answer = window.confirm(this.$messages.MSG['I3005']);
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
function jsonReplace(key, val) {
  if(typeof val == "string" && val == ""){
    return null;
  }
  return val;
}
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">

        <div class="row mt-3">
          ２回目以降は前回の回答内容を引き継いで表示しています。修正がある場合は編集してください。
        </div>

        <h4 class="mt-4">{{this.yousiki_title}}</h4>

        <div v-bind:class="{'bg-dark': isDark}" class="card mb-2 card_shadow" style="padding: 10px" v-for="(yousiki, i) in yousiki_naiyo" v-bind:key="i">
          <div class="row border-bottom">
            <div class="col-12 pl-1">{{yousiki[0]['qu_hyoki']}}</div>
            <div class="col-12 pl-2 mb-2">{{yousiki[0]['qu_content']}}</div>
          </div>
          <div class="row mt-2 pl-3" v-for="(yousiki_data, index) in yousiki" v-bind:key="index">
            <div class="col-12 form-inline" v-if="yousiki_data.select_type == 'R'">
              <input type="radio" class="mr-1"
              :value="yousiki_data.select_no"
              :name="'selected_ans_' + yousiki_data.qu_no"
              :checked="yousiki_data.sentaku_umu == 1"
              @click="clickCheckbox(i, index, $event)"/>
              {{yousiki_data.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="yousiki_data.select_type == 'RT'">
              <input type="radio" class="mr-1"
              :value="yousiki_data.select_no"
              :name="'selected_ans_' + yousiki_data.qu_no"
              :checked="yousiki_data.sentaku_umu == 1"
              @click="clickCheckbox(i, index, $event)"/>
              {{yousiki_data.select_content}}
              <div class="col-12 form-inline">
                <textarea
                  style="width: 100%; height: 150px"
                  :value="yousiki_data.kaito_content"
                  @input="yousiki_data.kaito_content = $event.target.value"
                ></textarea>
              </div>
            </div>
            <div class="col-12 form-inline" v-if="yousiki_data.select_type == 'C'">
              <input type="checkbox" class="mr-1"
              :value="yousiki_data.select_no"
              :checked="yousiki_data.sentaku_umu == 1"
              :data-checked="yousiki_data.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(i, index, $event)"/>{{yousiki_data.select_content}}
            </div>
            <div class="col-12 form-inline" v-if="yousiki_data.select_type == 'CT'">
              <input type="checkbox" class="mr-1"
              :value="yousiki_data.select_no"
              :checked="yousiki_data.sentaku_umu == 1"
              :data-checked="yousiki_data.sentaku_umu == 1? '1': '0'"
              @click="clickCheckbox(i, index, $event)"/>{{yousiki_data.select_content}}
              <div class="col-12 form-inline">
                <textarea
                  style="width: 100%; height: 150px"
                  :value="yousiki_data.kaito_content"
                  @input="yousiki_data.kaito_content = $event.target.value"
                ></textarea>
              </div>
            </div>
            <div class="col-12" style="line-height: 40px" v-if="yousiki_data.select_type == 'TB'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{yousiki_data.select_content}}</label>
              <div class="col-12 form-inline">
                <input type="text" style="width: 100%;" v-model="yousiki_data.kaito_content"
                @change="clickCheckbox(i, index, $event)"/>
              </div>
            </div>
            <div class="col-12 form-inline" style="line-height: 40px" v-if="yousiki_data.select_type == 'TA'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{yousiki_data.select_content}}</label>
              <div class="col-12 form-inline">
                <textarea
                  style="width: 100%; height: 150px"
                  :value="yousiki_data.kaito_content"
                  @input="yousiki_data.kaito_content = $event.target.value"
                  @change="clickCheckbox(i, index, $event)"
                ></textarea>
              </div>
            </div>
            <div class="col-12" style="line-height: 40px" v-if="yousiki_data.select_type == 'N'">
              <label for
                class="col-form-label"
                style="justify-content: left; display: block"
                >{{yousiki_data.select_content}}</label>
              <div class="col-12 form-inline">
                <input type="number" v-model="yousiki_data.kaito_content"
                @change="clickCheckbox(i, index, $event)"/>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-center">
            <button class="btn btn-primary shadow" style="width: 120px" @click="regist">
              登録
            </button>
            <router-link
              class="btn btn-secondary shadow ml-3"
              style="width: 120px"
              :to="{ name: 'TPortal', params: { nendo: nendo, kensyu_cd: kensyu_cd } }"
            >
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
.card_shadow {
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}
</style>
