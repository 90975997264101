<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default {
    name: "PTantoRegist",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        passFlg1: true,
        passFlg2: true,
        passFlg3: true,
        kensyu_name: '',
        tdfk_name: '',
        tanto_name: '',
        tanto_kana: '',
        post_no: '',
        address: '',
        shozoku_name: '',
        tel: '',
        tel_biko: '',
        fax: '',
        mail1: '',
        mail2: '',
        mail3: '',
        regist_flg: '',
        old_password: '',
        password: '',
        password_confirmation: '',
        login_id: '',
        user_kbn: '',
        account_flg: '',
        resend_flg: '',
        kiko_mail: '',
        status :'',
        errors:[],
        error_message:'',
        error_message_no :'',
        errorStatus:false,
        successStatus:false,
        succsssMessageNo:'',
        successMessage:'',
        systemInitialDate:'',
        initial_data: null,
      };
    },
    mounted() {
      this.login_id = this.$store.state.Header.sessionData['login_id'];
      this.isLoading = true;
      if(this.$route.params.login_id){
        this.login_id = this.$route.params.login_id;
        this.account_flg = this.$route.params.account_flg;
        this.$store.commit("PTantoRegist/setLoginId", this.login_id);
        this.$store.commit("PTantoRegist/setAccountFlg", this.account_flg);
      } else {
        this.login_id = this.$store.state.PTantoRegist.login_id;
        this.account_flg = this.$store.state.PTantoRegist.account_flg;
      }
      this.getSuisenData();
      this.getSystemInitialDate();
    },
    methods: {
      regist: function () {
        var answer = window.confirm(this.$messages.MSG['I3006']);
        if (answer) {
          this.isLoading = true;
          this.successStatus = false;
          this.errorStatus = false;
          var request_data = {
            kensyu_name: this.kensyu_name,
            tanto_name: this.tanto_name,
            tanto_kana: this.tanto_kana,
            post_no: this.post_no,
            address: this.address,
            shozoku_name: this.shozoku_name,
            tel: this.tel,
            tel_biko: this.tel_biko,
            fax: this.fax,
            mail_1: this.mail1,
            mail_2: this.mail2,
            mail_3: this.mail3,
            regist_flg: this.regist_flg,
            account_flg: this.account_flg,
            URL_host: location.host,
            kiko_mail: this.kiko_mail,
            suisen_name: this.tdfk_name,
            old_password: this.old_password,
            password: this.password,
            password_confirmation: this.password_confirmation,
            system_initial_date : this.systemInitialDate,
            login_id : this.login_id,
            oldRequestData: {}
          };
          apiService.PTantosave(request_data)
          .then(result => {
              if(result.status=='success') {
                this.successStatus    = true;
                this.errorStatus      = false;
                this.succsssMessageNo = result.message_no;
                this.successMessage   = result.message;
                this.setOldRequestData();
                if(result.url_cd && result.user_kbn && !alert(this.$messages.MSG["I3002"].replace("|", "\n"))) {
                  apiService.logoutUser()
                  .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                      name: 'KeyWordAuth',
                      params: {
                        login_id: this.login_id
                      },
                      query: {
                        url_cd: result.url_cd,
                        user_kbn : result.user_kbn,
                      }
                    });
                  })
                  .catch(error => {
                    this.errorMsg = error.message.replaceAll("|","\n");
                    this.errorMsgNo = error.message_no;
                    this.isLoading = false;
                    document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
                  });
                } else {
                  this.getSystemInitialDate();
                  this.isLoading = false;
                }
              } else {
                this.errorStatus = true;
                this.successStatus = false;
                this.error_message = result.message.replaceAll("|","\n");
                this.error_message_no = result.message_no;
                this.isLoading = false;
              }
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          })
          .catch(error => {
            this.isLoading = false;
            this.errorStatus = true
            this.successStatus = false
            this.errors = [];
            this.error_message ='';
            this.error_message_no ='';
            if(error.errors) {
              this.errors = error.errors;
            } else {
              this.error_message = error.message.replaceAll("|","\n");
              this.error_message_no = error.message_no;
            }
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          });
        }
      },
      backclick: function () {
        if(this.regist_flg == '1'){
          this.$router.push({ name: "PPortal" });
        } else {
          this.$router.push({ name: "PLogin" });
        }
      },
      getSuisenData() {
        this.isLoading = true;
        apiService.getSuisenData()
        .then(result => {
          this.suisenData = result.suisenData;
          this.kensyu_name = result.suisenData.kensyu_name !== null ? result.suisenData.kensyu_name : '';
          this.tdfk_name = result.suisenData.tdfk_name !== null ? result.suisenData.tdfk_name : '';
          this.tanto_name = result.suisenData.tanto_name !== null ? result.suisenData.tanto_name : '';
          this.tanto_kana = result.suisenData.tanto_kana !== null ? result.suisenData.tanto_kana : '';
          this.post_no = result.suisenData.post_no !== null ? result.suisenData.post_no : '';
          this.address = result.suisenData.address !== null ? result.suisenData.address : '';
          this.shozoku_name = result.suisenData.shozoku_name !== null ? result.suisenData.shozoku_name : '';
          this.tel = result.suisenData.tel !== null ? result.suisenData.tel : '';
          this.tel_biko = result.suisenData.tel_biko !== null ? result.suisenData.tel_biko : '';
          this.fax = result.suisenData.fax !== null ? result.suisenData.fax : '';
          this.mail1 = result.suisenData.mail_1 !== null ? result.suisenData.mail_1 : '';
          this.mail2 = result.suisenData.mail_2 !== null ? result.suisenData.kensmail_2yu_name : '';
          this.mail3 = result.suisenData.mail_3 !== null ? result.suisenData.mail_3 : '';
          this.regist_flg = result.suisenData.regist_flg !== null ? result.suisenData.regist_flg : '';
          this.resend_flg = result.suisenData.resend_flg !== null ? result.suisenData.resend_flg : '';
          this.kiko_mail = result.suisenData.kiko_mail !== null ? result.suisenData.kiko_mail : '';
          // 初期表示時点の入力情報を保持
          this.setOldRequestData();
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
            }
          })
          .catch(error => {
            console.log(error)
        });
      },
      setOldRequestData() {
        this.oldRequestData 
          = JSON.parse(JSON.stringify({
            tanto_name: this.tanto_name,
            tanto_kana: this.tanto_kana,
            post_no: this.post_no,
            address: this.address,
            shozoku_name: this.shozoku_name,
            tel: this.tel,
            tel_biko: this.tel_biko,
            fax: this.fax,
            mail_1: this.mail1,
            mail_2: this.mail2,
            mail_3: this.mail3,
            old_password: this.old_password,
            password: this.password,
            password_confirmation: this.password_confirmation
          }))
      },
      checkInputChange() {
        var org = JSON.stringify(this.oldRequestData, jsonReplace);
        if (org === '{}') {
          return true;
        }
        var dest = JSON.stringify({
          tanto_name: this.tanto_name,
          tanto_kana: this.tanto_kana,
          post_no: this.post_no,
          address: this.address,
          shozoku_name: this.shozoku_name,
          tel: this.tel,
          tel_biko: this.tel_biko,
          fax: this.fax,
          mail_1: this.mail1,
          mail_2: this.mail2,
          mail_3: this.mail3,
          old_password: this.old_password,
          password: this.password,
          password_confirmation: this.password_confirmation
        }, jsonReplace);

        return org === dest;
      },
    },
    beforeRouteLeave(to, from, next) {
      var input_check = this.checkInputChange();
      var answer = true;
      if (!input_check) {
        answer = window.confirm(this.$messages.MSG['I3005']);
      }
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  };
  function jsonReplace(key, val) {
    if(typeof val == "string" && val == ""){
      return null;
    }
    return val;
  }
  </script>
  <template>
    <AppLayout>
      <Loading :isLoading='isLoading' :fullPage='fullPage' />
      <div id="contentTop"></div>
      <main v-bind:class="errorStatus || successStatus ? 'mb-3' : 'mt-4 mb-3'">
        <b-alert
          dismissible
          fade
          :show="successStatus"
          @dismissed="successStatus=false"
          >
        <div class="col-md-12" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}</div>
        </b-alert>
        <b-alert
          variant="danger"
          dismissible
          fade
          :show="errorStatus"
          @dismissed="errorStatus=false"
          >
          <div class="col-md-12" v-for="(value,index) in errors" :key="index">
            {{value.slice('')[0].split('/')[1]}} {{value.slice('')[0].split('/')[0]}}
          </div>
          <div class="col-md-12" v-if="error_message">
            {{ error_message_no }} {{ error_message }}
          </div>
        </b-alert>
        <div id="mainTable" class="container" style="max-width: 90%;">
          <h4 class="mt-3">推薦担当者登録</h4>
          <div class="row">
            <div class="
              warnCell
              col-md-2 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              text-white
              "
            >
              研修名
            </div>
            <div class="
              subCell
              col-md-4 col-12
              bg-light
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
            ">
              {{kensyu_name}}
            </div>
            <div class="
              warnCell
              col-md-2 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              text-white
            ">
              推薦機関名
            </div>
            <div class="
              subCell
              col-md-4 col-12
              bg-light
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
            ">
              {{tdfk_name}}
            </div>
          </div>
          <div class="row row_border">
            <div class="
              warnCell
              col-md-2 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              text-white
            ">
              ID
            </div>
            <div class="
              subCell
              col-md-10 col-12
              bg-light
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
            ">
              {{login_id}}
            </div>
          </div>

          <div class="row mt-4">
            備考、メールアドレス２、メールアドレス３以外は必須項目です。
          </div>

          <div class="row">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
              担当者氏名
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
              <input
                type="text"
                class="form-control bg-required"
                tabindex="1"
                style="width: 400px"
                placeholder="担当者 太郎"
                v-model="tanto_name"
              />
            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
              フリガナ
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
              <input
                type="text"
                class="form-control bg-required"
                tabindex="1"
                style="width: 400px"
                placeholder="タントウシャ タロウ"
                v-model="tanto_kana"
              />
            </div>
          </div>
          <div class="row">
            <div class="
              mainCell
              col-md-1 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              row_border
            "
            >
              所属
            </div>

            <div class="col-md-3 col-12">
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  border-left
                  row_border
                ">
                  郵便番号
                </div>
              </div>
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  row_border
                  border-left
                ">
                  所在地
                </div>
              </div>
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  row_border
                  border-left
                ">
                  名称
                </div>
              </div>
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  row_border
                  border-left
                ">
                  電話番号
                </div>
              </div>
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  row_border
                  border-left
                ">
                備考(電話番号用)
                </div>
              </div>
              <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
                <div class="
                  mainCell
                  col-12
                  d-flex
                  align-items-center
                  float-left
                  text-center text-md-left
                  table_height_sub
                  bg-primary
                  text-white
                  row_border
                  border-left
                ">
                FAX番号
                </div>
              </div>
            </div>
            <div class="col-md-8 col-12 px-0">
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                郵便番号
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="post_no" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="123-4567"/>
              </div>
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                所在地
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="address" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="△△市△△町△－△－△ △△ビル△階"/>
              </div>
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                名称
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="shozoku_name" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="△△△△教育委員会 △△課"/>
              </div>
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                電話番号
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="tel" type="text" class="form-control bg-required" tabindex="1" style="width: 400px" placeholder="01-2345-6789(1234)"/>
              </div>
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                備考(電話番号用)
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="tel_biko" type="text" class="form-control" tabindex="1" style="width: 400px" placeholder="内線番号、宛て等"/>
              </div>
              <div class="
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-dark
                text-white
                d-md-none
                row_border
              ">
                FAX番号
              </div>
              <div class="
                subCell
                col-12
                d-flex
                align-items-center
                float-left
                table_height_sub
                text-center text-md-left
                bg-light
                row_border
              ">
                <input v-model="fax" type="text" class="form-control" tabindex="1" style="width: 400px" placeholder="01-2345-6789"/>
              </div>

            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
            メールアドレス１
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
              <input
                type="text"
                class="form-control bg-required"
                tabindex="1"
                style="width: 400px"
                placeholder="xxxx@xxxx.co.jp"
                v-model="mail1"
              />
            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
            メールアドレス２
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
              <input
                type="text"
                class="form-control"
                tabindex="1"
                style="width: 400px"
                placeholder="xxxx@xxxx.co.jp"
                v-model="mail2"
              />
            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
            ">
            メールアドレス３
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height
              text-center text-md-left
              bg-light
            ">
              <input
                type="text"
                class="form-control"
                tabindex="1"
                style="width: 400px"
                placeholder="xxxx@xxxx.co.jp"
                v-model="mail3"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              "
              v-if="account_flg != '1'"
            >
              <font v-if="resend_flg !='1'">旧パスワード</font><font v-else>仮パスワード</font><font color="#ff7777" v-if="resend_flg =='1'">※</font>
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              "
              v-if="account_flg != '1'"
            >
              <div v-if="passFlg1" style="position: relative">
                <input
                  type="password"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="old_password"
                  v-if="regist_flg == '0'"
                />
                <input
                  type="password"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="old_password"
                  v-else
                />
                <label class="icon-pass" @click="passFlg1 = false">
                  <b-icon icon="eye-fill"></b-icon>
                </label>
              </div>
              <div v-else style="position: relative">
                <input
                  type="text"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="old_password"
                  v-if="regist_flg == '0'"
                />
                <input
                  type="text"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="old_password"
                  v-else
                />
                <label class="icon-pass" @click="passFlg1 = true">
                  <b-icon icon="eye-slash-fill"></b-icon>
                </label>
              </div>
            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              "
              v-if="account_flg == '1'"
            >
              パスワード
            </div>
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              "
              v-else
            >
              新パスワード
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              "
            >
              <div v-if="passFlg2" style="position: relative">
                <input
                  type="password"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password"
                  v-if="account_flg == '1' || regist_flg == '0'"
                />
                <input
                  type="password"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password"
                  v-else
                />
                <label class="icon-pass" @click="passFlg2 = false">
                  <b-icon icon="eye-fill"></b-icon>
                </label>
              </div>
              <div v-else style="position: relative">
                <input
                  type="text"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password"
                  v-if="account_flg == '1' || regist_flg == '0'"
                />
                <input
                  type="text"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password"
                  v-else
                />
                <label class="icon-pass" @click="passFlg2 = true">
                  <b-icon icon="eye-slash-fill"></b-icon>
                </label>
              </div>
            </div>
          </div>
          <div class="row row_border">
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              "
              v-if="account_flg == '1'"
            >
              パスワード(確認入力)
            </div>
            <div class="
              mainCell
              col-md-4 col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height
              bg-primary
              text-white
              "
              v-else
            >
              新パスワード(確認入力)
            </div>
            <div class="
              subCell
              col-md-8 col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              "
            >
              <div v-if="passFlg3" style="position: relative">
                <input
                  type="password"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password_confirmation"
                  v-if="account_flg == '1' || regist_flg == '0'"
                />
                <input
                  type="password"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password_confirmation"
                  v-else
                />
                <label class="icon-pass" @click="passFlg3 = false">
                  <b-icon icon="eye-fill"></b-icon>
                </label>
              </div>
              <div v-else style="position: relative">
                <input
                  type="text"
                  class="form-control bg-required"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password_confirmation"
                  v-if="account_flg == '1' || regist_flg == '0'"
                />
                <input
                  type="text"
                  class="form-control bg-white"
                  tabindex="1"
                  style="width: 400px"
                  autocomplete="new-password"
                  v-model="password_confirmation"
                  v-else
                />
                <label class="icon-pass" @click="passFlg3 = true">
                  <b-icon icon="eye-slash-fill"></b-icon>
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-12">
              パスワードは、数字と英字まじりの８～３０文字を設定してください。
            </div>
            <div class="col-12" style="color: red" v-if="resend_flg =='1'">
              ※仮パスワードを入力してください。
            </div>
          </div>
          <div class="w-100 text-center text-md-right mb-2">
            <button
              class="btn btn-primary d-inline-block"
              style="width: 100px"
              @click="regist"
            >
              登録
            </button>

            <button
              class="btn btn-secondary d-inline-block ml-3"
              style="width: 100px"
              @click="backclick"
              v-if="account_flg == '0'"
            >
              戻る
            </button>
          </div>
        </div>
      </main>
    </AppLayout>
  </template>
  <style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .border-left {
    border-left: 1px solid #a1a1a1 !important;
  }
  </style>
