
const defaultState = {
  nendo: '',
  kensyu_cd: '',
  kaisai_no: ''
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setNendo(state, result) {
      state.nendo = result;
    },
    setKensyuCd(state, result) {
      state.kensyu_cd = result;
    },
    setKaisaiNo(state, result) {
      state.kaisai_no = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  },
  actions: {
  },
  getters: {
  }
};
