const defaultState = {
    loginUserName :"",
    sessionData: {},
    backGroundColor: "secondary",
    fontSize: "secondary",
    registFlg: '1',
};
  export default {
    namespaced: true,
    state: defaultState,
    mutations: {
      setLoginUserName(state, result) {
        state.loginUserName = result ?? 'ユーザ名';
      },
      setSessionData(state, result) {
        state.sessionData = result;
      },
      init(state) {
        Object.assign(state, defaultState);
      },
      setFontSize(state, result) {
        state.fontSize = result;
      },
      setBackGroundColor(state, result) {
        state.backGroundColor = result;
      },
      setRegistFlg(state, result) {
        state.registFlg = result;
      },
    },
    actions : {

    },
    getters : {

    }
  };
