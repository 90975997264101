<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: "PZRegMeg",
    data() {
      return {
        modes: ['multi', 'single', 'range'],

      };
    },
    mounted() {
    },
    methods: {
      setClose() {
      },
    },
  });
</script>
<template>
  <main>
    現在入力されている研修受講者データがアップロードしたデータで上書きされます。<br>
    よろしいですか。
  </main>
</template>
