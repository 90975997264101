
const defaultState = {
  account_flg: '',
  login_id: ''
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setAccountFlg(state, result) {
      state.account_flg = result;
    },
    setLoginId(state, result) {
      state.login_id = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  },
  actions: {
  },
  getters: {
  }
};
