
const defaultState = {
    kensyu_cd :'',
    kaisai_no :''
};
  export default {
    namespaced: true,
    state: defaultState,
    mutations: {
      setKensyuCd(state, result) {
        state.kensyu_cd = result;
      },
      setKaisaiNo(state, result) {
        state.kaisai_no = result;
      },
      clear(state) {
        state.kensyu_cd = "";
        state.kaisai_no = "";
      }
    },
    actions : {
    },
    getters : {
    }
  };