<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "PassWordResend",
  components: {
    AppLayout,
  },
  data() {
    return {
      error: "",
      passFlg: true,
      loginId: "",
      password: "",
      mailAddress: "",
      updateFlg: false,
      user_kbn: localStorage.getItem("USER_KBN"),
      loginScreen: "",
      errorMsg: [],
      errorNo: [],
      errorStatus: false,
      successStatus:false,
      succsssMessageNo:'',
      successMessage:'',
    };
  },
  mounted() {
    this.updateUserKbn();
  },
  methods: {
    updateUserKbn: function () {
      if (this.user_kbn) {
        localStorage.setItem("USER_KBN", this.user_kbn);
        switch (this.user_kbn) {
          case "1":
            this.loginScreen = "PLogin";
            break;
          case "2":
            this.loginScreen = "SLogin";
            break;
          case "3":
            this.loginScreen = "TLogin";
            break;
        }
      } else {
        this.loginScreen = "SLogin";
      }
    },
    clickButton: function() {
      this.clearMessage();

      //メールアドレスを更新する場合は確認メッセージを表示する。
      if(this.updateFlg && !confirm(this.$messages.MSG["I3019"])){
        return;
      }
      let request_data = {
        'login_id': this.loginId,
        'mail_address': this.mailAddress,
        'system_initial_date': this.systemInitialDate
      }
      apiService.passWordResend(request_data)
      .then(result => {
        console.log(result);
        if(result.status == "error") {
          this.successStatus = false;
          this.errorStatus = true;
          this.errorNo[0] = result.message_no;
          this.errorMsg[0] = result.message.replaceAll("|","\n");
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          return;
        } else {
          this.errorStatus = false;
          this.successStatus = true;
          this.succsssMessageNo = result.message_no;
          this.successMessage = result.message.replaceAll("|","\n");
        }
      })
      .catch(error => {
        if (error.errors) {
          this.errorStatus = true;
          let msgs = Object.entries(error.errors);
          for (let i = 0; i < msgs.length; i++) {
            this.errorMsg[i] = msgs[i][1][0].split("/")[0];
            this.errorNo[i] = msgs[i][1][0].split("/")[1];
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
        console.log(this.error)
      });
    },
    clearMessage(){
      this.errorStatus = false;
      this.errorMsg = [];
      this.errorNo = [];
    },
  }
};
</script>

<template>
  <AppLayout>
    <main class="contents-height">
      <div id="contentTop"></div>
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
      <div class="col-md-12" v-if="successMessage">
        {{ succsssMessageNo }} {{ successMessage }}</div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus = false"
      >
        <div class="col-md-12 mb-0" style="white-space: pre !important" v-for="(msg, index) in errorMsg" :key="index">{{ errorNo[index] }} {{ msg.replace("|", "\n") }}</div>
      </b-alert>
      <div class="container text-center">
        <h4 class="font-weight-bold text-black">
          パスワード再発行画面
        </h4>
      </div>
      <h6 class="font-weight-normal text-center">
        IDとメールアドレスを入力して下さい。
      </h6>
      <div class="container login-body rounded text-center">
        <form class="form-keyword">
          <label for="login-id" class="sr-only">ID</label>
          <input
            type="text"
            id="login-id"
            class="form-control"
            style="ime-mode: disabled"
            placeholder="ID"
            v-model="loginId"
            autofocus
          />
          <label for="inputPassword" class="sr-only">メールアドレス</label>
          <input type="email"
          id="email"
          class="form-control"
          style="padding-right: 20px;"
          placeholder="メールアドレス"
          v-model="mailAddress">
          <router-link
            :to="{ name: loginScreen }"
            class="font-weight-normal text-primary d-block text-right"
            style="font-size: 15px"
          >
            ログイン画面へ
          </router-link>
          <button
            type="button"
            class="btn btn-lg btn-block btn-outline-primary mt-3"
            @click="clickButton"
          >
            送信
          </button>
        </form>
      </div>
    </main>
  </AppLayout>
</template>
