<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "TKadai",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullpage: true,
      errorStatus: false,
      error_message_no: '',
      error_message: '',
      nendo: '',
      kensyu_cd: '',
      kaisai_no: '',
      kensyu_name: "",
      fields: [
        {
          key: "no",
          label: "No.",
          class: "text-nowrap text-center",
          sortable: true,
        },
        {
          key: "kadai_name",
          label: "課題名",
          sortable: true,
          thClass: "text-center",
          class: "text-nowrap",
        },
        {
          key: "kadai_kensu",
          label: "提出ファイル",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-left text-nowrap file-width",
        },
        {
          key: "torokudate",
          label: "登録日",
          sortable: true,
          class: "text-center text-nowrap ",
        },
        {
          key: "simekiri_flg",
          label: "状態",
          sortable: true,
          class: "text-center text-nowrap",
        },
        {
          key: "kousi_dl_flg",
          label: "ダウンロード許可",
          sortable: true,
          class: "text-center text-nowrap",
        },
      ],
      items: [],
      login_id: '',
    };
  },
  mounted() {
    this.nendo = this.$store.state.TPortal.nendo;
    this.kensyu_cd = this.$store.state.TPortal.kensyu_cd;
    this.kaisai_no = this.$store.state.TPortal.kaisai_no;
    this.login_id = this.$store.state.Header.sessionData['login_id'];
    this.getKensyuNameAndKaisaiName();
    this.getKadaiInfo();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
    Btn_LightDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? "btn btn-outline-secondary-light shadow ml-1" : "btn btn-outline-secondary-dark shadow ml-1";
    },
  },
  methods: {
    getKensyuNameAndKaisaiName() {
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no
      }
      apiService
        .TKadaiGetKensyuNameAndKaisaiName(request_data)
        .then((result) => {
          this.kensyu_name = result.data[0].kensyu_name + '　' + result.data[0].kaisai_name;
        })
        .catch((error) => {
          console.log(error);
          this.err = error.message.replaceAll("|","\n");
          this.isLoading = false;
        });
    },

    getKadaiInfo() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no
      }
      apiService
        .TKadaiGetKadaiInfo(request_data)
        .then((result) => {
          if(result.status == 'success') {
            this.errorStatus = false;
            this.error_message_no = '';
            this.error_message = '';
            this.items = result.assignInfo;
            this.isLoading = false;
          } else {
            this.errorStatus = true;
            this.error_message_no = result.message_no;
            this.error_message = result.message.replaceAll("|","\n");
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    download(kadai_name, oi_no) {
      this.isLoading = true;
      let vm = this;
      var request_data = {
        kensyu_name: this.kensyu_name,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        oi_no: oi_no,
        kadai_name: kadai_name,
        column_id: this.$constants.COLUMN_ID.TKADAI,
        login_id: this.login_id,
      }
      apiService
        .TkadaiDownloadExcel(request_data)
        .then((result) => {
          if (result != false) {
            vm.downloadExcel(result, this.kensyu_name +'_'+ kadai_name +'_'+ oi_no + '.zip');
          } else {
            this.errorStatus = true;
            this.error_message = this.$messages.MSG['E1029'];
            this.error_message_no = 'E1029';
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        })
        .catch((error) => {
          this.blogErrorMessages(error);
        });
    },

    downloadExcel(result, fileName) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      }
    },
    blogErrorMessages(error){
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      )
      {
        errormessage = new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.onerror = () => {
            reject(error);
          };
          reader.readAsText(error.data);
        })
        .then(err => {
          console.log(err.data)
        });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        if(error.data.status=='error'){
          this.errorStatus = true;
          this.error_message = error.data.message.replaceAll("|","\n");
          this.error_message_no = error.data.message_no;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      });
    },
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3 mt-2" id="skadai">
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">講師課題参照</h4>

        <div id="mainTable">
          <div class="row border-bottom">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ kensyu_name }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col col-form-labe px-0">
            受講者提出課題
          </div>
        </div>

        <div class="row">
          <div class="col px-0">
            <b-table
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="items"
              :fields="fields"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #cell(kadai_kensu)="fileData">
                <div class="form-inline" style="width: 200px;">
                  <div class="custom-file" style="width: 60px;">
                    <label
                      v-if="fileData.item.kadai_kensu !== ''"
                      class="text-left"
                      for="inputFile"
                      >{{ fileData.value }}
                    </label>
                    <label
                      v-else
                      class="text-left"
                      for="inputFile"
                      >なし</label
                    >
                  </div>
                  <button
                    v-if="
                      fileData.item.kadai_kensu !== '' &&
                      fileData.item.kousi_dl_flg !== '未許可'
                    "
                    :class="Btn_LightDark"
                    @click="download(fileData.item.kadai_name, fileData.item.oi_no)"
                  >
                    ダウンロード
                  </button>
                  <button
                    v-else
                    :class="Btn_LightDark"
                    @click="download(fileData.item.kadai_name, fileData.item.oi_no)"
                    style="width: 120px"
                    disabled
                  >
                    ダウンロード
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row">
          <div class="col text-center text-md-left">
            <router-link
              class="btn btn-secondary shadow"
              style="width: 140px"
              :to="{ name: 'TPortal', params: { nendo: nendo, kensyu_cd: kensyu_cd } }"
            >
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
</style>
