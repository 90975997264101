const USER_KBN_P = 1;
const USER_KBN_S = 2;
const USER_KBN_T = 3;
const COLUMN_ID = {
  'TKADAI' : 'Tkadai',
};

const SCREEN_NAME = {
  'TMS010A': 'ログイン認証',
  'TMS010B': 'パスワード変更',
  'TMM010': 'メインメニュー',
  'AUTHERROR': '権限エラー'
};

const EXTENSION_LIST = ['doc','docx','xls','xlsx','ppt','pptx','pdf','jtdc','jttc','jtd','jtc','jfw','jvw','zip'];

export const TAISYO_KBN_SUISEN = 2;

export default {
  SCREEN_NAME,
  USER_KBN_P,
  USER_KBN_S,
  USER_KBN_T,
  EXTENSION_LIST,
  COLUMN_ID
}
