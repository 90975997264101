
const defaultState = {
  initial_zenhaku_umu: '',
  zenhaku_umu: '',
  kaisai_date_start: '',
  kaisai_date_end: '',
  syku_type: '',
  syku_riyo_date_start: '',
  syku_riyo_date_end: '',
  bus_riyo: '',
  parking_riyo: '',
  parking_riyo_date_start: '',
  parking_riyo_date_end: '',
  car_no_1: '',
  car_no_2: '',
  car_no_3: '',
  car_no_4: '',
  kinmusaki_tel: '',
  kinmusaki_tel_biko: '',
  kinmusaki_fax: '',
  regist_mode: '',
  renewalDate: '',
  bus_ryokin: '',
  syku_ryokin: '',
  parking_riyo_nisuu_ikatu: '',
  parking_ryokin_day: '',
  parking_ryokin_ikatu: ''
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setInitial_zenhaku_umu(state, result) {
      state.initial_zenhaku_umu = result;
    },
    setZenhaku_umu(state, result) {
      state.zenhaku_umu = result;
    },
    setKaisai_date_start(state, result) {
      state.kaisai_date_start = result;
    },
    setKaisai_date_end(state, result) {
      state.kaisai_date_end = result;
    },
    setSyku_type(state, result) {
      state.syku_type = result;
    },
    setSyku_riyo_date_start(state, result) {
      state.syku_riyo_date_start = result;
    },
    setSyku_riyo_date_end(state, result) {
      state.syku_riyo_date_end = result;
    },
    setBus_riyo(state, result) {
      state.bus_riyo = result;
    },
    setParking_riyo(state, result) {
      state.parking_riyo = result;
    },
    setParking_riyo_date_start(state, result) {
      state.parking_riyo_date_start = result;
    },
    setParking_riyo_date_end(state, result) {
      state.parking_riyo_date_end = result;
    },
    setCar_no_1(state, result) {
      state.car_no_1 = result;
    },
    setCar_no_2(state, result) {
      state.car_no_2 = result;
    },
    setCar_no_3(state, result) {
      state.car_no_3 = result;
    },
    setCar_no_4(state, result) {
      state.car_no_4 = result;
    },
    setKinmusaki_tel(state, result) {
      state.kinmusaki_tel = result;
    },
    setKinmusaki_tel_biko(state, result) {
      state.kinmusaki_tel_biko = result;
    },
    setKinmusaki_fax(state, result) {
      state.kinmusaki_fax = result;
    },
    setRegist_mode(state, result) {
      state.regist_mode = result;
    },
    setRenewalDate(state, result) {
      state.renewalDate = result;
    },
    setBus_ryokin(state, result) {
      state.bus_ryokin = result;
    },
    setSyku_ryokin(state, result) {
      state.syku_ryokin = result;
    },
    setParking_riyo_nisuu_ikatu(state, result) {
      state.parking_riyo_nisuu_ikatu = result;
    },
    setParking_ryokin_day(state, result) {
      state.parking_ryokin_day = result;
    },
    setParking_ryokin_ikatu(state, result) {
      state.parking_ryokin_ikatu = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  },
  actions: {
  },
  getters: {
  }
};
