<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import PZRegMsg from '@/pages/PZRegMsg/index.vue'
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();
  import moment from 'moment';

  export default {
    name: "PZyukosyaRegist",
    components: {
      AppLayout,
      PZRegMsg,
      Loading,
    },
    data() {
      return {
        registFlg: false,
        isLoading: false,
        fullpage: true,
        login_id: '',
        kaisai_no: '',
        kensyu_name: '',
        kaisai_date: '',
        jyuko_su: '',
        tdfk_name: '',
        last_jyuko_ins_date: '',
        kakutei_flg: '',
        kakutei_text: '',
        nendo: [],
        kensyu_cd: [],
        tdfk_cd: [],
        tosi_cd: [],
        tosi_type: [],
        daigaku_cd: [],
        meisai_no :[],
        todofuken_code: '',
        tosi_code: '',
        cyukaku_cd: '',
        seibetsu_cd: '',
        shoku_cd: '',
        shozoku_kikan_type_cd: '',
        tdfk_code: '00',
        fields: [
          {key: 'col1',  label: '推薦機関', sortable:true, class: "text-center text-nowrap",},
          {key: 'col2',  label: '氏名', sortable:true, class: "text-center text-nowrap",},
          {key: 'col3',  label: 'フリガナ', sortable:true, class: "text-center text-nowrap",},
          {key: 'col4',  label: '年齢', sortable:true, class: "text-center text-nowrap",},
          {key: 'col5',  label: '性別', sortable:true, class: "text-center text-nowrap",},
          {key: 'col6',  label: '名称', sortable:true, class: "text-center text-nowrap",},
          {key: 'col7',  label: '職名１', sortable:true, class: "text-center text-nowrap",},
          {key: 'col8',  label: '職名２', sortable:true, class: "text-center text-nowrap",},
          {key: 'col9',  label: '郵便番号', sortable:true, class: "text-center text-nowrap",},
          {key: 'col10', label: '住所', sortable:true, class: "text-center text-nowrap",},
          {key: 'col11', label: '所属機関種別', sortable:true, class: "text-center text-nowrap",},
        ],
        items: [],
        jyuko_info:{ col1: '', col2: '', col3: '', col4: '', col5: '',col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '', col13: '', col14: '', col15: '', col16: '', col17: '', col18: '', col19: '', col20: '', col21: '', col22: '', col23: '', col24: '', col25: '', col26: '', col27: '', col28: '', col29: '', col30: '', col31: '', col32: '' },
        add_row:{ col1: '', col2: '', col3: '', col4: '', col5: '',col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '', col13: '', col14: '', col15: '', col16: '', col17: '', col18: '', col19: '', col20: '', col21: '', col22: '', col23: '', col24: '', col25: '', col26: '', col27: '', col28: '', col29: '', col30: '', col31: '', col32: '' },
        todofunken_name: {},
        seibetsu: {},
        shokuName: {},
        gakko: {},
        import_file: '',
        fileData: { col1: '', col2: '', col3: '', col4: '', col5: '',col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '', col13: '', col14: '', col15: '', col16: '', col17: '', col18: '', col19: '', col20: '', col21: '', col22: '', col23: '', col24: '', col25: '', col26: '', col27: '', col28: '', col29: '', col30: '', col31: '', col32: '' },
        uploadFileSize: "",
        max_row: '',
        uploadStatus: false,
        successStatus: false,
        successMessage: '',
        succsssMessageNo: '',
        errorStatus:false,
        error_message:'',
        error_message_no :'',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        prtParam:
        {
          nendo: "",
          kensyuCd: "",
          kaisaiNo: "",
          tdfkCd: "",
          tosiCd: "",
          daigakuCd: "",
          tosiType: ""
        },
        oldRequestData: {},
        uploadsuccess_checkflg: false,
        uploadFiledata: [],
      };
    },
    mounted() {
      this.login_id = this.$store.state.Header.sessionData['login_id'];

      if(this.$route.params.kaisai_no){
        this.kaisai_no = this.$route.params.kaisai_no
        this.$store.commit("PZyukosyaRegist/setKaisaiNo", this.kaisai_no);
      } else {
        this.kaisai_no = this.$store.state.PZyukosyaRegist.kaisai_no;
      }

      this.getKensyuInfo();
      this.getJyukoInfo();
      this.getSeibetsu();
      this.getShokuName();
      this.getGakkoShu();
      this.getSystemInitialDate();
    },
    methods: {
      tempRegist: function () {
        var answer = window.confirm(this.$messages.MSG['I3006']);
        if (answer == false) {
          return;
        }
        this.isLoading = true;
        this.successStatus = false;
        this.errorStatus   = false;
        var File_data = [];

        this.items.forEach(function(data) {
          File_data.push(data);
        });

        var request_data = {
          items: File_data,
          fields: this.fields,
          login_id: this.login_id,
          tosi_type: this.prtParam.tosiType,
          system_initial_date : this.systemInitialDate,
          suisen_nendo : this.prtParam.nendo,
          suisen_kensyu_cd : this.prtParam.kensyuCd,
          suisen_kaisai_no : this.prtParam.kaisaiNo,
          suisen_tdfk_cd : this.prtParam.tdfkCd,
          suisen_tosi_cd : this.prtParam.tosiCd,
          suisen_daigaku_cd : this.prtParam.daigakuCd
        }
        apiService.PZyukosyaTempSave(request_data)
        .then(result => {
          if(result.status=='success') {
            this.status=2;
            this.successStatus    = true;
            this.errorStatus      = false;
            this.succsssMessageNo = result.message_no;
            this.successMessage   = result.message.replaceAll("|","\n");
            this.kakutei_flg = '0';
            this.setOldRequestData();
            this.getSystemInitialDate();
          }
          else {
            this.errorStatus = true
            this.successStatus = false
            this.errors = [];
            this.error_message = '';
            this.error_message_no = '';
            if(result.errors) {
              this.errors = result.errors;
            } else {
              this.error_message = result.message.replaceAll("|","\n");
              this.error_message_no = result.message_no;
            }
            this.isLoading = false;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        })
        .catch(error => {
          this.errorStatus = true
          this.successStatus = false
          this.errors = [];
          this.error_message ='';
          this.error_message_no ='';
          if(error.errors) {
            this.errors = error.errors;
          } else {
            this.error_message = error.message.replaceAll("|","\n");
            this.error_message_no = error.message_no;
          }
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
      },
      confirm: function () {
        var answer = window.confirm(this.$messages.MSG['I3006']);
        if (answer == false) {
          return;
        }
        this.isLoading = true;
        this.successStatus = false;
        this.errorStatus   = false;
        var File_data = [];

        this.items.forEach(function(data) {
          File_data.push(data);
        });

        var request_data = {
          items: File_data,
          fields: this.fields,
          login_id: this.login_id,
          tosi_type: this.prtParam.tosiType,
          system_initial_date : this.systemInitialDate,
          suisen_nendo : this.prtParam.nendo,
          suisen_kensyu_cd : this.prtParam.kensyuCd,
          suisen_kaisai_no : this.prtParam.kaisaiNo,
          suisen_tdfk_cd : this.prtParam.tdfkCd,
          suisen_tosi_cd : this.prtParam.tosiCd,
          suisen_daigaku_cd : this.prtParam.daigakuCd
        }
        apiService.PZyukosyaConfirm(request_data)
        .then(result => {
            if(result.status=='success') {
              this.status=2;
              this.successStatus    = true;
              this.errorStatus      = false;
              this.succsssMessageNo = result.message_no;
              this.successMessage   = result.message.replaceAll("|","\n");
              this.kakutei_flg = '1';
              this.setOldRequestData();
              this.getSystemInitialDate();
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              window.confirm(this.$messages.MSG['I3009']);
            }
            else{
              this.errorStatus = true
              this.successStatus = false
              this.errors = [];
              this.error_message ='';
              this.error_message_no ='';
              if(result.errors) {
                this.errors = result.errors;
              } else {
                this.error_message =  result.message.replaceAll("|","\n");
                this.error_message_no = result.message_no;
              }
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            }
        })
        .catch(error => {
          this.errorStatus = true
          this.successStatus = false
          this.errors = [];
          this.error_message ='';
          this.error_message_no ='';
          if(error.errors) {
            this.errors = error.errors;
          } else {
            this.error_message = error.message.replaceAll("|","\n");
            this.error_message_no = error.message_no;
          }
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
      },
      Back: function () {
        this.$router.push({ name: "PPortal" });
      },
      addRow: function () {
        this.sortBy = '';
        this.sortDesc = false;
        if(this.items.length < this.max_row) {
          this.items.push({ col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '', col13: '', col14: '', col15: '', col16: '', col17: '', col18: '', col19: '', col20: '', col21: '', col22: '', col23: '', col24: '', col25: '', col26: '', col27: '', col28: '', col29: '', col30: '', col31: '', col32: '' },)
        } else {
          this.errorStatus = true;
          this.error_message = this.$messages.MSG['E1035'];
          this.error_message_no = 'E1035';
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      },
      removeRow: function(row){
        row.item.col1 = '';
        row.item.col2 = '';
        row.item.col3 = '';
        row.item.col4 = '';
        row.item.col5 = '';
        row.item.col6 = '';
        row.item.col7 = '';
        row.item.col8 = '';
        row.item.col9 = '';
        row.item.col10 = '';
        row.item.col11 = '';
        row.item.col12 = '';
        row.item.col13 = '';
        row.item.col14 = '';
        row.item.col15 = '';
        row.item.col16 = '';
        row.item.col17 = '';
        row.item.col18 = '';
        row.item.col19 = '';
        row.item.col20 = '';
        row.item.col21 = '';
        row.item.col22 = '';
        row.item.col23 = '';
        row.item.col24 = '';
        row.item.col25 = '';
        row.item.col26 = '';
        row.item.col27 = '';
        row.item.col28 = '';
        row.item.col29 = '';
        row.item.col30 = '';
        row.item.col31 = '';
        this.seibetsu_cd = '';
        this.shoku_cd = '';
        this.shozoku_kikan_type_cd = '';
      },
      getKensyuInfo() {
        this.isLoading = true;
        var start_date;
        var end_date;
        var request_data = {
          login_id: this.login_id,
          kaisai_no: this.kaisai_no,
        }
        apiService.getKensyuInfo(request_data)
        .then(result => {
          if(result.kensyuInfo[0]['item_meisyo_01']) {
            this.fields.push({key: 'col12', label: result.kensyuInfo[0]['item_meisyo_01'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_02']) {
            this.fields.push({key: 'col13', label: result.kensyuInfo[0]['item_meisyo_02'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_03']) {
            this.fields.push({key: 'col14', label: result.kensyuInfo[0]['item_meisyo_03'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_04']) {
            this.fields.push({key: 'col15', label: result.kensyuInfo[0]['item_meisyo_04'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_05']) {
            this.fields.push({key: 'col16', label: result.kensyuInfo[0]['item_meisyo_05'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_06']) {
            this.fields.push({key: 'col17', label: result.kensyuInfo[0]['item_meisyo_06'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_07']) {
            this.fields.push({key: 'col18', label: result.kensyuInfo[0]['item_meisyo_07'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_08']) {
            this.fields.push({key: 'col19', label: result.kensyuInfo[0]['item_meisyo_08'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_09']) {
            this.fields.push({key: 'col20', label: result.kensyuInfo[0]['item_meisyo_09'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_10']) {
            this.fields.push({key: 'col21', label: result.kensyuInfo[0]['item_meisyo_10'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_11']) {
            this.fields.push({key: 'col22', label: result.kensyuInfo[0]['item_meisyo_11'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_12']) {
            this.fields.push({key: 'col23', label: result.kensyuInfo[0]['item_meisyo_12'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_13']) {
            this.fields.push({key: 'col24', label: result.kensyuInfo[0]['item_meisyo_13'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_14']) {
            this.fields.push({key: 'col25', label: result.kensyuInfo[0]['item_meisyo_14'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_15']) {
            this.fields.push({key: 'col26', label: result.kensyuInfo[0]['item_meisyo_15'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_16']) {
            this.fields.push({key: 'col27', label: result.kensyuInfo[0]['item_meisyo_16'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_17']) {
            this.fields.push({key: 'col28', label: result.kensyuInfo[0]['item_meisyo_17'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_18']) {
            this.fields.push({key: 'col29', label: result.kensyuInfo[0]['item_meisyo_18'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_19']) {
            this.fields.push({key: 'col30', label: result.kensyuInfo[0]['item_meisyo_19'], sortable:true, class: "text-center text-nowrap",},);
          }
          if(result.kensyuInfo[0]['item_meisyo_20']) {
            this.fields.push({key: 'col31', label: result.kensyuInfo[0]['item_meisyo_20'], sortable:true, class: "text-center text-nowrap",},);
          }
          this.fields.push({key: 'col32', label: "入力クリア", class: "text-center text-nowrap",});

          if(result.kensyuInfo[0]['kaisai_name']){
            this.kensyu_name = result.kensyuInfo[0]['kensyu_name'] + '　' + result.kensyuInfo[0]['kaisai_name'];
          }else {
            this.kensyu_name = result.kensyuInfo[0]['kensyu_name'];
          }
          start_date = moment(result.kensyuInfo[0]['kaisai_date_start']).format('YYYY/MM/DD') + '('+ result.kensyuInfo[0]['start_day'] + ')';
          end_date = moment(result.kensyuInfo[0]['kaisai_date_end']).format('YYYY/MM/DD') + '('+ result.kensyuInfo[0]['end_day'] + ')';
          this.kaisai_date = start_date + ' ~ ' + end_date;

          if(result.kensyuInfo[0]['kaisai_jyuko_su'] == 0) {
            this.jyuko_su = result.kensyuInfo[0]['kensyu_jyuko_su'];
          } else {
            this.jyuko_su = result.kensyuInfo[0]['kaisai_jyuko_su'];
          }
          if(result.kensyuInfo[0]['tosi_type'] == 0) {
            this.tdfk_name = result.kensyuInfo[0]['tdfk_name'];
          } else if(result.kensyuInfo[0]['tosi_type'] == 1) {
            this.tdfk_name = result.kensyuInfo[0]['tdfk_name'] + result.kensyuInfo[0]['tosi_name'];
          } else if(result.kensyuInfo[0]['tosi_type'] == 3) {
            this.tdfk_name = result.kensyuInfo[0]['tdfk_name'] + result.kensyuInfo[0]['daigaku_name'];
          }
          if(result.kensyuInfo[0]['renewal_date']){
            this.last_jyuko_ins_date = moment(result.kensyuInfo[0]['renewal_date']).format('YYYY/MM/DD HH:mm');
          }
          else{
            this.last_jyuko_ins_date = '－';
          }
          if(result.kensyuInfo[0]['kakutei_flg'] == null) {
            this.kakutei_text = '未登録です。';
          } else if(result.kensyuInfo[0]['kakutei_flg'] == 0) {
            this.kakutei_text = '未確定です。確定して下さい。';
          } else {
            this.kakutei_text = '確定済みです。';
          }
          this.kakutei_flg = result.kensyuInfo[0]['kakutei_flg'];
          this.todofuken_code = result.kensyuInfo[0]['tdfk_cd'];
          this.tosi_code = result.kensyuInfo[0]['tosi_cd']

          this.prtParam.nendo = result.kensyuInfo[0]['nendo']
          this.prtParam.kensyuCd = result.kensyuInfo[0]['kensyu_cd'];
          this.prtParam.kaisaiNo = result.kensyuInfo[0]['kaisai_no'];
          this.prtParam.tdfkCd = result.kensyuInfo[0]['tdfk_cd'];
          this.prtParam.tosiCd = result.kensyuInfo[0]['tosi_cd'];
          this.prtParam.daigakuCd = result.kensyuInfo[0]['daigaku_cd'];
          this.prtParam.tosiType = result.kensyuInfo[0]['tosi_type']
          this.getTodofukenName();
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getJyukoInfo() {
        var request_data = {
          login_id: this.login_id,
          kaisai_no: this.kaisai_no,
        }
        apiService.getJyukoData(request_data)
        .then(result => {
          this.max_row = result.maxRow;
          this.uploadFileSize = result.upload_file_size;
          if(result.status == 'success') {
            let i =0;
            let data = '';
            for(i;i < result.jyukoInfo.length;i++) {
              var age = Number(result.jyukoInfo[i]['age']);
              data =
                {
                  col1: result.jyukoInfo[i]['suisen_cd'],
                  col2: result.jyukoInfo[i]['jyuko_name'],
                  col3: result.jyukoInfo[i]['jyuko_kana'],
                  col4: age,
                  col5: result.jyukoInfo[i]['seibetu_cd'],
                  col6: result.jyukoInfo[i]['shozoku_name'],
                  col7: result.jyukoInfo[i]['syoku_cd'],
                  col8: result.jyukoInfo[i]['shozoku_syoku_2'],
                  col9: result.jyukoInfo[i]['shozoku_post_no'],
                  col10: result.jyukoInfo[i]['shozoku_address'],
                  col11: result.jyukoInfo[i]['shozoku_kikan_type_cd'],
                  col12: result.jyukoInfo[i]['item_01'],
                  col13: result.jyukoInfo[i]['item_02'],
                  col14: result.jyukoInfo[i]['item_03'],
                  col15: result.jyukoInfo[i]['item_04'],
                  col16: result.jyukoInfo[i]['item_05'],
                  col17: result.jyukoInfo[i]['item_06'],
                  col18: result.jyukoInfo[i]['item_07'],
                  col19: result.jyukoInfo[i]['item_08'],
                  col20: result.jyukoInfo[i]['item_09'],
                  col21: result.jyukoInfo[i]['item_10'],
                  col22: result.jyukoInfo[i]['item_11'],
                  col23: result.jyukoInfo[i]['item_12'],
                  col24: result.jyukoInfo[i]['item_13'],
                  col25: result.jyukoInfo[i]['item_14'],
                  col26: result.jyukoInfo[i]['item_15'],
                  col27: result.jyukoInfo[i]['item_16'],
                  col28: result.jyukoInfo[i]['item_17'],
                  col29: result.jyukoInfo[i]['item_18'],
                  col30: result.jyukoInfo[i]['item_19'],
                  col31: result.jyukoInfo[i]['item_20'],
                  col32: '',
                };

              this.items.push(data);
              this.nendo.push(result.jyukoInfo[i]['nendo']);
              this.kensyu_cd.push(result.jyukoInfo[i]['kensyu_cd']);
              this.tdfk_cd.push(result.jyukoInfo[i]['tdfk_cd']);
              this.tosi_cd.push(result.jyukoInfo[i]['tosi_cd']);
              this.daigaku_cd.push(result.jyukoInfo[i]['daigaku_cd']);
              this.meisai_no.push(result.jyukoInfo[i]['meisai_no']);
            }
          } else {

            let i = 0;
            let data ='';
            for(i; i < 10;i++) {
              data = { col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '', col13: '', col14: '', col15: '', col16: '', col17: '', col18: '', col19: '', col20: '', col21: '', col22: '', col23: '', col24: '', col25: '', col26: '', col27: '', col28: '', col29: '', col30: '', col31: '', col32: '' };

              this.items.push(data);
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getTodofukenName() {
        var request_data = {
          tosi_type: this.prtParam.tosiType,
          tdfk_cd: this.prtParam.tdfkCd,
          tosi_cd: this.prtParam.tosiCd,
          daigaku_cd: this.prtParam.daigakuCd,
        }
        apiService.getTodofukenName(request_data)
        .then(result => {
          this.todofunken_name = result.todofuken;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getSeibetsu() {
        apiService.getSeibetsu()
        .then(result => {
          this.seibetsu = result.seibetsu;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getShokuName() {
        apiService.getShokuName()
        .then(result => {
          this.shokuName = result.shokuName;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getGakkoShu() {
        apiService.getGakkoShu()
        .then(result => {
          this.gakko = result.gakko;
          this.setOldRequestData();
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      uploadFile: function () {
          let obj = document.getElementById('selectFile');
          obj.value='';

          this.$refs.uploadBtn.click();
      },
      onFileChange(e) {
        if(e.target.files.length < 1){
          return;
        }
        this.isLoading = true;
        this.import_file = e.target.files[0];

        var  extension_list = ['xlsx'];
        var ext = this.import_file.name.split('.').pop();
        var filesize = this.import_file.size;
        if(extension_list.indexOf(ext) < 0){
          this.errorStatus = true;
          this.error_message_no = "E1060";
          this.error_message = this.$messages.MSG["E1060"];
          this.isLoading = false;
          return;
        }else if(filesize > this.uploadFileSize){
          this.errorStatus = true;
          this.error_message_no = "E1050";
          this.error_message = this.$messages.MSG["E1050"];
          this.isLoading = false;
          return;
        }else {
          this.errorStatus = false;
          this.error_message_no = "";
          this.error_message = "";
        }

        let formData = new FormData();
        formData.append("import_file", this.import_file);
        formData.append("loginID", this.login_id);
        formData.append("fields", JSON.stringify(this.fields));

        this.errorStatus = false;
        this.successStatus = false;
        apiService.fileUpload(formData)
        .then(result => {
          if(result.status == 'success'){
            this.uploadFiledata = result.file_data;
            this.$root.$emit('bv::show::modal', 'modal-pzregmsg')
          }
          else{
            this.errorStatus = true
            this.successStatus = false
            this.errors = [];
            this.error_message = '';
            this.error_message_no = '';
            if(result.errors) {
              this.errors = result.errors;
            } else {
              this.error_message = result.message.replaceAll("|","\n");
              this.error_message_no = result.message_no;
            }
            this.isLoading = false;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        })
        .catch(error => {
          this.errorStatus = true
          this.successStatus = false
          this.errors = [];
          this.error_message ='';
          this.error_message_no ='';
          if(error.errors) {
            this.errors = error.errors;
          } else {
            this.error_message = error.message.replaceAll("|","\n");
            this.error_message_no = error.message_no;
          }
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
      },
      dataChangeOK() {
        let vm = this;
        this.items = [];
        var File_data = {};
        this.uploadFiledata.forEach(function(data){
          let suisen = vm.todofunken_name.filter(e=>e.suisen_no == data.suisen_cd);
          let suisen_cd = suisen.length > 0 ? suisen[0].suisen_no : "";
          let syoku = vm.shokuName.filter(e=>e.cd_1 == data.syoku_cd);
          let syoku_cd = syoku.length > 0 ? syoku[0].cd_1 : "";
          let kikan = vm.gakko.filter(e=>e.cd_1 == data.shozoku_kikan_type_cd);
          let kikan_cd = kikan.length > 0 ? kikan[0].cd_1 : "";
          let seibetu = vm.seibetsu.filter(e=>e.cd_1 == data.seibetu_cd);
          let seibetu_cd = seibetu.length > 0 ? seibetu[0].cd_1 : "";

          File_data = {
            tdfk_cd: data.tdfk_cd,
            tosi_cd: data.tosi_cd,
            daigaku_cd: data.daigaku_cd,
            tosi_type: data.tosi_type,
            col1: suisen_cd,
            col2: data.jyuko_name,
            col3: data.jyuko_kana,
            col4: data.age_val,
            col5: seibetu_cd,
            col6: data.shozoku_name,
            col7: syoku_cd,
            col8: data.shozoku_syoku_2,
            col9: data.shozoku_post_no,
            col10: data.shozoku_address,
            col11: kikan_cd,
            col12: data.item_1,
            col13: data.item_2,
            col14: data.item_3,
            col15: data.item_4,
            col16: data.item_5,
            col17: data.item_6,
            col18: data.item_7,
            col19: data.item_8,
            col20: data.item_9,
            col21: data.item_10,
            col22: data.item_11,
            col23: data.item_12,
            col24: data.item_13,
            col25: data.item_14,
            col26: data.item_15,
            col27: data.item_16,
            col28: data.item_17,
            col29: data.item_18,
            col30: data.item_19,
            col31: data.item_20,
            col32: ''
          };
          vm.items.push(File_data);
        });
        this.successStatus = true;
        this.successMessage = this.$messages.MSG['I3012'].replaceAll("|","\n");
        this.success_message_no = "I3012";
        this.$bvModal.hide('modal-pzregmsg');
        this.isLoading = false;
      },
      downloadFormatFile() {
        this.isLoading = true;
        var request_data =
        {
          nendo: this.prtParam.nendo? this.prtParam.nendo : "",
          kensyuCd: this.prtParam.kensyuCd? this.prtParam.kensyuCd : "",
          kaisaiNo: this.prtParam.kaisaiNo? this.prtParam.kaisaiNo : "",
          tdfkCd: this.prtParam.tdfkCd? this.prtParam.tdfkCd : "",
          tosiCd: this.prtParam.tosiCd? this.prtParam.tosiCd : "",
          daigakuCd: this.prtParam.daigakuCd? this.prtParam.daigakuCd : "",
          tosiType: this.prtParam.tosiType? this.prtParam.tosiType : ""
        };
        apiService.PPZyukosyaRegist001exportExcel(request_data)
          .then(result => {
            var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
            this.download(result,'研修受講者推薦名簿_' +  date + '.xlsx');
          }).catch(error => {
            console.log(error);
            this.isLoading = false;
          });
      },
      download(result, fileName) {
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(new Blob([result]), fileName);
          this.isLoading = false;
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        }
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
              if(this.kakutei_flg == null) {
                this.kakutei_text = '未登録です。';
              } else 
              if(this.kakutei_flg == '0') {
                this.kakutei_text = '未確定です。確定して下さい。';
              } else {
                this.kakutei_text = '確定済みです。';
              }
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      setSyokuCode2(rowData) {
        this.shokuName.forEach(function(syokuName){
          if (syokuName.cd_1 == rowData.item.col7) {
            rowData.item.col8 = syokuName.name_1;
          }
        });
        if(rowData.item.col7 == '99'){
          rowData.item.col8 = "";
        }
      },
      setOldRequestData() {
          this.oldRequestData
            = JSON.parse(JSON.stringify({
              items: this.items
            }))
      },
      checkInputChange() {
        var org = JSON.stringify(this.oldRequestData, jsonReplace);
        if (org === '{}') {
          return true;
        }
        var dest = JSON.stringify({
          items: this.items
        }, jsonReplace);
        return org === dest;
      },
      checkOrder() {
        setTimeout(() => {
          switch(this.sortBy){
            case "col4":
              if(this.sortDesc){
                this.items.sort((a, b) => {
                  let fa = a.col4, fb = b.col4;
                  return fa < fb ? 1 : fa > fb ? -1 : 0
                })
              }else {
                this.items.sort((a, b) => {
                  let fa = a.col4, fb = b.col4;
                  return fa < fb ? -1 : fa > fb ? 1 : 0
                })
              }
              break;

            default:
              if(this.sortDesc){
                this.items.sort((a, b) => {
                  let fa = a[this.sortBy], fb = b[this.sortBy];
                  let checkval = fa.localeCompare(fb, 'jp', {numeric: 'base'});
                  return checkval == -1 ? 1 : checkval == 1 ? -1 : 0
                })
              }else {
                this.items.sort((a, b) => {
                  let fa = a[this.sortBy], fb = b[this.sortBy];
                  let checkval = fa.localeCompare(fb, 'jp', {numeric: 'base'});
                  return checkval == -1 ? -1 : checkval == 1 ? 1 : 0
                })
              }
              break;
          }
        }, 100);
      },
    },
    beforeRouteLeave(to, from, next) {
      var input_check = this.checkInputChange();
      var answer = true;
      if (!input_check) {
        answer = window.confirm(this.$messages.MSG['I3005']);
      }
      if (answer) {
        next();
        this.$store.commit("PPortal/clear");
      } else {
        next(false);
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      isDark () {
        return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
      },
    },
  };
  function jsonReplace(key, val) {
      if(typeof val == "string" && val == ""){
        return null;
      }
      return val;
  }
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3 overflow-hidden">
      <div id="contentTop"></div>
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
        <div
          class="col-md-12"
          v-for="(value, index) in errors"
          :key="index"
          style="white-space: pre !important"
        >
          <!-- {{ value.slice("")[0].split("[").pop().split("]")[0].replace("|","\n")}} -->
          {{ value.slice("")[0].split("[").pop().split("]")[0].split("/")[1] }}
          {{
            value
              .slice("")[0]
              .split("[")
              .pop()
              .split("]")[0]
              .split("/")[0]
              .replace("|", "\n")
          }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">研修受講者推薦</h4>

        <div id="mainTable">
          <div class="row">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ kensyu_name }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">開催日</div>
            <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ kaisai_date }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">受講定員</div>
            <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ jyuko_su }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">推薦機関名</div>
            <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ tdfk_name }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">最終登録日時</div>
            <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ last_jyuko_ins_date }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">登録状況</div>
            <div  :style="kakutei_flg== '1'? { 'color':'red' , 'font-weight':'bold' } :{ }" class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ kakutei_text }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <input type="file" id="selectFile" class="d-none" ref="uploadBtn" @change="onFileChange">
          <button class="btn btn-outline-secondary shadow mr-3" @click="uploadFile">データのアップロード</button>
          <span class="d-flex align-items-center font-weight-bold" style="font-size:15px;">フォーマットのダウンロードはこちら。</span>
          <b-link class="d-flex align-items-center font-weight-bold ml-2" href="#" style="text-decoration: underline;font-size:15px;" @click="downloadFormatFile">フォーマットダウンロード</b-link>
        </div>
        <div class="row mt-1">
          <span class="font-weight-bold" style="font-size:15px;">
            「行追加ボタン」「仮登録ボタン」を押さずに９０分以上続けてデータを作成すると、サーバーとの接続が切れてしまい作成中のデータが無効になります。
            <br>続けて作成する場合には、「仮登録ボタン」でデータを保存してください。
          </span>
        </div>
        <div class="row mt-4">
          <button class="btn btn-outline-secondary shadow mr-3" style="width: 100px" @click="addRow()">
            行追加
          </button>
        </div>
        <div class="row mt-1">
          <span class="font-weight-bold" style="font-size:15px;">
            入力項目は全て必須です。
            <br>※「氏名」に外字が含まれている場合は教職員支援機構担当者までご連絡ください。
          </span>
        </div>
        <div class="row">
          <div class="col px-0">
            <b-table
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="items"
              :fields="fields"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
              :no-local-sorting="true"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @head-clicked="checkOrder()"
            >
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #thead-top >
                <b-tr class="first-tr" >
                  <b-th v-for="(val,i) in fields.slice(5)" v-bind:key="i" :colspan="i==5? 6:1" :class="i==5?'syozoku-col text-center':''">{{i==5?'所属':''}}</b-th>
                </b-tr>
              </template>
              <template #cell(col1)="col1Data">
                <select class="form-control custom-select input-w200px bg-required" v-model="col1Data.item.col1">
                  <option></option>
                  <option v-for="(todofuken, index) in todofunken_name"
                  v-bind:key="index"
                  :value="todofuken.suisen_no"
                  :selected="todofuken.suisen_no == cyukaku_cd"
                  v-show="todofuken.suisen_name != null">{{todofuken.suisen_name}}</option>
                </select>
              </template>
              <template #cell(col2)="col2Data">
                <input type="text" class="form-control input-w200px bg-required" v-model="col2Data.item.col2"
                  @change="items[col2Data.index].col2=col2Data.value" />
              </template>
              <template #cell(col3)="col3Data">
                <input type="text" class="form-control input-w200px bg-required" v-model="col3Data.item.col3"
                  @change="items[col3Data.index].col3=col3Data.value" />
              </template>
              <template #cell(col4)="col4Data">
                <select class="form-control custom-select bg-required" style="width:70px;" v-model="col4Data.item.col4">
                  <option v-for="n in 80" :key="n" :value="n" v-show="n >= 20">{{n}}</option>
                </select>
              </template>
              <template #cell(col5)="col5Data">
                <select class="form-control custom-select bg-required" style="width:70px;" v-model="col5Data.item.col5">
                  <option></option>
                  <option v-for="(seibetsu_name, index) in seibetsu"
                  v-bind:key="index"
                  :value="seibetsu_name.cd_1"
                  :selected="seibetsu_name.cd_1 == seibetsu_cd"
                  v-show="seibetsu_name.rname != null">{{seibetsu_name.rname}}</option>
                </select>
              </template>
              <template #cell(col6)="col6Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col6Data.item.col6"
                  @change="items[col6Data.index].col6=col6Data.value" />
              </template>
              <template #cell(col7)="col7Data">
                <select class="form-control custom-select bg-required" style="width:200px;" v-model="col7Data.item.col7" @change="setSyokuCode2(col7Data)">
                  <option></option>
                  <option v-for="(shoku_name, index) in shokuName"
                  v-bind:key="index"
                  :value="shoku_name.cd_1"
                  :selected="shoku_name.cd_1 == shoku_cd"
                  v-show="shoku_name.name_1 != null">{{shoku_name.name_1}}</option>
                </select>
              </template>
              <template #cell(col8)="col8Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col8Data.item.col8"
                  @change="items[col8Data.index].col8=col8Data.value" />
              </template>
              <template #cell(col9)="col9Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col9Data.item.col9"
                  @change="items[col9Data.index].col9=col9Data.value" />
              </template>
              <template #cell(col10)="col10Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col10Data.item.col10"
                  @change="items[col10Data.index].col10=col10Data.value" />
              </template>
              <template #cell(col11)="col11Data">
                {{shozoku_kikan_type_cd}}
                <select class="form-control custom-select input-w200px bg-required" v-model="col11Data.item.col11">
                  <option></option>
                  <option v-for="(gakkoshu, index) in gakko"
                  v-bind:key="index"
                  :value="gakkoshu.cd_1"
                  :selected="gakkoshu.cd_1 == shozoku_kikan_type_cd"
                  v-show="gakkoshu.name_1 != null">{{gakkoshu.name_1}}</option>
                </select>
              </template>
              <template #cell(col12)="col12Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col12Data.item.col12"
                  @change="items[col12Data.index].col12=col12Data.value" />
              </template>
              <template #cell(col13)="col13Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col13Data.item.col13"
                  @change="items[col13Data.index].col13=col13Data.value" />
              </template>
              <template #cell(col14)="col14Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col14Data.item.col14"
                  @change="items[col14Data.index].col14=col14Data.value" />
              </template>
              <template #cell(col15)="col15Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col15Data.item.col15"
                  @change="items[col15Data.index].col15=col15Data.value" />
              </template>
              <template #cell(col16)="col16Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col16Data.item.col16"
                  @change="items[col16Data.index].col16=col16Data.value" />
              </template>
              <template #cell(col17)="col17Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col17Data.item.col17"
                  @change="items[col17Data.index].col17=col17Data.value" />
              </template>
              <template #cell(col18)="col18Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col18Data.item.col18"
                  @change="items[col18Data.index].col18=col18Data.value" />
              </template>
              <template #cell(col19)="col19Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col19Data.item.col19"
                  @change="items[col19Data.index].col19=col19Data.value" />
              </template>
              <template #cell(col20)="col20Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col20Data.item.col20"
                  @change="items[col20Data.index].col20=col20Data.value" />
              </template>
              <template #cell(col21)="col21Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col21Data.item.col21"
                  @change="items[col21Data.index].col21=col21Data.value" />
              </template>
              <template #cell(col22)="col22Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col22Data.item.col22"
                  @change="items[col22Data.index].col22=col22Data.value" />
              </template>
              <template #cell(col23)="col23Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col23Data.item.col23"
                  @change="items[col23Data.index].col23=col23Data.value" />
              </template>
              <template #cell(col24)="col24Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col24Data.item.col24"
                  @change="items[col24Data.index].col24=col24Data.value" />
              </template>
              <template #cell(col25)="col25Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col25Data.item.col25"
                  @change="items[col25Data.index].col25=col25Data.value" />
              </template>
              <template #cell(col26)="col26Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col26Data.item.col26"
                  @change="items[col26Data.index].col26=col26Data.value" />
              </template>
              <template #cell(col27)="col27Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col27Data.item.col27"
                  @change="items[col27Data.index].col27=col27Data.value" />
              </template>
              <template #cell(col28)="col28Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col28Data.item.col28"
                  @change="items[col28Data.index].col28=col28Data.value" />
              </template>
              <template #cell(col29)="col29Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col29Data.item.col29"
                  @change="items[col29Data.index].col29=col29Data.value" />
              </template>
              <template #cell(col30)="col30Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col30Data.item.col30"
                  @change="items[col30Data.index].col30=col30Data.value" />
              </template>
              <template #cell(col31)="col31Data">
                <input type="text" class="form-control input-w200px bg-required" style="width:50px;" v-model="col31Data.item.col31"
                  @change="items[col31Data.index].col31=col31Data.value" />
              </template>
              <template #cell(col32)="row">
                <button class="btn btn-secondary d-inline" style="width: 100px" @click="removeRow(row)">クリア</button>
              </template>
            </b-table>
          </div>
        </div>
        <div class="w-100 text-center text-md-right mb-2">
          <div>
            <router-link class="btn btn-outline-secondary shadow d-inline-block" :to="{ name: 'PZyukosyaRegistDL', params: {kaisai_no : kaisai_no} }">
              推薦済研修受講者一覧CSV出力
            </router-link>
          </div>
          <button class="btn btn-primary shadow d-inline-block mt-2 ml-2" @click="tempRegist" style="width:100px" :disabled="kakutei_flg == '1'">
            仮登録
          </button>
          <button class="btn btn-primary shadow d-inline-block mt-2 ml-2" @click="confirm" style="width:100px" :disabled="kakutei_flg == '1'">
            確定
          </button>
          <button class="btn btn-secondary shadow d-inline-block mt-2 ml-2" @click="Back" style="width:100px">
            戻る
          </button>
        </div>
      </div>
      <b-modal id="modal-pzregmsg" size="lg" title="受講者名簿アップロード成功">
        <PZRegMsg ref="PZRegMsg"/>
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="white" class="btn-outline-secondary float-right shadow" style="width:100px;"
              @click="$bvModal.hide('modal-pzregmsg'); isLoading = false;">
              閉じる
            </b-button>
            <b-button variant="white" class="btn-outline-primary float-right mr-2 shadow" style="width:100px;" 
              @click="dataChangeOK">
              OK
            </b-button>

          </div>
        </template>
      </b-modal>

    </main>
  </AppLayout>
</template>
<style scoped>
  .table .first-tr th {
    border-bottom-color: #343a40 !important;
    border-bottom-width: 1px !important;
  }

  .table .first-tr th.syozoku-col {
    border-bottom-color: #a1a1a1 !important;
    border-bottom-width: 1px !important;
  }

  .input-w100px,
  .input-w200px,
  .input-w300px,
  .input-w400px {
    width: 100% !important;
  }

  .table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div {
    width: 100% !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 767.98px) {
    .input-w100px {
      width: 100px !important;
    }

    .input-w200px {
      width: 200px !important;
    }

    .input-w300px {
      width: 300px !important;
    }

    .input-w400px {
      width: 400px !important;
    }
  }
</style>
