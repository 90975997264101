<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  import moment from "moment";
  const apiService = new APIService();

  export default {
    name: "SPortal",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        kensyu_cd: '',
        kaisai_no: '',
        jyuko_no: '',
        jyuko_kana: '',
        jyuko_name: '',
        suisen_kikan_name: '',
        kensyu_kikan: '',
        kensyu_name: '',
        kaisai_name: '',
        shozoku_name: '',
        seibetsu: '',
        viewKensyuName: '',
        viewKensyuCd: '',
        portalNotice: [],
        portalInfo: [],
        portalCertInfo: [],
        portal_message: '',
        portal_no: '',
        portal_flg: false,
        errorStatus:false,
        errorMessage:'',
        errorMessageNo:'',
      };
    },
    mounted() {
      this.getJyukoInfo();
      this.getPortalInfo();
      this.getPortalInfoDownload();
      this.getPortalInfoSyuryosyo();
    },
    methods: {
      getJyukoInfo() {
        this.isLoading = true;
        apiService.getJyukoInfo()
        .then(result => {
          this.jyuko_no = result.jyukoData[0].jyuko_no;
          this.jyuko_kana = result.jyukoData[0].jyuko_kana;
          this.jyuko_name = result.jyukoData[0].jyuko_name;
          this.suisen_kikan_name = result.jyukoData[0].suisen_kikan_name;
          this.kensyu_kikan = result.jyukoData[0].kensyu_kikan;
          this.kensyu_cd = result.jyukoData[0].kensyu_cd;
          this.kensyu_name = result.jyukoData[0].kensyu_name;
          this.kaisai_no = result.jyukoData[0].kaisai_no;
          this.kaisai_name = result.jyukoData[0].kaisai_name != null ? result.jyukoData[0].kaisai_name : '';
          this.shozoku_name = result.jyukoData[0].shozoku_name;
          this.seibetsu = result.jyukoData[0].seibetsu;
          this.viewKensyuName = this.kensyu_name + '　' + this.kaisai_name;
          this.viewKensyuCd = this.kensyu_cd +this.kaisai_no;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        });
      },
      getPortalInfo() {
        apiService.getSPortalInfo()
        .then(result => {
          var vm = this;
          if(result.status == 'success') {
            result.protalInfo.forEach(function(data) {
              var year = data.keisai_date_start.slice(0,4);
              var month = data.keisai_date_start.slice(4,6);
              var day = data.keisai_date_start.slice(6,8);
              var keisai_date_start = year + '年' + month + '月' + day+ '日 (' +  data.name_1 + ')';
              var portal_list = {
                keisai_date_start: keisai_date_start,
                message: data.message,
                link_name: data.link_name,
                file_name: data.file_name
              };
              vm.portalNotice.push(portal_list);
            });
            this.isLoading = false;
          } else {
            this.portal_flg = true;
            this.portal_message = result.message.replaceAll("|","\n");
            this.portal_no = result.message_no;
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
        })
      },
      getPortalInfoDownload() {
        this.isLoading = true;
        apiService
          .portalTyouhyouInfoDownload()
          .then((result) => {
            this.portalInfo = result;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },
      getPortalInfoSyuryosyo() {
        this.isLoading = true;
        apiService
          .portalSyuryosyoInfoDownload()
          .then((result) => {
            this.portalCertInfo = result;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },
      downloadPortal(file_name) {
        if(!file_name){
          return;
        }
        this.isLoading = true;
        let name = file_name.split("/");
        let index = name.length-1;
        let fileName = name[index];
        var request_data = {
          file_name: file_name
        }
        apiService.downloadPortal(request_data).then(result => {
          if (result.type == 'application/json') {
            this.isLoading = false;
            return;
          }
          this.download(result, fileName);
        }).catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      },
      download(result, fileName) {
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(new Blob([result]), fileName);
          this.isLoading = false;
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;
        }
      },
      output($portalinfoItem)
      {
        this.isLoading = true;
        let request_data =
        {
          selectedValue: $portalinfoItem.number,
          login_id: 'ncnu'
        }
        apiService.SPortalOutputProcess(request_data)
          .then(result => {
            console.log(result);
            if(result.status == "success" && $portalinfoItem.number == '1')
            {
              this.exportPTRE010001(request_data);
            } else if(result.status == "success" && $portalinfoItem.number == '2')
            {
              this.exportPTRE010002(request_data);
            } else if(result.status == "success" && $portalinfoItem.number == '3')
            {
              this.exportPTRE010003_ONE(request_data);
            } else if(result.status == "success" && $portalinfoItem.number == '4')
            {
              this.exportPTRE010004(request_data);
            } else if(result.status == "success" && $portalinfoItem.number == '5')
            {
              this.exportPTRE010007(request_data);
            } else if(result.status == "error")
            {
              this.errorStatus = true;
              this.errorMessage = result.message.replaceAll("|","\n");
              this.errorMessageNo = result.message_no;
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            } else {
              this.isLoading = false;
            }
        })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
        });
      },

      // P-TRE010-001 駐車券
      exportPTRE010001(request_data) {
        let vm = this;
        apiService.PTRE010001exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.downloadExcel(result,'駐車券_' +  date + '.xlsx');
          this.successStatus = true;
          this.infoMessage = this.$messages.MSG["I0007"];
          this.infoMessageNo = "I0007";
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      },
      // P-TRE010-002 宿泊証明書
      exportPTRE010002(request_data) {
        let vm = this;
        apiService.PTRE010002exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.downloadExcel(result,'宿泊証明書_' +  date + '.xlsx');
          this.successStatus = true;
          this.infoMessage = this.$messages.MSG["I0007"];
          this.infoMessageNo = "I0007";
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      },
      // P-TRE010-003 宿泊費領収書（ログインID指定＝１件）
      exportPTRE010003_ONE(request_data) {
        let vm = this;
        apiService.PTRE010003exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.downloadExcel(result,'宿泊費領収書_' +  date + '.xlsx');
          this.successStatus = true;
          this.infoMessage = this.$messages.MSG["I0007"];
          this.infoMessageNo = "I0007";
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      },
      // P-TRE010-004 駐車場利用費領収書
      exportPTRE010004(request_data) {
        let vm = this;
        apiService.PTRE010004exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.downloadExcel(result,'駐車場・バス利用領収書_' +  date + '.xlsx');
          this.successStatus = true;
          this.infoMessage = this.$messages.MSG["I0007"];
          this.infoMessageNo = "I0007";
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      },
      // P-TRE010-007 研修経費領収書
      exportPTRE010007(request_data) {
        let vm = this;
        apiService.PTRE010007exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.downloadExcel(result,'研修経費領収書_' +  date + '.xlsx');
          this.successStatus = true;
          this.infoMessage = this.$messages.MSG["I0007"];
          this.infoMessageNo = "I0007";
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      },
      // TSE030-001 修了証書
      exportPTSE030A001(request_data) {
        let vm = this;
        apiService.PTSE030A001exportExcel(request_data)
          .then((result) => {
            var date = moment(String(new Date())).format(
              "YYYYMMDDHHmmss"
            );
            vm.downloadExcel(result, "修了証書_" + date + ".xlsx");
          })
          .catch(error => {
            this.blogErrorMessages(error);
        });
      },

      blogErrorMessages(error){
        this.isLoading = false;
        console.log(error)
        let errormessage;
        if (
          error.request.responseType === 'blob' &&
          error.data instanceof Blob &&
          error.data.type &&
          error.data.type.toLowerCase().indexOf('json') != -1
        ) {
            errormessage = new Promise((resolve, reject) => {
              let reader = new FileReader();
              reader.onload = () => {
                error.data = JSON.parse(reader.result);
                resolve(Promise.reject(error));
              };
              reader.onerror = () => {
                reject(error);
              };
              reader.readAsText(error.data);
            })
            .then(err => {
              console.log(err.data)
            });
        }
        errormessage.then(success=>{
          console.log(success)
        }).catch(error=>{
          if(error.data.status=='error'){
              this.errorStatus = true;
              this.errors = "";
              this.errorMessage = error.data.message;
              this.errorMessageNo = error.data.message_no;
          }
          if(error.data.errors) {
            this.errorStatus = true;
            this.errorMessage = "";
            var require_error = error.data.errors;
            this.errors = require_error;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
      },

      downloadExcel(result, fileName) {
        this.isLoading = false;
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(new Blob([result]), fileName);
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      },
    },
  };
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="contents-height">
      <div id="contentTop"></div>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important;" v-if="errorMessage">
          {{ errorMessageNo }} {{ errorMessage }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <div id="mainTable" class="my-3">
          <div class="row">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">受講者番号</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ jyuko_no }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">性別</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ seibetsu }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">カナ氏名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ jyuko_kana }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">研修コード</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ viewKensyuCd }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">氏名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ jyuko_name }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">研修名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ viewKensyuName }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">推薦機関名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ suisen_kikan_name }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">所属</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ shozoku_name }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">研修期間</div>
            <div class="subCell col-md-10 col-12 bg-light text-center text-md-left table_height">{{ kensyu_kikan }}</div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="text-left font-weight-bold">お知らせ</div>
            <div class="text-left ml-2" v-for="(notice, index) in portalNotice" v-bind:key="index">
              <span>{{notice.keisai_date_start}}掲載・・・・・{{notice.message}}</span>
              <b-link class="ml-2" href="#" style="text-decoration: underline;" v-if="notice.link_name" @click="downloadPortal(notice.file_name)">{{notice.link_name}}</b-link>
            </div>
            <div v-if="portal_flg">
              <span>{{ portal_message }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-5" >
          <div class="col-12">
            <div class="text-left font-weight-bold">ダウンロード</div>
            <div class="text-left ml-2" v-if="portalInfo.status == 'success'">
              <div class="text-left ml-2" v-for="(portalInfoItem, index) in portalInfo.portal_info" v-bind:key="index">
                <span>{{ portalInfoItem.keisai_date_start }}掲載・・・・・</span>
                <b-link class="ml-2" v-if="portalInfoItem.number == '1'" href="#" style="text-decoration: underline;" @click="output(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
                <b-link class="ml-2" v-if="portalInfoItem.number == '2'" href="#" style="text-decoration: underline;" @click="output(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
                <b-link class="ml-2" v-if="portalInfoItem.number == '3'" href="#" style="text-decoration: underline;" @click="output(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
                <b-link class="ml-2" v-if="portalInfoItem.number == '4'" href="#" style="text-decoration: underline;" @click="output(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
                <b-link class="ml-2" v-if="portalInfoItem.number == '5'" href="#" style="text-decoration: underline;" @click="output(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
              </div>
            </div>
            <div class="text-left ml-2" v-if="portalCertInfo.status == 'success'">
              <div class="text-left ml-2" >
                <span>{{ portalCertInfo.cert_info.syuryosyo_hyoji_date }}掲載・・・・・</span>
                <b-link class="ml-2"  href="#" style="text-decoration: underline;" @click="exportPTSE030A001()">{{portalCertInfo.cert_info.link_name}}</b-link>
              </div>
            </div>
            <span v-if="portalInfo.status == 'error' && portalCertInfo.status == 'error'">{{
              portalInfo.message
            }}</span>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
</style>
