<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import moment from 'moment';
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  moment.locale("ja");
  export default {
    name: "SSTAYCHECK",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        screenData: {
          meisyo: {},
          jyukoUser: {},
          dtSyukuhaku: {
            dtSt: null,
            dtEd: null,
          },
          ryokin: {
            syukuhaku: 0,
            parking: 0,
            bus: 0,
            syukuhakuDays: 0,
            parkingDays: 0
          }
        },
        content_body: {
          syku_kikan: "",
          zenhaku_umu: "",
          syku_type: "",
          bus_riyo: "",
          parking_riyo: "",
          parking_kikan: "",
          car_no: "",
          kinmusaki_tel: "",
          kinmusaki_fax: "",
          kinmusaki_tel_biko: "",
          syku_ryokin: "",
          parking_ryokin: "",
          bus_ryokin: "",
          total_ryokin: "",
        },
        errors: [],
        error_message: '',
        error_message_no: '',
        errorStatus: false,
        success_message: '',
        success_message_no: '',
        successStatus: false,
        systemInitialDate: '',
        initial_zenhaku_umu: '',
        zenhaku_umu: '',
        kaisai_date_start: '',
        kaisai_date_end: '',
        syku_type: '',
        syku_riyo_date_start: '',
        syku_riyo_date_end: '',
        bus_riyo: '',
        parking_riyo: '',
        parking_riyo_date_start: '',
        parking_riyo_date_end: '',
        car_no_1: '',
        car_no_2: '',
        car_no_3: '',
        car_no_4: '',
        kinmusaki_tel: '',
        kinmusaki_tel_biko: '',
        kinmusaki_fax: '',
        regist_mode: '',
        renewalDate: '',
        bus_ryokin: '',
        syku_ryokin: '',
        parking_riyo_nisuu_ikatu: '',
        parking_ryokin_day: '',
        parking_ryokin_ikatu: '',
        registFlag: false,
        backFlag: false,
        oldRequestData: {}
      };
    },
    mounted() {
      moment.locale("ja");
      if(this.$route.params.kaisai_date_start){
        this.initial_zenhaku_umu = this.$route.params.initial_zenhaku_umu;
        this.zenhaku_umu = this.$route.params.zenhaku_umu;
        this.kaisai_date_start = this.$route.params.kaisai_date_start;
        this.kaisai_date_end = this.$route.params.kaisai_date_end;
        this.syku_type = this.$route.params.syku_type;
        this.syku_riyo_date_start = this.$route.params.syku_riyo_date_start;
        this.syku_riyo_date_end = this.$route.params.syku_riyo_date_end;
        this.bus_riyo = this.$route.params.bus_riyo;
        this.parking_riyo = this.$route.params.parking_riyo;
        this.parking_riyo_date_start = this.$route.params.parking_riyo_date_start;
        this.parking_riyo_date_end = this.$route.params.parking_riyo_date_end;
        this.car_no_1 = this.$route.params.car_no_1;
        this.car_no_2 = this.$route.params.car_no_2;
        this.car_no_3 = this.$route.params.car_no_3;
        this.car_no_4 = this.$route.params.car_no_4;
        this.kinmusaki_tel = this.$route.params.kinmusaki_tel;
        this.kinmusaki_tel_biko = this.$route.params.kinmusaki_tel_biko;
        this.kinmusaki_fax = this.$route.params.kinmusaki_fax;
        this.regist_mode = this.$route.params.regist_mode;
        this.renewalDate = this.$route.params.renewalDate;
        this.bus_ryokin = this.$route.params.bus_ryokin;
        this.syku_ryokin = this.$route.params.syku_ryokin;
        this.parking_ryokin_day = this.$route.params.parking_ryokin_day;

        this.$store.commit("SStayCheck/setZenhaku_umu", this.$route.params.initial_zenhaku_umu);
        this.$store.commit("SStayCheck/setZenhaku_umu", this.$route.params.zenhaku_umu);
        this.$store.commit("SStayCheck/setKaisai_date_start", this.$route.params.kaisai_date_start);
        this.$store.commit("SStayCheck/setKaisai_date_end", this.$route.params.kaisai_date_end);
        this.$store.commit("SStayCheck/setSyku_type", this.$route.params.syku_type);
        this.$store.commit("SStayCheck/setSyku_riyo_date_start", this.$route.params.syku_riyo_date_start);
        this.$store.commit("SStayCheck/setSyku_riyo_date_end", this.$route.params.syku_riyo_date_end);
        this.$store.commit("SStayCheck/setBus_riyo", this.$route.params.bus_riyo);
        this.$store.commit("SStayCheck/setParking_riyo", this.$route.params.parking_riyo);
        this.$store.commit("SStayCheck/setParking_riyo_date_start", this.$route.params.parking_riyo_date_start);
        this.$store.commit("SStayCheck/setParking_riyo_date_end", this.$route.params.parking_riyo_date_end);
        this.$store.commit("SStayCheck/setCar_no_1", this.$route.params.car_no_1);
        this.$store.commit("SStayCheck/setCar_no_2", this.$route.params.car_no_2);
        this.$store.commit("SStayCheck/setCar_no_3", this.$route.params.car_no_3);
        this.$store.commit("SStayCheck/setCar_no_4", this.$route.params.car_no_4);
        this.$store.commit("SStayCheck/setKinmusaki_tel", this.$route.params.kinmusaki_tel);
        this.$store.commit("SStayCheck/setKinmusaki_tel_biko", this.$route.params.kinmusaki_tel_biko);
        this.$store.commit("SStayCheck/setKinmusaki_fax", this.$route.params.kinmusaki_fax);
        this.$store.commit("SStayCheck/setRegist_mode", this.$route.params.regist_mode);
        this.$store.commit("SStayCheck/setRenewalDate", this.$route.params.renewalDate);
        this.$store.commit("SStayCheck/setBus_ryokin", this.$route.params.bus_ryokin);
        this.$store.commit("SStayCheck/setSyku_ryokin", this.$route.params.syku_ryokin);
        this.$store.commit("SStayCheck/setParking_ryokin_day", this.$route.params.parking_ryokin_day);
      } else {
        this.initial_zenhaku_umu = this.$store.state.SStayCheck.initial_zenhaku_umu;
        this.zenhaku_umu = this.$store.state.SStayCheck.zenhaku_umu;
        this.kaisai_date_start = this.$store.state.SStayCheck.kaisai_date_start;
        this.kaisai_date_end = this.$store.state.SStayCheck.kaisai_date_end;
        this.syku_type = this.$store.state.SStayCheck.syku_type;
        this.syku_riyo_date_start = this.$store.state.SStayCheck.syku_riyo_date_start;
        this.syku_riyo_date_end = this.$store.state.SStayCheck.syku_riyo_date_end;
        this.bus_riyo = this.$store.state.SStayCheck.bus_riyo;
        this.parking_riyo = this.$store.state.SStayCheck.parking_riyo;
        this.parking_riyo_date_start = this.$store.state.SStayCheck.parking_riyo_date_start;
        this.parking_riyo_date_end = this.$store.state.SStayCheck.parking_riyo_date_end;
        this.car_no_1 = this.$store.state.SStayCheck.car_no_1;
        this.car_no_2 = this.$store.state.SStayCheck.car_no_2;
        this.car_no_3 = this.$store.state.SStayCheck.car_no_3;
        this.car_no_4 = this.$store.state.SStayCheck.car_no_4;
        this.kinmusaki_tel = this.$store.state.SStayCheck.kinmusaki_tel;
        this.kinmusaki_tel_biko = this.$store.state.SStayCheck.kinmusaki_tel_biko;
        this.kinmusaki_fax = this.$store.state.SStayCheck.kinmusaki_fax;
        this.regist_mode = this.$store.state.SStayCheck.regist_mode;
        this.renewalDate = this.$store.state.SStayCheck.renewalDate;
        this.bus_ryokin = this.$store.state.SStayCheck.bus_ryokin;
        this.syku_ryokin = this.$store.state.SStayCheck.syku_ryokin;
        this.parking_ryokin_day = this.$store.state.SStayCheck.parking_ryokin_day;
      }

      this.getInitialScreenData();
      this.getSystemInitialDate();
    },
    methods: {
      regist: function () {
        var answer = window.confirm(this.$messages.MSG['I3006']);
        if (answer) {
          this.isLoading = true;
          this.errorStatus = false;
          this.errors = [];
          var request_data =
          {
            regist_mode: this.regist_mode,
            syku_type: this.syku_type,
            bus_riyo: this.bus_riyo,
            parking_riyo: this.parking_riyo,
            parking_riyo_date_start: moment(this.parking_riyo_date_start).isValid() ?
              moment(this.parking_riyo_date_start).format('YYYYMMDD') : "",
            parking_riyo_date_end: moment(this.parking_riyo_date_end).isValid() ?
              moment(this.parking_riyo_date_end).format('YYYYMMDD') : "",
            kaisai_date_start: this.kaisai_date_start,
            kaisai_date_end: this.kaisai_date_end,
            jyukoUser: this.screenData.jyukoUser,
            renewal_date: this.renewalDate,
            zenhaku_umu: this.zenhaku_umu,
            syku_riyo_date_start: moment(this.screenData.dtSyukuhaku.dtSt).format('YYYYMMDD'),
            syku_riyo_date_end: moment(this.screenData.dtSyukuhaku.dtEd).format('YYYYMMDD'),
            car_no_1: this.car_no_1,
            car_no_2: this.car_no_2,
            car_no_3: this.car_no_3,
            car_no_4: this.car_no_4,
            kinmusaki_tel: this.kinmusaki_tel,
            kinmusaki_tel_biko: this.kinmusaki_tel_biko,
            kinmusaki_fax: this.kinmusaki_fax,
            syku_nissu: this.screenData.ryokin.syukuhakuDays,
            parking_riyo_nisuu: this.screenData.ryokin.parkingDays,
            syku_ryokin: this.screenData.ryokin.syukuhaku,
            parking_ryokin: this.screenData.ryokin.parking,
            mail_body: this.content_body
          };
          apiService.SStayCheckRegist(request_data)
            .then(result => {
              if (result.status == 'error') {
                if (!alert(result.message_no + " " + result.message)) {
                  this.isLoading = false;
                  this.$router.push({ name: "SSTAYREGIST" });
                }
              }
              else if (result.status == 'error_msg') {
                this.errorStatus = true
                this.errors = [];
                this.error_message = '';
                this.error_message_no = '';
                if (result.errors) {
                  this.errors = result.errors;
                } else {
                  this.error_message = result.message.replaceAll("|", "\n");
                  this.error_message_no = result.message_no;
                }
                this.isLoading = false;
                document.getElementById('contentTop').scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
              else {
                this.registFlag = true;
                this.errorStatus = false;
                this.isLoading = false;
                this.$router.push({ name: "SSTAYPRINT" });
              }
            })
            .catch(error => {
              console.log(error);
              this.errorStatus = true
              this.errors = [];
              this.error_message = '';
              this.error_message_no = '';
              if (error.errors) {
                this.errors = error.errors;
              } else {
                this.error_message = error.message.replaceAll("|", "\n");
                this.error_message_no = error.message_no;
              }
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        }else {
          this.isLoading = false;
        }
      },
      getInitialScreenData() {
        this.isLoading = true;
        var request_data = {
          syku_type: this.syku_type,
          zenhaku_umu: this.zenhaku_umu,
          bus_riyo: this.bus_riyo,
          parking_riyo: this.parking_riyo
        };
        apiService.SStayCheckInitialScreenData(request_data)
          .then(result => {
            if (result.status == 'success') {
              this.screenData.meisyo = result.meisyo;
              this.screenData.jyukoUser = result.jyukosya;
              this.setContentBody();
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if (result.status == 'success') {
              this.systemInitialDate = result.systemdate;
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      setContentBody() {
        this.content_body.zenhaku_umu = this.screenData.meisyo.zenhaku_umu_str;
        this.content_body.bus_riyo = this.screenData.meisyo.bus_riyo_str;
        this.content_body.parking_riyo = this.screenData.meisyo.parking_riyo_str;
        this.content_body.syku_type = this.screenData.meisyo.syku_type_str ? this.screenData.meisyo.syku_type_str : "無";
        this.content_body.kinmusaki_tel = this.kinmusaki_tel;
        this.content_body.kinmusaki_tel_biko = this.kinmusaki_tel_biko ? this.kinmusaki_tel_biko : "-";
        this.content_body.kinmusaki_fax = this.kinmusaki_fax;
        this.setSyukuhakuDate();
        this.setParkingDate();
        this.setSyukuhakuRyokin();
        this.setCarno();
        this.setParkingRyokin();
        this.setBusRyokin();
        this.setTotalAmount();
      },
      setSyukuhakuDate() {
        var dtStStr = "";
        var dtEdStr = "";
        var dtStOrg = this.kaisai_date_start;
        var dtEdOrg = this.kaisai_date_end;
        var dtSt = null;
        var dtEd = null;
        if(this.regist_mode == '1'){
          var chStOrg = this.syku_riyo_date_start;
          var chEdOrg = this.syku_riyo_date_end;
          var chSt = null;
          var chEd = null;
          if (this.initial_zenhaku_umu == "1") {
            dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg).subtract(1, 'days').format('YYYYMMDD') : null;
          }
          else {
            dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg).format('YYYYMMDD') : null;
          }
          dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg).subtract(1, 'days').format('YYYYMMDD') : null;
          chSt = moment(chStOrg).isValid() ? moment(chStOrg).format('YYYYMMDD') : null;
          chEd = moment(chEdOrg).isValid() ? moment(chEdOrg).format('YYYYMMDD') : null;
          if(chSt != dtSt || chEd != dtEd){
            dtSt = moment(chStOrg).isValid() ? moment(chStOrg) : null;
            dtEd = moment(chEdOrg).isValid() ? moment(chEdOrg) : null;
            this.zenhaku_umu = this.initial_zenhaku_umu;
            this.content_body.zenhaku_umu = this.zenhaku_umu == "1" ? "有" : "無";
            if(!this.syku_riyo_date_start && !this.syku_riyo_date_end){
              this.successStatus = true;
              this.success_message = this.$messages.MSG['I3022'].replace("{0}", "が「無し」に").replace("|", "\n");
              this.success_message_no = 'I3022';
            }else{
              this.successStatus = true;
              this.success_message = this.$messages.MSG['I3022'].replace("{0}", "期間が変更").replace("|", "\n");
              this.success_message_no = 'I3022';
            }
          }else{
            if (this.zenhaku_umu == "1") {
              dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg).subtract(1, 'days') : null;
            }
            else {
              dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg) : null;
            }
            dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg).subtract(1, 'days') : null;
          }
        }else{
          if (this.zenhaku_umu == "1") {
            dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg).subtract(1, 'days') : null;
          }
          else {
            dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg) : null;
          }
          dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg).subtract(1, 'days') : null;
        }
        if (dtSt != null) {
          dtStStr = moment(dtSt).format('YYYY/MM/DD (ddd)') + " ～ "
        }
        if (dtEd != null) {
          dtEdStr = moment(dtEd).format('YYYY/MM/DD(ddd)');
        }
        // 宿泊期間の終了日が開始日の前にくる場合
        if (dtEd < dtSt) {
          this.content_body.syku_kikan = "ー";
        }
        this.screenData.dtSyukuhaku.dtSt = dtSt;
        this.screenData.dtSyukuhaku.dtEd = dtEd;
        this.content_body.syku_kikan = dtStStr + dtEdStr;
        if(!this.screenData.meisyo.syku_type_str){
          this.content_body.syku_kikan = "ー";
        }
      },
      setParkingDate() {
        if (this.parking_riyo != "1") {
          this.content_body.parking_kikan = "-";
        }
        else {
          var dtStOrg = this.parking_riyo_date_start;
          var dtEdOrg = this.parking_riyo_date_end;
          var dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg).format('YYYY/MM/DD (ddd)') + " ～ " : "";
          var dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg).format('YYYY/MM/DD(ddd)') : "";

          this.content_body.parking_kikan = dtSt + dtEd;
        }
      },
      setCarno() {
        if (this.parking_riyo != "1") {
          this.content_body.car_no = "-";
        }
        else {
          this.content_body.car_no = this.car_no_1 + " " + this.car_no_2 + " " + this.car_no_3 + " " + this.car_no_4;
        }
      },
      setSyukuhakuRyokin() {
        if (this.syku_ryokin) {
          var ryokin = Number(this.syku_ryokin.replace(/[^0-9]/g, ''));
          var dtSt = this.screenData.dtSyukuhaku.dtSt;
          var dtEd = this.screenData.dtSyukuhaku.dtEd;
          var daysCnt = 0;
          if (dtSt && dtEd) {
            daysCnt = dtEd.diff(dtSt, 'days') + 1;
          }
          var amount = ryokin * daysCnt;
          this.screenData.ryokin.syukuhaku = amount;
          this.screenData.ryokin.syukuhakuDays = daysCnt;

          this.content_body.syku_ryokin
            = amount.toLocaleString('ja-JP') + "円（" + ryokin.toLocaleString('ja-JP') + "円 " + daysCnt + "泊）";
        }else{
          this.content_body.syku_ryokin = "-";
        }
      },
      setParkingRyokin() {
        if (this.parking_riyo != "1") {
          this.content_body.parking_ryokin = "-";
        }
        else {
          var dtSt = moment(this.parking_riyo_date_start).isValid() ? moment(this.parking_riyo_date_start) : null;
          var dtEd = moment(this.parking_riyo_date_end).isValid() ? moment(this.parking_riyo_date_end) : null;
          if (dtSt == null || dtEd == null) {
            this.content_body.parking_ryokin = "-";
          }
          if (this.parking_ryokin_day) {
            var ryokin = 0;
            var amount = 0;
            var daysCnt = dtEd.diff(dtSt, 'days') + 1;
              ryokin = Number(this.parking_ryokin_day.replace(/[^0-9]/g, ''));
              amount = ryokin * daysCnt;
              this.screenData.ryokin.parking = amount;
              this.screenData.ryokin.parkingDays = daysCnt;
              this.content_body.parking_ryokin
                = amount.toLocaleString('ja-JP') + "円（" + ryokin.toLocaleString('ja-JP') + "円 " + daysCnt + "日）";
          }
        }
      },
      setBusRyokin() {
        if (this.bus_riyo != "1") {
          this.content_body.bus_ryokin = "-";
        }
        else {
          if (this.bus_ryokin) {
            var ryokin = Number(this.bus_ryokin.replace(/[^0-9]/g, ''));
            this.screenData.ryokin.bus = ryokin;
            this.content_body.bus_ryokin
              = ryokin.toLocaleString('ja-JP') + "円";
          }
        }
      },
      setTotalAmount() {
        var amount = Number(this.screenData.ryokin.syukuhaku);
        if (this.parking_riyo == "1") {
          amount += Number(this.screenData.ryokin.parking);
        }
        if (this.bus_riyo == "1") {
          amount += Number(this.screenData.ryokin.bus);
        }
        this.content_body.total_ryokin
          = amount.toLocaleString('ja-JP') + "円";
        this.isLoading = false;
      },
      back() {
        this.backFlag = true;
      }
    },
    beforeRouteLeave(to, from, next) {
      if(this.backFlag || this.registFlag) {
        next();
      } else {
        var answer = true;
        answer = window.confirm(this.$messages.MSG['I3005']);
        if (answer) {
          next();
        } else {
          next(false);
        }
      }
      
    },
  };
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3">
      <b-alert variant="danger" dismissible fade :show="errorStatus" @dismissed="errorStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">{{ error_message_no }} {{ error_message }}</div>
        <div class="col-md-12" style="white-space: pre !important" v-for="error in errors" :key="error.index">
          {{error.slice('')[0].split('/')[1]}} {{error.slice('')[0].split('/')[0]}}
        </div>
      </b-alert>
      <b-alert dismissible fade :show="successStatus" @dismissed="successStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="success_message">{{ success_message_no }} {{ success_message }}</div>
      </b-alert>
      <div class="container" style="margin-top: 20px">
        <div class="row progress_row">
          <div class="subCell col-4 text-center" style="border-right: 3px solid #fff">
            予約申込
          </div>
          <div class="mainCell col-4 text-center bg-info text-white" style="border-right: 3px solid #fff">
            予約確認
          </div>
          <div class="subCell col-4 text-center">予約完了</div>
        </div>
        <div class="row text-danger" style="margin-top: 20px">
          <div class="col-md-3 col-0"></div>
          <div class="col-md-6 col-12">
            ※予約申込は、登録ボタンをクリックするまでは確定されません。<br />
            宿泊・駐車場の予約申込情報を確認の上、登録をクリックして下さい。
          </div>
          <div class="col-md-3 col-0"></div>
        </div>
        <div id="mainTable" class="row" style="margin-top: 20px">
          <div class="container" style="padding: 0 30px;">
            <div class="row">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                宿泊期間
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.syku_kikan}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                前日泊
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.zenhaku_umu}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                宿泊室タイプ
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.syku_type}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                復路直通バス利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.bus_riyo}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                駐車場利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.parking_riyo}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                駐車場利用期間
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.parking_kikan}}
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                車のナンバー
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{content_body.car_no}}
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary table_height" style="height: auto">
                <div class="text-light text-center" style="margin: 0 auto">
                  連絡先
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light">
                <div class="container" style="padding: 0px">
                  <div class="row table_height">
                    <div class="col-md-5 col-5">勤務先TEL</div>
                    <div class="col-md-7 col-7">{{content_body.kinmusaki_tel}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">(備考)</div>
                    <div class="col-md-7 col-7 break-word">{{content_body.kinmusaki_tel_biko}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">勤務先FAX</div>
                    <div class="col-md-7 col-7">{{content_body.kinmusaki_fax}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary table_height" style="height: auto">
                <div class="text-light text-center" style="margin: 0 auto">
                  料金
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light">
                <div class="container" style="padding: 0px">
                  <div class="row table_height">
                    <div class="col-md-5 col-5">宿泊料</div>
                    <div class="col-md-7 col-7">{{content_body.syku_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">駐車場利用料</div>
                    <div class="col-md-7 col-7">{{content_body.parking_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">復路バス利用料</div>
                    <div class="col-md-7 col-7">{{content_body.bus_ryokin}}</div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-5">合計</div>
                    <div class="col-md-7 col-7">{{content_body.total_ryokin}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin: 30px 0">
          <div style="max-width: 300px; margin: 0 auto">
            <button class="btn btn-primary" role="button" style="width: 140px" @click="regist">
              登録
            </button>

            <router-link 
            :to="{ 
              name: 'SSTAYREGIST' , 
              params: { 
                kaisai_date_start: kaisai_date_start, 
                kaisai_date_end: kaisai_date_end, 
                zenhaku_umu: zenhaku_umu, 
                syku_type: syku_type, 
                bus_riyo: bus_riyo, 
                parking_riyo: parking_riyo, 
                parking_riyo_date_start: parking_riyo_date_start, 
                parking_riyo_date_end: parking_riyo_date_end, 
                car_no_1: car_no_1, 
                car_no_2: car_no_2, 
                car_no_3: car_no_3, 
                car_no_4: car_no_4, 
                kinmusaki_tel: kinmusaki_tel, 
                kinmusaki_tel_biko: kinmusaki_tel_biko, 
                kinmusaki_fax: kinmusaki_fax, 
                regist_mode: regist_mode, 
                renewalDate: renewalDate, 
                bus_ryokin: bus_ryokin, 
                parking_ryokin_day: parking_ryokin_day, 
                }
              }" class="">
              <button class="btn btn-secondary ml-2" role="button" style="width: 140px" @click="back()">
                戻る
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row_height {
    line-height: 50px;
  }

  .text-car-num {
    width: 75px !important;
    margin-left: 5px;
  }

  .date-style {
    width: 142px;
  }
  .text-required {
    color: #ff4342 !important;
  }

  .progress_row {
    margin-top: 10px;
    background-color: #eee;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }
</style>
