<script>
import Vue from 'vue';
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default Vue.extend({
    name: "TosiList",
    props: ['tdfk_cd'],
    data() {
      return {
        toggleFlg: false,
        modes: ['multi', 'single', 'range'],

        fields: [
          {key: 'selected', label: '選択', sortable: true, thStyle: "width:60px", class: "text-center"},
          {key: 'tosi_cd', label: 'コード', sortable: true, thStyle: "width:80px", class: "text-center"},
          {key: 'tosi_name', label: '名称', sortable: true}
        ],
        items: [],
        selectMode: 'single',
        tosi_cd: '',
        selected: [],
        infoMsg: "",
        errorMsg: "",
        successStatus :false,
        errorStatus :false,
        errors: []
      };
    },
    mounted() {
      var request_data = {
        tdfk_cd: this.tdfk_cd
      };
      apiService.TosiListGetCityData(request_data)
      .then(result => {
        if(result.status == 'success'){
          this.errorStatus = false;
          for (var i = 0; i < result.tosiData.length; i++) {
            this.items.push({
              tosi_cd: result.tosiData[i].tosi_cd,
              tosi_name: result.tosiData[i].tosi_name,
              del_flg: result.tosiData[i].del_flg
            });
          }
        }
        else{
          this.errorStatus = true;
          this.successStatus = false;
          this.errorMsg = result.message.replaceAll("|","\n");
          this.errorNo = result.message_no;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      })
      .catch(error => {
        console.log(error);
      });
     },
    methods: {
      setKencode(items){
        this.kenCode = items;
      },
      onRowSelected(items) {
        this.tosi_cd = items;
        if(items.length > 0){
          this.$emit('Active', false)
        } else {
          this.$emit('Active', true)
        }
      },
      setClose() {
        this.$emit('clicked', this.tosi_cd)
      },
      rowClass(row) {
        if(row){
          if (row.del_flg === "1") {
            return 'TableRowDelBackcolor'
          }
        }
        return;
      },
    },
  });
</script>
<template>
  <main>
    <div id="contentTop"></div>
    <div class="text-left">
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="errorMsg">{{ errorNo }} {{ errorMsg }}</div>
        <div class="col-md-12" style="white-space: pre !important" v-for="error in errors" :key="error.index">
          {{error.slice('')[0].split('/')[1]}} {{error.slice('')[0].split('/')[0]}}
        </div>
      </b-alert>
    </div>
    <b-table sticky-header="450px" bordered hover striped small :items="items" :fields="fields" :head-variant="'dark'" 
      :tbody-tr-class="rowClass" selectable :select-mode="'single'" @row-selected="onRowSelected" show-empty 
      :empty-text="$messages.MSG['I0008']">
      <template #empty="scope">{{ scope.emptyText }}</template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
  </main>
</template>
