import CONSTANTS from "./constants.js";
import MESSAGES from "./messages.js";
import App from "./App.vue";
import router from "./router.js";
import "./styles/variables/jpn.css";
import VuejsDialog from "vuejs-dialog";
import store from "./stores/index";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer"; // Optional
import Vue from "vue";
//import { BootstrapVue } from 'bootstrap-vue'
import Notifications from "vue-notification";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./styles/variables/customize.css";


// Tell Vue to install the plugin.
Vue.use(VuejsDialog);
Vue.config.productionTip = false;

Vue.prototype.$constants = CONSTANTS;
Vue.prototype.$messages = MESSAGES;

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
