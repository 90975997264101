<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "PLogin",
  data() {
    return {
      login_id: "",
      password: "",
      user_kbn: null,
      user_name: null,
      mail: null,
      error: null,
      error1: null,
      errors : {
        id: null,
        password: null,
        id_message_no :null,
        id_password_no :null
      },
      errorStatus : false,
      message_no :null,
      message_no1 :null,
      passFlg: true,
      unAuthFlg: '0',
    };
  },
  components: {
    AppLayout,
  },
  mounted() {
    apiService.setUserKbn(this.$constants.USER_KBN_P);
    if(this.$route.params.UnAuth_flg){
      this.unAuthFlg = this.$route.params.UnAuth_flg;
      if(this.unAuthFlg == '2'){
        this.errorStatus = true;
        this.message_no = 'I3025';
        this.error = this.$messages.MSG['I3025'].replace("|", "\n");
      }
    }
  },
  methods: {
    clickPLogin: function () {
        var request_data = {
          login_id: this.login_id,
          password: this.password
        };
        apiService
          .PloginLogin(request_data)
          .then((result) => {
            apiService.setToken(result.token);
            apiService.CommonLoginInfo()
            .then((result_info)=>{
              this.$store.commit("Header/setLoginUserName", result_info.user_name);
              this.$store.commit("Header/setSessionData", result_info.session);
              this.$store.commit("Header/setRegistFlg", result.regist_flg);
              if (result.regist_flg == "0") {
                this.$router.push({
                  name: "PTantoRegist",
                  params: { login_id: this.login_id,
                            account_flg: '0' }
                });
              } else {
                this.$router.push({
                  name: "PPortal",
                });
              }
            })
            .catch((error)=>{
              console.log(error)
            })
          })
          .catch((error) => {
            this.errorStatus = true;
            this.message_no = error.message_no;

            if(error.errors) {
              this.error = "";
              this.errors.id = (error.errors.login_id) ? error.errors.login_id[0].split("/")[0] : '';
              this.errors.id_message_no = (error.errors.login_id) ? error.errors.login_id[0].split("/")[1] : '';

              this.errors.password = (error.errors.password) ? error.errors.password[0].split("/")[0]  : '';
              this.errors.password_message_no = (error.errors.password) ? error.errors.password[0].split("/")[1] : '';

            } else {
              this.error = error.message.replaceAll("|","\n");
              this.errors.id = "";
              this.errors.password ="";

              if(error.message1) {
                this.message_no1 = error.message_no1;
                this.error1 = error.message1.replaceAll("|","\n");
              } else {
                this.error1 = "";
                this.message_no1 = "";
              }
            }
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            if(error.status == "question") {
              this.error = "";
              this.errorStatus = false;
              if(confirm(error.message.replace("|","\n"))==true) {
                var request_data = {
                  login_id: this.login_id,
                  password: this.password
                };
                apiService.updateMLogin(request_data)
                .then((result) => {
                  apiService.setToken(result.token);
                  apiService.CommonLoginInfo()
                  .then((result_info)=>{
                    this.$store.commit("Header/setLoginUserName", result_info.user_name);
                    this.$store.commit("Header/setSessionData", result_info.session);
                    this.$store.commit("Header/setRegistFlg", result.regist_flg);
                    this.$router.push({
                      name: "PTantoRegist",
                      params: { login_id: this.login_id,
                                account_flg: '0' }
                    });
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
                })
                .catch((error) => {
                  console.log(error);
                });
              }
            }
          });
      },
  }
};
</script>

<template>
  <AppLayout>
    <main v-bind:class="errorStatus ? '' : 'contents-height pt-sm-3'">
    <div id="contentTop"></div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="errorStatus"
      @dismissed="errorStatus=false"
      >
      <div class="col-md-12" style="white-space: pre !important" v-if="this.errors.id">
        {{ this.errors.id_message_no }} {{ this.errors.id.replace("|","\n") }}
      </div>
      <div class="col-md-12" style="white-space: pre !important" v-if="this.errors.password">
         {{ this.errors.password_message_no }}  {{ this.errors.password.replace("|","\n") }}
      </div>
      <div class="col-md-12" style="white-space: pre !important" v-if="this.error">
        {{ message_no }}  {{ error.replace("|","\n") }}
      </div>
      <div class="col-md-12" style="white-space: pre !important" v-if="this.error1">
        {{ message_no1 }}  {{ error1.replace("|","\n") }}</div>
    </b-alert>
      <div class="container text-center">
        <h3 class="font-weight-bold text-black">
          研修システム
        </h3>
      </div>
      <div class="container text-center">
        <h5 class="font-weight-normal text-black">
          推薦担当者ログイン画面
        </h5>
      </div>
      <div class="container login-body rounded text-center">
        <h6 class="font-weight-normal text-black">
          IDとパスワードを入力してログインして下さい。
        </h6>
        <form class="form-signin">
          <label for="login-id" class="sr-only">ID</label>
          <input
            type="text"
            id="login-id"
            class="form-control"
            style="ime-mode: disabled"
            placeholder="ID"
            autofocus
            v-model="login_id"
          />
          <label for="inputPassword" class="sr-only">パスワード</label>
          <div v-if="passFlg" style="position: relative">
            <input type="password" id="password" v-model="password" class="form-control password mb-0" style="padding-right: 20px;" placeholder="パスワード">
            <label class="icon-pass" @click="passFlg = false">
              <b-icon icon="eye-fill"></b-icon>
            </label>
          </div>
          <div v-else style="position: relative;">
            <input type="text" id="password" v-model="password" class="form-control password mb-0" style="padding-right: 20px;" placeholder="パスワード">
            <label class="icon-pass" @click="passFlg = true">
              <b-icon icon="eye-slash-fill"></b-icon>
            </label>
          </div>
          <router-link
            :to="{ name: 'PassWordResend' }"
            class="font-weight-normal text-primary d-block text-right"
            style="font-size: 15px;"
          >
            パスワード再発行画面へ
          </router-link>
          <router-link
            :to="{ name: 'KeyWordResend' }"
            class="font-weight-normal text-primary d-block text-right"
            style="font-size: 15px;"
          >
            キーワード再発行画面へ
          </router-link>
          <button
            class="btn btn-lg btn-block btn-outline-primary mt-3"
            type="button"
            @click="clickPLogin()"
          >
            ログイン
          </button>
        </form>
      </div>
    </main>
  </AppLayout>
</template>

