<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import SSRegMsg from '@/pages/SSRegMsg/index.vue'
  import datepicker from "vuejs-datepicker";
  import moment from 'moment';
  import { ja } from "vuejs-datepicker/dist/locale";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  moment.locale("ja");
  export default {
    name: "SSTAYREGIST",
    components: {
      AppLayout,
      SSRegMsg,
      datepicker,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        ja: ja,
        calendar_right: "calendar_right",
        screenData: {
          zenhakuDisable: false,
          meisyo: {},
          screen: {},
          yoyaku: {},
          yoyakusu: [],
          carwari: {},
          heyawari: {},
          sykuTypeInput: [
            {
              syku_type: 'A',
              text: "",
              radioVisible: false,
              radioDisable: false,
              radioChecked: false
            },
            {
              syku_type: 'B',
              text: "",
              radioVisible: false,
              radioDisable: false,
              radioChecked: false
            },
            {
              syku_type: 'C',
              text: "",
              radioVisible: false,
              radioDisable: false,
              radioChecked: false
            }
          ],
          parking: {
            text: "",
            visiblecb: true,
            disablecb: false,
            disabledt: true,
            disableno: true
          }
        },
        yoyakuOrg: {},
        renewalMode: false,
        registFlag: false,
        renewalDate: "",
        success_message: '',
        success_message_no: '',
        successStatus: false,
        errors: [],
        error_message: '',
        error_message_no: '',
        errorStatus: false,
        systemInitialDate: '',
        oldRequestData: {},
        initial_zenhaku_umu: ''
      };
    },
    mounted() {
      this.getInitialScreenData();
      this.getSystemInitialDate();
    },
    computed: {
      isSykuTypeVisible: function () {
        return function (type) {
          var sykType = type.toUpperCase();
          var item = this.screenData.sykuTypeInput.filter(x => x.syku_type === sykType);
          if (item) {
            return item[0].radioVisible;
          }
          return false;
        }
      },
      isSykuTypeDisable: function () {
        return function (type) {
          if(this.screenData.yoyaku.syku_riyo == '0'){
            return true;
          }
          var sykType = type.toUpperCase();
          var item = this.screenData.sykuTypeInput.filter(x => x.syku_type === sykType);
          if (item) {
            return item[0].radioDisable;
          }
          return false;
        }
      },
      sykuTypeText: function () {
        return function (type) {
          var sykType = type.toUpperCase();
          var item = this.screenData.sykuTypeInput.filter(x => x.syku_type === sykType);
          if (item) {
            return item[0].text;
          }
          return "";
        }
      }
    },
    methods: {
      check: function () {
        this.errorStatus = false;
        this.errors = [];
        var result = this.inputCheck();
        if (result == false) {
          this.errorStatus = true;
          document.getElementById('contentTop').scrollIntoView({ behavior: 'smooth', block: 'start' });
          return;
        }else if(result != true){
          if(confirm(this.error_message.replace("|","\n")) == true){
            this.screenData.yoyaku.zenhaku_umu = "0";
          }else{
            return;
          }
        }
        this.isLoading = true;

        var request_data = {
          kaisai_date_start: this.screenData.screen.kaisai_date_start,
          kaisai_date_end: this.screenData.screen.kaisai_date_end,
          zenhaku_umu: this.screenData.yoyaku.zenhaku_umu,
          syku_type: this.screenData.yoyaku.syku_type,
          bus_riyo: this.screenData.yoyaku.bus_riyo,
          parking_riyo: this.screenData.yoyaku.parking_riyo,
          parking_riyo_date_start: moment(this.screenData.yoyaku.parking_riyo_date_start).isValid() ?
            moment(this.screenData.yoyaku.parking_riyo_date_start).format('YYYYMMDD') : "",
          parking_riyo_date_end: moment(this.screenData.yoyaku.parking_riyo_date_end).isValid() ?
            moment(this.screenData.yoyaku.parking_riyo_date_end).format('YYYYMMDD') : "",
          car_no_1: this.screenData.yoyaku.car_no_1,
          car_no_2: this.screenData.yoyaku.car_no_2,
          car_no_3: this.screenData.yoyaku.car_no_3,
          car_no_4: this.screenData.yoyaku.car_no_4,
          kinmusaki_tel: this.screenData.yoyaku.kinmusaki_tel,
          kinmusaki_tel_biko: this.screenData.yoyaku.kinmusaki_tel_biko,
          kinmusaki_fax: this.screenData.yoyaku.kinmusaki_fax,
          regist_mode: this.renewalMode == true ? "1" : "0"
        };
        apiService.SStayRegistConfirm(request_data)
          .then(result => {
            if (result.status == 'error') {
              this.errorStatus = true
              this.errors = [];
              this.error_message = '';
              this.error_message_no = '';
              if (result.errors) {
                this.errors = result.errors;
              } else {
                this.error_message = result.message.replaceAll("|", "\n");
                this.error_message_no = result.message_no;
              }
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            else {
              this.setOldRequestData();
              this.errorStatus = false;

              var sykuType = this.screenData.yoyaku.syku_type;
              var sykuPrice = 0;
              switch (sykuType) {
                case 'A':
                  sykuPrice = this.screenData.screen.a_type_ryokin;
                  break;
                case 'B':
                  sykuPrice = this.screenData.screen.b_type_ryokin;
                  break;
                case 'C':
                  sykuPrice = this.screenData.screen.c_type_ryokin;
                  break;
              }
              this.isLoading = false;
              this.$router.push({
                name: "SSTAYCHECK",
                params: {
                  kaisai_date_start: this.screenData.screen.kaisai_date_start,
                  kaisai_date_end: this.screenData.screen.kaisai_date_end,
                  initial_zenhaku_umu: this.initial_zenhaku_umu ? this.initial_zenhaku_umu : "0",
                  zenhaku_umu: this.screenData.yoyaku.zenhaku_umu ? this.screenData.yoyaku.zenhaku_umu : "0",
                  syku_type: this.screenData.yoyaku.syku_type,
                  syku_riyo_date_start: this.screenData.yoyaku.syku_riyo_date_start,
                  syku_riyo_date_end: this.screenData.yoyaku.syku_riyo_date_end,
                  bus_riyo: this.screenData.yoyaku.bus_riyo ? this.screenData.yoyaku.bus_riyo : "0",
                  parking_riyo: this.screenData.yoyaku.parking_riyo ?
                    this.screenData.yoyaku.parking_riyo : "0",
                  parking_riyo_date_start: this.screenData.yoyaku.parking_riyo == "1" ?
                    this.screenData.yoyaku.parking_riyo_date_start : "",
                  parking_riyo_date_end: this.screenData.yoyaku.parking_riyo == "1" ?
                    this.screenData.yoyaku.parking_riyo_date_end : "",
                  car_no_1: this.screenData.yoyaku.car_no_1,
                  car_no_2: this.screenData.yoyaku.car_no_2,
                  car_no_3: this.screenData.yoyaku.car_no_3,
                  car_no_4: this.screenData.yoyaku.car_no_4,
                  kinmusaki_tel: this.screenData.yoyaku.kinmusaki_tel,
                  kinmusaki_tel_biko: this.screenData.yoyaku.kinmusaki_tel_biko,
                  kinmusaki_fax: this.screenData.yoyaku.kinmusaki_fax,
                  regist_mode: this.renewalMode == true ? "1" : "0",
                  renewalDate: this.renewalDate,
                  bus_ryokin: this.screenData.screen.bus_ryokin,
                  syku_ryokin: sykuPrice,
                  parking_ryokin_day: this.screenData.screen.parking_ryokin_day,
                }
              });
            }
          })
          .catch(error => {
            console.log(error);
            this.errorStatus = true
            this.errors = [];
            this.error_message = '';
            this.error_message_no = '';
            if (error.errors) {
              this.errors = error.errors;
            } else {
              this.error_message = error.message.replaceAll("|", "\n");
              this.error_message_no = error.message_no;
            }
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
      },
      inputCheck() {
        var result = false;
        var zenhakuUmu = this.screenData.yoyaku.zenhaku_umu;
        var sykyType = this.screenData.yoyaku.syku_type;
        if (zenhakuUmu == "1") {
          if (sykyType == 'A') {
            result = this.screenData.screen.a_type_wariate_umu != "1" || this.screenData.screen.a_type_zenhaku_flg != "1" ? false : true;
          }
          else if (sykyType == 'B') {
            result = this.screenData.screen.b_type_wariate_umu != "1" || this.screenData.screen.b_type_zenhaku_flg != "1" ? false : true;
          }
          else if (sykyType == 'C') {
            result = this.screenData.screen.c_type_wariate_umu != "1" || this.screenData.screen.c_type_zenhaku_flg != "1" ? false : true;
          }
        }
        else {
          if (sykyType == 'A') {
            result = this.screenData.screen.a_type_wariate_umu != "1" ? false : true;
          }
          else if (sykyType == 'B') {
            result = this.screenData.screen.b_type_wariate_umu != "1" ? false : true;
          }
          else if (sykyType == 'C') {
            result = this.screenData.screen.c_type_wariate_umu != "1" ? false : true;
          }
        }
        if (result == false) {
          if(this.screenData.yoyaku.parking_riyo != "1" && this.screenData.yoyaku.bus_riyo != "1"){
            this.error_message_no = 'E0069';
            this.error_message = this.$messages.MSG['E0069'].replace("|", "\n");
            return false;
          }
          if(this.screenData.yoyaku.syku_riyo == '0'){
            return true;
          }
          this.error_message = this.$messages.MSG['Q0129'].replace('|', '\n');
          return this.error_message;
        }
        return true;
      },
      back: function () {
        this.registFlag = false;
        this.confirmUpdate();
        var answer = true;
        if (this.registFlag) {
          answer = window.confirm(this.$messages.MSG['I3005']);
        }
        if (answer) {
          this.$router.push({ name: "SSTAYMANUAL" });
        }
      },
      confirmUpdate() {
        Object.keys(this.screenData.yoyaku).forEach((key) => {
          if (this.registFlag == false) {
            var orgVal = this.yoyakuOrg[key];
            var newVal = this.screenData.yoyaku[key];
            switch (key) {
              case 'parking_riyo_date_start':
              case 'parking_riyo_date_end':
                newVal = moment(newVal).isValid() ? moment(newVal).format('YYYYMMDD') : "";
                newVal = newVal.replace(/\//g, '');
                break;
            }

            if (orgVal !== undefined) {
              this.registFlag = orgVal != newVal;
            }
            else {
              this.registFlag = newVal != '';
            }
          }
        });
        return this.registFlag;
      },
      getInitialScreenData() {
        this.isLoading = true;
        this.registFlag = false;
        this.renewalMode = false;
        apiService.SStayRegistInitialScreenData()
          .then(result => {
            if (result.status == 'success') {
              if(result.uketukegai == true){
                this.$router.push({
                  name: "SSTAYMANUAL",
                });
              }

              this.screenData.screen = result.screenData;
              this.screenData.meisyo = result.meisyo;
              this.screenData.yoyakusu = result.yoyakusu;
              this.screenData.carwari = result.carwari;
              this.screenData.yoyaku = result.yoyaku;
              this.screenData.heyawari = result.heyawari;

              if(this.screenData.screen.kaisai_date_start >= this.screenData.screen.kaisai_date_end){
                this.successStatus = true
                this.success_message_no = 'I0115';
                this.success_message = this.$messages.MSG['I0115'].replace("{0}", "研修期間").replace("|", "\n");
                document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              }

              if(this.screenData.yoyaku.syku_riyo == '0'){
                this.screenData.zenhakuDisable = true;
                this.successStatus = true
                this.success_message_no = 'I3022';
                this.success_message = this.$messages.MSG['I3022'].replace("{0}", "が「無し」に").replace("|", "\n");
                document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              }

              this.yoyakuOrg = Object.assign({ ...this.screenData.yoyaku });
              this.renewalMode = Object.keys(this.screenData.yoyaku).length > 0 ? true : false;
              this.renewalDate = this.renewalMode == true ? this.screenData.yoyaku.renewal_date : "";
              this.initial_zenhaku_umu = this.screenData.yoyaku.zenhaku_umu;
              this.setOldRequestData();
              this.setBackData();
            }
            else {
              this.screenData.meisyo = result.meisyo;
            }
            this.setZenhakuType();
            this.setParkingData();
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      setParkingData() {
        this.screenData.parking.disablecb = true;
        this.screenData.parking.disabledt = true;
        this.screenData.parking.disableno = true;
        if (this.screenData.carwari.parking_wariate_su == 0) {
          this.screenData.parking.text = "利用不可";
        }
        else {
          var cnt = this.screenData.carwari.parking_wariate_su;
          var orgParking = this.yoyakuOrg.parking_riyo ? this.yoyakuOrg.parking_riyo : "0";
          if (orgParking == "1") {
            cnt = cnt - (this.screenData.carwari.parking_yoyaku_su - 1);
          }
          else {
            cnt = cnt - this.screenData.carwari.parking_yoyaku_su;
          }
          if (cnt < 1) {
            this.screenData.parking.text = "満車";
          }
          else {
            this.screenData.parking.text = "利用する";
            this.screenData.parking.disablecb = false;
          }
        }

        if (this.screenData.yoyaku.parking_riyo == "1") {
          this.screenData.parking.disabledt = false;
          this.screenData.parking.disableno = false;
          var dtStOrg = (this.screenData.yoyaku.parking_riyo_date_start) ? this.screenData.yoyaku.parking_riyo_date_start : "";
          var dtEdOrg = (this.screenData.yoyaku.parking_riyo_date_end) ? this.screenData.yoyaku.parking_riyo_date_end : "";
          var dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg) : "";
          var dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg) : "";
          if (dtSt == "" || dtEd == "") {
            return;
          }
          this.screenData.yoyaku.parking_riyo_date_start = moment(dtSt).format('YYYY/MM/DD');
          this.screenData.yoyaku.parking_riyo_date_end = moment(dtEd).format('YYYY/MM/DD');
        }
        else {
          this.screenData.yoyaku.parking_riyo_date_start = "";
          this.screenData.yoyaku.parking_riyo_date_end = "";
          this.screenData.yoyaku.car_no_1 = "";
          this.screenData.yoyaku.car_no_2 = "";
          this.screenData.yoyaku.car_no_3 = "";
          this.screenData.yoyaku.car_no_4 = "";
        }
        this.isLoading = false;
      },
      getSyukuhakuTypeName(type) {
        var sykType = type.toUpperCase();
        var price = "";
        var name = "";
        if (Object.keys(this.screenData.meisyo).length > 0) {
          if (sykType == 'A') {
            price = this.screenData.screen.a_type_ryokin ? this.screenData.screen.a_type_ryokin : "";
            name = this.screenData.meisyo[0].name_1 ? this.screenData.meisyo[0].name_1 : "";
            return name + "（" + price + "円）";
          }
          else if (sykType == 'B') {
            price = this.screenData.screen.a_type_ryokin ? this.screenData.screen.b_type_ryokin : "";
            name = this.screenData.meisyo[1].name_1 ? this.screenData.meisyo[1].name_1 : "";
            return name + "（" + price + "円）";
          }
          else if (sykType == 'C') {
            price = this.screenData.screen.a_type_ryokin ? this.screenData.screen.c_type_ryokin : "";
            name = this.screenData.meisyo[2].name_1 ? this.screenData.meisyo[2].name_1 : "";
            return name + "（" + price + "円）";
          }
        }
        return "";
      },
      setZenhakuType() {
        var zenhakuUmu = this.screenData.yoyaku.zenhaku_umu;
        this.screenData.sykuTypeInput.forEach((inputType) => {

          var wariate = "";
          var zenhakuFlg = "";
          var sykType = inputType.syku_type;
          var heyawariCnt = 0;

          if (sykType == 'A') {
            wariate = this.screenData.screen.a_type_wariate_umu;
            zenhakuFlg = this.screenData.screen.a_type_zenhaku_flg;
            heyawariCnt = this.screenData.heyawari.syku_type_a;
          }
          else if (sykType == 'B') {
            wariate = this.screenData.screen.b_type_wariate_umu;
            zenhakuFlg = this.screenData.screen.b_type_zenhaku_flg;
            heyawariCnt = this.screenData.heyawari.syku_type_b;
          }
          else if (sykType == 'C') {
            wariate = this.screenData.screen.c_type_wariate_umu;
            zenhakuFlg = this.screenData.screen.c_type_zenhaku_flg;
            heyawariCnt = this.screenData.heyawari.syku_type_c;
          }
          var kanousu = 0;
          if (Object.keys(this.screenData.yoyakusu).length > 0) {
            var target = this.screenData.yoyakusu.filter(x => x.syku_type === sykType);
            if (target) {
              var syku_yoyaku_su = target[0].syku_yoyaku_su;
              if (this.renewalMode == true) {
                syku_yoyaku_su = (syku_yoyaku_su > 0) ? target[0].syku_yoyaku_su - 1 : syku_yoyaku_su;
              }
              kanousu = target[0].syku_kanou_su - syku_yoyaku_su;
            }
          }
          else {
            wariate = "0";
          }
          var visible = false;
          if (zenhakuUmu == '1') {
            if (wariate == '0' || zenhakuFlg == '0') {
              inputType.text = "利用不可";
            }
            else if (kanousu < 1 || heyawariCnt < 1) {
              inputType.text = "満室";
            }
            else {
              inputType.text = "";
              visible = true;
            }
            inputType.radioVisible = visible;
          }
          else {
            if (wariate == '0') {
              inputType.text = "利用不可";
            }
            else if (kanousu < 1 || heyawariCnt < 1) {
              inputType.text = "満室";
            }
            else {
              inputType.text = "";
              visible = true;
            }
            inputType.radioVisible = visible;
          }

          if (this.renewalMode && this.screenData.yoyaku.syku_type) {
            inputType.radioDisable = sykType == this.screenData.yoyaku.syku_type ? false : true;
            inputType.radioChecked = sykType == this.screenData.yoyaku.syku_type ? true : false;
            if(sykType == this.screenData.yoyaku.syku_type && zenhakuFlg == '0'){
              this.screenData.zenhakuDisable = true;
            }
          }
          else {
            inputType.radioDisable = false;
            inputType.radioChecked = false;
          }
        });

        var sykyType = this.screenData.yoyaku.syku_type;
        if(!this.renewalMode && zenhakuUmu == "1"){
          if (sykyType == 'A' && (this.screenData.screen.a_type_wariate_umu != "1" || this.screenData.screen.a_type_zenhaku_flg != "1")) {
            this.screenData.yoyaku.syku_type = '0';
            this.$root.$emit('bv::show::modal', 'modal-ssregmsg')
          }
          else if (sykyType == 'B' && (this.screenData.screen.b_type_wariate_umu != "1" || this.screenData.screen.b_type_zenhaku_flg != "1")) {
            this.screenData.yoyaku.syku_type = '0';
            this.$root.$emit('bv::show::modal', 'modal-ssregmsg')
          }
          else if (sykyType == 'C' && (this.screenData.screen.c_type_wariate_umu != "1" || this.screenData.screen.c_type_zenhaku_flg != "1")) {
            this.screenData.yoyaku.syku_type = '0';
            this.$root.$emit('bv::show::modal', 'modal-ssregmsg')
          }
        }
      },
      setBackData(){
        if(this.$route.params.kaisai_date_start){
          this.screenData.screen.kaisai_date_start = this.$route.params.kaisai_date_start;
          this.screenData.screen.kaisai_date_end = this.$route.params.kaisai_date_end;
          this.screenData.yoyaku.zenhaku_umu = this.$route.params.zenhaku_umu;
          this.screenData.yoyaku.syku_type = this.$route.params.syku_type;
          this.screenData.yoyaku.bus_riyo = this.$route.params.bus_riyo;
          this.screenData.yoyaku.parking_riyo = this.$route.params.parking_riyo;
          this.screenData.yoyaku.parking_riyo_date_start = this.$route.params.parking_riyo == "1" ? this.$route.params.parking_riyo_date_start : "";
          this.screenData.yoyaku.parking_riyo_date_end = this.$route.params.parking_riyo == "1" ? this.$route.params.parking_riyo_date_end : "";
          this.screenData.yoyaku.car_no_1 = this.$route.params.car_no_1;
          this.screenData.yoyaku.car_no_2 = this.$route.params.car_no_2;
          this.screenData.yoyaku.car_no_3 = this.$route.params.car_no_3;
          this.screenData.yoyaku.car_no_4 = this.$route.params.car_no_4;
          this.screenData.yoyaku.kinmusaki_tel = this.$route.params.kinmusaki_tel;
          this.screenData.yoyaku.kinmusaki_tel_biko = this.$route.params.kinmusaki_tel_biko;
          this.screenData.yoyaku.kinmusaki_fax = this.$route.params.kinmusaki_fax;
          this.renewalMode = this.$route.params.regist_mode == "1" ? true : false;
          this.renewalDate = this.$route.params.renewalDate;
          this.screenData.screen.bus_ryokin = this.$route.params.bus_ryokin;
          this.screenData.screen.parking_ryokin_day = this.$route.params.parking_ryokin_day;
        }
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if (result.status == 'success') {
              this.systemInitialDate = result.systemdate;
            }
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      onZenhakuChange: function () {
        this.setZenhakuType();
        if (this.screenData.yoyaku.parking_riyo == "1") {
          var dtSt = moment(this.screenData.screen.kaisai_date_start);
          if (this.screenData.yoyaku.zenhaku_umu == "1") {
            dtSt = moment(dtSt).subtract(1, 'days');
          }
          this.screenData.yoyaku.parking_riyo_date_start = moment(dtSt).format('YYYY/MM/DD');
        }
      },
      onParkingChange: function () {
        if (this.screenData.yoyaku.parking_riyo == "1") {
          this.screenData.parking.disabledt = false;
          this.screenData.parking.disableno = false;
          var dtStOrg = "";
          if (this.screenData.screen.kaisai_date_start) {
            dtStOrg = this.screenData.screen.kaisai_date_start;
          }
          var dtEdOrg = "";
          if (this.screenData.screen.kaisai_date_end) {
            dtEdOrg = this.screenData.screen.kaisai_date_end;
          }
          var dtSt = moment(dtStOrg).isValid() ? moment(dtStOrg) : "";
          var dtEd = moment(dtEdOrg).isValid() ? moment(dtEdOrg) : "";
          if (dtSt == "" || dtEd == "") {
            return;
          }
          if (this.screenData.yoyaku.zenhaku_umu == "1") {
            dtSt = moment(dtStOrg).subtract(1, 'days');
          }
          this.screenData.yoyaku.parking_riyo_date_start = moment(dtSt).format('YYYY/MM/DD');
          this.screenData.yoyaku.parking_riyo_date_end = moment(dtEd).format('YYYY/MM/DD');
        }
        else {
          this.screenData.parking.disabledt = true;
          this.screenData.parking.disableno = true;
        }
      },
      setOldRequestData() {
        this.oldRequestData 
          = JSON.parse(JSON.stringify({
            zenhaku_umu: Number(this.screenData.yoyaku.zenhaku_umu),
            syku_type: this.screenData.yoyaku.syku_type,
            bus_riyo: Number(this.screenData.yoyaku.bus_riyo),
            parking_riyo: Number(this.screenData.yoyaku.parking_riyo),
            parking_riyo_date_start: this.screenData.yoyaku.parking_riyo_date_start,
            parking_riyo_date_end: this.screenData.yoyaku.parking_riyo_date_end,
            car_no_1: this.screenData.yoyaku.car_no_1,
            car_no_2: this.screenData.yoyaku.car_no_2,
            car_no_3: this.screenData.yoyaku.car_no_3,
            car_no_4: this.screenData.yoyaku.car_no_4,
            kinmusaki_tel: this.screenData.yoyaku.kinmusaki_tel,
            kinmusaki_tel_biko: this.screenData.yoyaku.kinmusaki_tel_biko,
            kinmusaki_fax: this.screenData.yoyaku.kinmusaki_fax,
          }))
      },
      checkInputChange() {
        var org = JSON.stringify(this.oldRequestData, jsonReplace);
        if (org === '{}') {
          return true;
        }
        var dest = JSON.stringify({
            zenhaku_umu: Number(this.screenData.yoyaku.zenhaku_umu),
            syku_type: this.screenData.yoyaku.syku_type,
            bus_riyo: Number(this.screenData.yoyaku.bus_riyo),
            parking_riyo: Number(this.screenData.yoyaku.parking_riyo),
            parking_riyo_date_start: this.screenData.yoyaku.parking_riyo_date_start,
            parking_riyo_date_end: this.screenData.yoyaku.parking_riyo_date_end,
            car_no_1: this.screenData.yoyaku.car_no_1,
            car_no_2: this.screenData.yoyaku.car_no_2,
            car_no_3: this.screenData.yoyaku.car_no_3,
            car_no_4: this.screenData.yoyaku.car_no_4,
            kinmusaki_tel: this.screenData.yoyaku.kinmusaki_tel,
            kinmusaki_tel_biko: this.screenData.yoyaku.kinmusaki_tel_biko,
            kinmusaki_fax: this.screenData.yoyaku.kinmusaki_fax,
        }, jsonReplace);
        return org === dest;
      },
    },
    beforeRouteLeave(to, from, next) {
      var input_check = this.checkInputChange();
      var answer = true;
      if (!input_check) {
        answer = window.confirm(this.$messages.MSG['I3005']);
      }
      if (answer) {
        next();
      } else {
        next(false);
      }
    },
  };
  function jsonReplace(key, val) {
    if(typeof val == "string" && val == ""){
      return null;
    }

    if(key == "parking_riyo_date_start" ||
       key == "parking_riyo_date_end") {
      if(moment(val).isValid()) {
        return moment(val).format('YYYYMMDD');
      }
    }

    return val;
  }
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3">
      <b-alert variant="danger" dismissible fade :show="errorStatus" @dismissed="errorStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">{{ error_message_no }} {{ error_message }}</div>
        <div class="col-md-12" style="white-space: pre !important" v-for="error in errors" :key="error.index">
          {{error.slice('')[0].split('/')[1]}} {{error.slice('')[0].split('/')[0]}}
        </div>
      </b-alert>
      <b-alert dismissible fade :show="successStatus" @dismissed="successStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="success_message">{{ success_message_no }} {{ success_message }}</div>
      </b-alert>

      <div class="container" style="margin-top: 20px">
        <div class="row progress_row">
          <div class="mainCell col-4 bg-info text-white text-center" style="border-right: 3px solid #fff">
            予約申込
          </div>
          <div class="subCell col-4 text-center" style="border-right: 3px solid #fff">
            予約確認
          </div>
          <div class="subCell col-4 text-center">予約完了</div>
        </div>
        <div class="row text-danger msg_area" style="margin-top: 30px"></div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            ※必要箇所をチェックしてください。<br />
            駐車場を利用する方は駐車場利用の「利用する」にチェックし車ナンバーを入力して下さい。
          </div>
        </div>
        <div id="mainTable" class="row" style="margin-top: 40px">
          <div class="container" style="padding: 0 30px;">
            <div class="row">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                研修期間
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                {{screenData.screen.kaisai_date}}
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary" style="height: auto">
                <div class="text-light text-center" style="line-height: 25px; margin: 0 auto">
                  宿泊室タイプ
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light">
                <div class="container" style="padding: 0px">
                  <div class="row table_height">
                    <div class="col-md-5 col-7">前日泊</div>
                    <div class="col-md-7 col-5 text-center">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="screenData.yoyaku.zenhaku_umu" :true-value='1' :false-value='0' :disabled="screenData.zenhakuDisable"
                          @change="onZenhakuChange" />
                        <label class="form-check-label" for="">有</label>
                      </div>
                    </div>
                  </div>
                  <div class="row row_border table_height">
                    <div class="col-md-5 col-7 pr-0">{{getSyukuhakuTypeName('A')}}</div>
                    <div v-if="isSykuTypeVisible('A') == false" class="col-md-7 col-5 text-danger text-center type-a">
                      {{sykuTypeText('A')}}
                    </div>
                    <div v-else-if="isSykuTypeVisible('A') == true" class="col-md-7 col-5 text-center type-c">
                      <input name="rooms_type" type="radio" value="A" v-model="screenData.yoyaku.syku_type" :disabled="isSykuTypeDisable('A')" :true-value='A' />
                    </div>
                  </div>


                  <div class="row row_border table_height">
                    <div class="col-md-5 col-7 pr-0">{{getSyukuhakuTypeName('B')}}</div>
                    <div v-if="isSykuTypeVisible('B') == false" class="col-md-7 col-5 text-danger text-center type-a">
                      {{sykuTypeText('B')}}
                    </div>
                    <div v-else-if="isSykuTypeVisible('B') == true" class="col-md-7 col-5 text-center type-c">
                      <input name="rooms_type" type="radio" value="B" v-model="screenData.yoyaku.syku_type" :disabled="isSykuTypeDisable('B')" :true-value='B' />
                    </div>
                  </div>

                  <div class="row row_border table_height">
                    <div class="col-md-5 col-7 pr-0">{{getSyukuhakuTypeName('C')}}</div>
                    <div v-if="isSykuTypeVisible('C') == false" class="col-md-7 col-5 text-danger text-center type-a">
                      {{sykuTypeText('C')}}
                    </div>
                    <div v-else-if="isSykuTypeVisible('C') == true" class="col-md-7 col-5 text-center type-c">
                      <input name="rooms_type" type="radio" value="C" v-model="screenData.yoyaku.syku_type" :disabled="isSykuTypeDisable('C')" :true-value='C' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                復路直通バス利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" name="chk_use_bus" type="checkbox" v-model="screenData.yoyaku.bus_riyo" :true-value='1' :false-value='0' />
                  <label class="form-check-label" for="">利用する</label>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                駐車場利用
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" name="chk_use_parking" type="checkbox" v-model="screenData.yoyaku.parking_riyo" :disabled="screenData.parking.disablecb" :true-value='1'
                    :false-value='0' @change="onParkingChange" />
                  <label class="form-check-label" for="">{{screenData.parking.text}}</label>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                <div style="line-height: 25px">
                  駐車場利用期間<br />
                  <span class="text-required">(駐車場利用者は必須)</span>
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light text-center">
                <div class="form-inline" style="height: 50px; max-width: 250px">
                  <datepicker :language="ja" :typeable="true" v-model="screenData.yoyaku.parking_riyo_date_start" format="yyyy/MM/dd" :disabled="screenData.parking.disabledt"></datepicker>
                  <span style="margin: 0px 5px">～</span>
                  <datepicker :language="ja" :typeable="true" v-model="screenData.yoyaku.parking_riyo_date_end" :calendar-class="calendar_right" format="yyyy/MM/dd"
                    :disabled="screenData.parking.disabledt"></datepicker>
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="mainCell col-md-4 col-12 bg-primary text-white text-center" style="line-height: 50px">
                <div style="line-height: 25px">
                  車のナンバー<br />
                  <span class="text-required">(駐車場利用者は必須)</span>
                </div>
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                <div class="form-inline" style="height: 50px; max-width: 400px">
                  <input type="text" class="form-control text-car-num" style="margin: 0" placeholder="品川" v-model="screenData.yoyaku.car_no_1" :disabled="screenData.parking.disableno" />
                  <input type="text" class="form-control text-car-num" placeholder="333" v-model="screenData.yoyaku.car_no_2" :disabled="screenData.parking.disableno" />
                  <input type="text" class="form-control text-car-num" placeholder="て" v-model="screenData.yoyaku.car_no_3" :disabled="screenData.parking.disableno" />
                  <input type="text" class="form-control text-car-num" tabindex="1" placeholder="8888" v-model="screenData.yoyaku.car_no_4" :disabled="screenData.parking.disableno" />
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                <div style="line-height: 25px">
                  勤務先TEL<br />
                  <span class="text-required">(※必須)</span>
                </div>
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                <div class="form-inline" style="height: 50px">
                  <input type="text" class="form-control bg-white" v-model="screenData.yoyaku.kinmusaki_tel" tabindex="1" placeholder="03-1234-5678" />
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                備考(勤務先TEL用)
              </div>
              <div class="
                  subCell
                  col-md-8 col-12
                  bg-light
                  text-center text-md-left
                  table_height
                ">
                <div class="form-inline" style="height: 50px;">
                  <input type="text" class="form-control bg-white" style="width: 100%;" v-model="screenData.yoyaku.kinmusaki_tel_biko" tabindex="1" placeholder="内線、呼出等" />
                </div>
              </div>
            </div>
            <div class="row row_border">
              <div class="
                  mainCell
                  col-md-4 col-12
                  bg-primary
                  text-white text-center
                  table_height
                ">
                <div style="line-height: 25px">
                  勤務先FAX<br />
                  <span class="text-required">(※必須)</span>
                </div>
              </div>
              <div class="subCell col-md-8 col-12 bg-light text-center text-md-left">
                <div class="form-inline table_height" style="height: 50px">
                  <input type="text" class="form-control bg-white" v-model="screenData.yoyaku.kinmusaki_fax" tabindex="1" placeholder="03-1234-5678" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin: 30px 0">
          <div style="max-width: 300px; margin: 0 auto">
            <button class="btn btn-primary mb-3" role="button" style="width: 140px" @click="check">
              次へ
            </button>

            <button class="btn btn-secondary mb-3" role="button" style="margin-left: 5px; width: 140px" @click="back">
              戻る
            </button>
          </div>
        </div>
      </div>
      <b-modal id="modal-ssregmsg" size="lg" title="宿泊室タイプの選択取消">
        <SSRegMsg ref="SSRegMsg"/>
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="white" class="btn-outline-secondary float-right shadow" style="width:100px;"
              @click="$bvModal.hide('modal-ssregmsg')">
              閉じる
            </b-button>
          </div>
        </template>
      </b-modal>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row_height {
    line-height: 50px;
  }

  .text-car-num {
    width: 60px !important;
    margin-left: 3px;
  }

  .text-required {
    color: #ff4342 !important;
  }

  .progress_row {
    margin-top: 10px;
    background-color: #eee;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
  }
</style>
<style>
  .vdp-datepicker input {
    width: 142px;
    height: 37.9972px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 4px;
    color: rgb(73, 80, 87);
  }

  .vdp-datepicker>div {
    display: flex;
    align-items: center;
  }

  .vdp-datepicker>div.vdp-datepicker__calendar {
    display: block;
    align-items: center;
  }

  div.calendar_right {
    right: 0%;
  }
</style>
