<script>
  import AppLayout from "@/components/layouts/Layout.vue";

  export default {
    name: "URLError",
    components: {
      AppLayout,
    },
    data() {
      return {
        error: null,
        URL: '',
      };
    },
    mounted() {
      this.URL = this.$route.params.URL;
    },
    methods: {
    }
  };
  </script>
<template>
  <AppLayout>
        <main class="contents-height pt-3">
      <div v-if="error" class="col-md-12 bg-err text-center err" style="white-space: pre !important" >{{ error }}</div>
      <div class="container text-left">
        <h4 class="font-weight-bold text-black">
          エラー：URLの情報を取得できませんでした。
        </h4>
        <h6>
          URLが正しいか、途中で途切れていないかをご確認のうえ、もう一度アクセスしてください。
        </h6>
        <h6 v-if="URL" >アクセスされたURL：{{URL}}
        </h6>
      </div>
    </main>

  </AppLayout>
</template>

