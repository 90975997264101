import Vue from "vue";
import Vuex from "vuex";

import TPortalModule from "./modules/TPortal.module";
import PPortalModule from "./modules/PPortal.module";
import PTantoRegistModule from "./modules/PTantoRegist.module";
import PKadaiModule from "./modules/PKadai.module";
import PZyukosyaRegistModule from "./modules/PZyukosyaRegist.module";
import PZyukosyaRegistDLModule from "./modules/PZyukosyaRegistDL.module";
import PtodokedeModule from "./modules/Ptodokede.module";
import SStayCheckModule from "./modules/SStayCheck.module";

import HeaderModule from "./modules/Header.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    TPortal: TPortalModule,
    PPortal: PPortalModule,
    PTantoRegist: PTantoRegistModule,
    PKadai: PKadaiModule,
    PZyukosyaRegist: PZyukosyaRegistModule,
    PZyukosyaRegistDL: PZyukosyaRegistDLModule,
    Ptodokede: PtodokedeModule,
    SStayCheck: SStayCheckModule,
    Header: HeaderModule
  },
  mutations: {},
  actions: {},
  plugins: [createPersistedState({storage: window.sessionStorage})],
});
