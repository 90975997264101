<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "vue-loading-overlay";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "KeyWordAuth",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      error: "",
      passFlg: true,
      login_id: this.$route.params.login_id,
      keyword: "",
      user_kbn: this.$route.query.user_kbn,
      url_cd: this.$route.query.url_cd,
      loginScreen: "",
      errors: [],
      error_message: '',
      error_message_no: '',
      errorStatus: false,
      successStatus: false,
      succsssMessageNo: '',
      successMessage: '',
      systemInitialDate: '',
      isLoading: false,
    };
  },
  mounted() {
    this.getSystemInitialDate();
    this.updateUserKbn();
  },
  methods: {
    updateUserKbn: function () {
      if (this.user_kbn) {
        localStorage.setItem("USER_KBN", this.user_kbn);
        switch (this.user_kbn) {
          case "1":
            this.loginScreen = "PLogin";
            break;
          case "2":
            this.loginScreen = "SLogin";
            break;
          case "3":
            this.loginScreen = "TLogin";
            break;
        }
      } else {
        this.loginScreen = "SLogin";
      }
    },
    clickKeywordAuth: function () {
      let param = {
        login_id: this.login_id,
        keyword: this.keyword,
        url_cd: this.url_cd,
        user_kbn: this.user_kbn,
        systemInitialDate: this.systemInitialDate,
      }
      apiService
        .KeyWordAuthProcess(param)
        .then((result) => {
          if (result.status == "success") {
            this.$router.push({ name: "KeyWordAuthSuccess",params: { user_kbn: this.user_kbn } });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorStatus = true;
          this.successStatus = false;
          this.errors = [];
          this.error_message ='';
          this.error_message_no ='';
          if(error.errors) {
            this.errors = error.errors;
          } else {
            this.error_message = error.message.replaceAll("|","\n");
            this.error_message_no = error.message_no;
          }
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
    },
    getSystemInitialDate() {
      apiService.GetSystemInitialDate()
        .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
            }
        })
        .catch(error => {
          console.log(error)
      });
    }
  },
};
</script>

<template>
  <AppLayout>
    <loading :active.sync="isLoading" :is-full-page="true">
      <div style="vertical-align: middle">
        <img
          src="@/assets/loader.gif"
          style="dipslay: inline-block; float: left"
        />
        <h4 style="dipslay: inline-block; float: left; color: #3399f3">
          読み込み中...
        </h4>
      </div>
    </loading>
    <main class="contents-height pt-sm-3">
      <div id="contentTop"></div>
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" v-if="successMessage">
          {{ succsssMessageNo }} {{ successMessage }}</div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" v-for="(value,index) in errors" :key="index">
          {{value.slice('')[0].split('/')[1]}} {{value.slice('')[0].split('/')[0]}}
        </div>
        <div class="col-md-12" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container text-center">
        <h4 class="font-weight-bold text-black">キーワード認証画面</h4>
      </div>
      <div class="container login-body rounded text-center">
        <h6 class="font-weight-normal text-black">
          IDとキーワードを入力してログインして下さい。
        </h6>
        <div class="form-signin">
          <label for="login-id" class="sr-only">ID</label>
          <input
            type="text"
            id="login-id"
            class="form-control"
            style="ime-mode: disabled"
            placeholder="ID"
            autofocus
            v-model="login_id"
          />
          <label for="password" class="sr-only">キーワード</label>
          <div v-if="passFlg" style="position: relative">
            <input
              type="text"
              id="password"
              class="form-control password"
              style="padding-right: 20px"
              placeholder="キーワード"
              v-model="keyword"
            />
          </div>
          <router-link
            :to="{ name: loginScreen }"
            class="font-weight-normal text-primary d-block text-right"
            style="font-size: 15px"
          >
            ログイン画面へ
          </router-link>
          <router-link
            :to="{
              name: 'KeyWordResend',
              params: { login_id: this.login_id, user_kbn: this.user_kbn },
            }"
            class="font-weight-normal text-primary d-block text-right"
            style="font-size: 15px"
          >
            キーワード再発行画面へ
          </router-link>
          <button
            class="btn btn-lg btn-block btn-outline-primary mt-3"
            @click="clickKeywordAuth()"
          >
            認証
          </button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
