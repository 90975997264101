<script>
import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();
import moment from 'moment';

export default {
  name: "PZyukosyaRegistDL",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      login_id: '',
      kaisai_no: '',
      kensyuName: '',
      kensyu_name: '',
      kaisai_name: '',
      kaisai_date: '',
      juko_teiin: '',
      tdfk_name: '',
      saishu_toroku_nichiji: '',
      registFlg: false,
      isLoading: false,
      fullPage: true,
      fields: [
        {key: 'selected', label: '選択'},
        {key: 'name',     label: '項目名', sortable: true},
      ],
      jyukosya: [],
      items: [
        { selected: "", col_name: "d_kensyu.kensyu_name", name: "研修名"},
        { selected: "", col_name: "d_kaisai.kaisai_name", name: "開催名"},
        { selected: "", col_name: "CONCAT(d_kaisai.kaisai_date_start , '(', m_meisyo1.name_1, ')', '~', d_kaisai.kaisai_date_end, '(', m_meisyo2.name_1, ')') as kaisai_date", name: "開催日"},
        { selected: "", col_name: "CONCAT(m_tdfk.tdfk_name,m_tosi.tosi_name,m_daigaku.daigaku_name) as tdfk_name", name: "推薦機関名"},
        { selected: "", col_name: "d_jyuko_user.jyuko_name", name: "受講者氏名"},
        { selected: "", col_name: "d_jyuko_user.jyuko_kana", name: "受講者フリガナ"},
        { selected: "", col_name: "d_jyuko_user.age", name: "年齢"},
        { selected: "", col_name: "m_meisyo3.rname", name: "性別"},
        { selected: "", col_name: "d_jyuko_user.shozoku_name", name: "所属-名称"},
        { selected: "", col_name: "d_jyuko_user.syoku_cd", name: "所属-職名１"},
        { selected: "", col_name: "d_jyuko_user.shozoku_syoku_2", name: "所属-職名２"},
        { selected: "", col_name: "d_jyuko_user.shozoku_post_no", name: "所属-郵便番号"},
        { selected: "", col_name: "d_jyuko_user.shozoku_address", name: "所属-住所"},
        { selected: "", col_name: "m_meisyo4.name_1", name: "所属-機関種別"},
      ],
      selected: [],
      errorStatus:false,
      error_message:'',
      error_message_no :'',
    };
  },
  mounted() {
    this.login_id = this.$store.state.Header.sessionData['login_id'];
    if(this.$route.params.kaisai_no){
      this.kaisai_no = this.$route.params.kaisai_no;
      this.$store.commit("PZyukosyaRegistDL/setKaisaiNo", this.kaisai_no);
    } else {
      this.kaisai_no = this.$store.state.PZyukosyaRegistDL.kaisai_no;
    }
    this.getJyukosyaInfo();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
  },
  methods: {
    getJyukosyaInfo() {
      this.isLoading = true;
      var start_date;
      var end_date;
      var request_data = {
        login_id: this.login_id,
        kaisai_no: this.kaisai_no
      }
      apiService.getJyukosyaData(request_data)
      .then(result => {
        if(result.status == "success")
        {
          this.kensyu_name = result.jyukosya_info[0]['kensyu_name'];
          this.kaisai_name = result.jyukosya_info[0]['kaisai_name']
          if(this.kaisai_name){
            this.kensyuName = this.kensyu_name + '　' + this.kaisai_name;
          }else {
            this.kensyuName = this.kensyu_name;
          }

          start_date = moment(result.jyukosya_info[0]['kaisai_date_start']).format('YYYY/MM/DD') + '('+ result.jyukosya_info[0]['start_day'] + ')';
          end_date = moment(result.jyukosya_info[0]['kaisai_date_end']).format('YYYY/MM/DD') + '('+ result.jyukosya_info[0]['end_day'] + ')';
          this.kaisai_date = start_date + ' ~ ' + end_date;

          if(result.jyukosya_info[0]['kaisai_jyuko_su'] == 0) {
            this.juko_teiin = result.jyukosya_info[0]['kensyu_jyuko_su'];
          } else {
            this.juko_teiin = result.jyukosya_info[0]['kaisai_jyuko_su'];
          }

          if(result.jyukosya_info[0]['suisen_name']) {
            this.tdfk_name = result.jyukosya_info[0]['tdfk_name'] + result.jyukosya_info[0]['suisen_name'];
          } else {
            this.tdfk_name = result.jyukosya_info[0]['tdfk_name'];
          }

          if(result.jyukosya_info[0]['renewal_date']){
            this.saishu_toroku_nichiji = moment(result.jyukosya_info[0]['renewal_date']).format('YYYY/MM/DD HH:mm');
          }
          else{
            this.saishu_toroku_nichiji = '－';
          }

          if(result.jyukosya_info[0]['item_meisyo_01']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_01', name: result.jyukosya_info[0]['item_meisyo_01']});
          }
          if(result.jyukosya_info[0]['item_meisyo_02']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_02', name: result.jyukosya_info[0]['item_meisyo_02']});
          }
          if(result.jyukosya_info[0]['item_meisyo_03']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_03', name: result.jyukosya_info[0]['item_meisyo_03']});
          }
          if(result.jyukosya_info[0]['item_meisyo_04']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_04', name: result.jyukosya_info[0]['item_meisyo_04']});
          }
          if(result.jyukosya_info[0]['item_meisyo_05']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_05', name: result.jyukosya_info[0]['item_meisyo_05']});
          }
          if(result.jyukosya_info[0]['item_meisyo_06']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_06', name: result.jyukosya_info[0]['item_meisyo_06']});
          }
          if(result.jyukosya_info[0]['item_meisyo_07']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_07', name: result.jyukosya_info[0]['item_meisyo_07']});
          }
          if(result.jyukosya_info[0]['item_meisyo_08']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_08', name: result.jyukosya_info[0]['item_meisyo_08']});
          }
          if(result.jyukosya_info[0]['item_meisyo_09']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_09', name: result.jyukosya_info[0]['item_meisyo_09']});
          }
          if(result.jyukosya_info[0]['item_meisyo_10']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_10', name: result.jyukosya_info[0]['item_meisyo_10']});
          }
          if(result.jyukosya_info[0]['item_meisyo_11']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_11', name: result.jyukosya_info[0]['item_meisyo_11']});
          }
          if(result.jyukosya_info[0]['item_meisyo_12']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_12', name: result.jyukosya_info[0]['item_meisyo_12']});
          }
          if(result.jyukosya_info[0]['item_meisyo_13']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_13', name: result.jyukosya_info[0]['item_meisyo_13']});
          }
          if(result.jyukosya_info[0]['item_meisyo_14']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_14', name: result.jyukosya_info[0]['item_meisyo_14']});
          }
          if(result.jyukosya_info[0]['item_meisyo_15']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_15', name: result.jyukosya_info[0]['item_meisyo_15']});
          }
          if(result.jyukosya_info[0]['item_meisyo_16']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_16', name: result.jyukosya_info[0]['item_meisyo_16']});
          }
          if(result.jyukosya_info[0]['item_meisyo_17']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_17', name: result.jyukosya_info[0]['item_meisyo_17']});
          }
          if(result.jyukosya_info[0]['item_meisyo_18']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_18', name: result.jyukosya_info[0]['item_meisyo_18']});
          }
          if(result.jyukosya_info[0]['item_meisyo_19']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_19', name: result.jyukosya_info[0]['item_meisyo_19']});
          }
          if(result.jyukosya_info[0]['item_meisyo_20']) {
            this.items.push({ selected: '', col_name: 'd_jyuko_user.item_20', name: result.jyukosya_info[0]['item_meisyo_20']});
          }
          this.isLoading = false;
        }else {
          this.isLoading = false;
        }
      })
      .catch(error => {
        this.errorStatus = true;
        this.error_message = error.message.replaceAll("|","\n");
        this.error_message_no = error.message_no;
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
    blogErrorMessages(error){
      console.log(error)
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      ) {
          errormessage = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              error.data = JSON.parse(reader.result);
              resolve(Promise.reject(error));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.data);
          })
          .then(err => {
            console.log(err.data)
          });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        if(error.data.status=='error'){
            this.errorStatus = true;
            this.error_message = error.data.message;
            this.error_message_no = error.data.message_no;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      });
    },
    exportCSV() {
      this.isLoading = true;
      var selected_array = [];
      var header_name = [];
      var $index = 0;
      this.selected.forEach(function(data) {
        selected_array[$index] = data.col_name;
        header_name[$index] = data.name;
        $index++;
      });
      var selected_name = selected_array.join(',');

      if(this.selected.length == 0) {
        this.errorStatus = true;
        this.error_message = this.$messages.MSG['E1054'];
        this.error_message_no = 'E1054';
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      } else {
        var request_data = {
          login_id: this.login_id,
          kaisai_no: this.kaisai_no,
          selected: selected_name,
          header_name:header_name
        }
        apiService.exportJyukosyaData(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          this.downloadCSV(result,'推薦済研修受講者一覧_' +  date + '.csv');
        }).catch(error => {
          this.blogErrorMessages(error);
        });
      }
    },
    downloadCSV(result, fileName) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
  },
  updated: function ()  {
    if(this.errorStatus == false) {
      this.selectAllRows();
    }
  }
};
</script>
<template>
  <AppLayout>
  <Loading :isLoading='isLoading' :fullPage='fullPage' />
  <main class="mb-3 overflow-hidden">
    <div id="contentTop"></div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="errorStatus"
      @dismissed="errorStatus=false"
      >
      <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
        {{ error_message_no }} {{ error_message }}
      </div>
    </b-alert>
    <div class="container" style="max-width: 90%;">
      <h4 class="mt-3">推薦済研修受講者一覧CSV出力</h4>
      <div id="mainTable">
        <div class="row">
          <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
          <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ kensyuName }}</div>
        </div>
        <div class="row row_border">
          <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">開催日</div>
          <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ kaisai_date }}</div>
          <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">受講定員</div>
          <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ juko_teiin }}</div>
        </div>
        <div class="row row_border">
          <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">推薦機関名</div>
          <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ tdfk_name }}</div>
          <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">最終登録日時</div>
          <div class="subCell col-md-4 col-12 bg-light text-md-left table_height">{{ saishu_toroku_nichiji }}</div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6 float-md-left text-center text-md-left p-0 mb-2">
          <button class="btn btn-outline-primary shadow" style="width:80px" @click="selectAllRows()">全選択</button>
          <button class="btn btn-outline-secondary shadow ml-2" style="width:80px;"  @click="clearSelected()">解除</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 p-0">
          <b-table
            bordered
            hover
            sticky-header="400px"
            :items="items"
            :fields="fields"
            head-variant="dark"
            striped
            small
            responsive
            selectable
            :dark="isDark"
            ref="selectableTable"
            select-mode="multi"
            @row-selected="onRowSelected"
            show-empty
            :empty-text="$messages.MSG['I0008']"
          >
            <template #empty="scope">{{ scope.emptyText }}</template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row text-center text-md-right mb-2">
        <div class="col-12 col-md-6 p-0">
          <button
            class="btn btn-outline-primary shadow d-inline-block"
            type="button"
            style="width:100px"
            @click="exportCSV()"
          >
            CSV出力
          </button>
          <router-link
            class="btn btn-outline-secondary shadow d-inline-block ml-2"
            style="width:100px"
            :to="{ name: 'PZyukosyaRegist', params: { kaisai_no: kaisai_no } }"
          >
            戻る
          </router-link>
        </div>
      </div>
    </div>
  </main>
  </AppLayout>
</template>
<style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  </style>
