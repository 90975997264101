import Vue from "vue";
import VueRouter from "vue-router";
import KeyWordAuthSuccess from "./pages/KwAuthSuccess/index.vue";
import KeyWordAuth from "./pages/KeyWordAuth/index.vue";
import KeyWordResend from "./pages/KeyWordResend/index.vue";
import PassWordResend from "./pages/PassWordResend/index.vue";
import PLogin from "./pages/PLogin/index.vue";
import SLogin from "./pages/SLogin/index.vue";
import TLogin from "./pages/TLogin/index.vue";
import PPortal from "./pages/PPortal/index.vue";
import SPortal from "./pages/SPortal/index.vue";
import TPortal from "./pages/TPortal/index.vue";
import PTantoRegist from "./pages/PTantoRegist/index.vue";
import TKousiRegist from "./pages/TKousiRegist/index.vue";
import PZyukosyaRegist from "./pages/PZyukosyaRegist/index.vue";
import PZyukosyaRegistDL from "./pages/PZyukosyaRegistDL/index.vue";
import AUTHERROR from "./pages/common/error_auth.vue";
import SSTAYMANUAL from "./pages/SStayManual/index.vue";
import SSTAYREGIST from "./pages/SStayRegist/index.vue";
import SSTAYCHECK from "./pages/SStayCheck/index.vue";
import SSTAYPRINT from "./pages/SStayPrint/index.vue";
import CError from "./pages/CError/index.vue";
import SENQUETEKENSYU from "./pages/SEnqueteKensyu/index.vue";
import SENQUETEKOZA from "./pages/SEnqueteKoza/index.vue";
import SKADAI from "./pages/SKadai/index.vue";
import PKADAI from "./pages/PKadai/index.vue";
import TKADAI from "./pages/TKadai/index.vue";
import TYOSIKI from "./pages/TYosiki/index.vue";
import PCREATEACCOUNT from "./pages/PCreateAccount/index.vue";
import PCREATEACSUISEN from "./pages/PCreateAcSuisen/index.vue";
import URLERROR from "./pages/URLError/index.vue";
import SjyukoRegist from "./pages/SjyukoRegist/index.vue";
import Ptodokede from './pages/Ptodokede/index.vue';

Vue.use(VueRouter);

// 推薦担当者
const USER_KBN_P = 1;
// 受講者
const USER_KBN_S = 2;
// 講師
const USER_KBN_T = 3;

// ベースページタイトル
const BASE_TITLE = 'NITS 研修システム';

const routes = [
  {
    path: "/KeyWordAuth",
    name: "KeyWordAuth",
    component: KeyWordAuth,
    meta: {
      noNeedAuth: true,
      title: 'キーワード認証',
    },
  },
  {
    path: "/KeyWordAuthSuccess",
    name: "KeyWordAuthSuccess",
    component: KeyWordAuthSuccess,
    meta: {
      noNeedAuth: true,
      title: 'キーワード認証成功',
    },
  },
  {
    path: "/KeyWordResend",
    name: "KeyWordResend",
    component: KeyWordResend,
    meta: {
      noNeedAuth: true,
      title: 'キーワード再発行',
    },
  },
  {
    path: "/PassWordResend",
    name: "PassWordResend",
    component: PassWordResend,
    meta: {
      noNeedAuth: true,
      title: 'パスワード再発行',
    },
  },
  {
    path: "/",
    name: "SLogin",
    component: SLogin,
    meta: {
      user_kbn: USER_KBN_S,
      noNeedAuth: true,
      title: '受講者ログイン',
    },
  },
  {
    path: "/PLogin",
    name: "PLogin",
    component: PLogin,
    meta: {
      user_kbn: USER_KBN_P,
      noNeedAuth: true,
      title: '推薦担当者ログイン',
    },
  },
  {
    path: "/TLogin",
    name: "TLogin",
    component: TLogin,
    meta: {
      user_kbn: USER_KBN_T,
      noNeedAuth: true,
      title: '講師ログイン',
    },
  },
  {
    path: "/PPortal",
    name: "PPortal",
    component: PPortal,
    meta: {
      user_kbn: USER_KBN_P,
      title: '推薦担当者ポータル',
    },
  },
  {
    path: "/SPortal",
    name: "SPortal",
    component: SPortal,
    meta: {
      user_kbn: USER_KBN_S,
      title: '受講者ポータル',
    },
  },
  {
    path: "/SKadai",
    name: "SKadai",
    component: SKADAI,
    meta: {
      user_kbn: USER_KBN_S,
      title: '受講者課題提出',
    },
  },
  {
    path: "/TPortal",
    name: "TPortal",
    component: TPortal,
    meta: {
      user_kbn: USER_KBN_T,
      title: '講師ポータル',
    },
  },
  {
    path: "/PTantoRegist",
    name: "PTantoRegist",
    component: PTantoRegist,
    meta: {
      user_kbn: USER_KBN_P,
      title: '推薦担当者登録',
    },
  },
  {
    path: "/TKousiRegist",
    name: "TKousiRegist",
    component: TKousiRegist,
    meta: {
      user_kbn: USER_KBN_T,
      title: '講師登録',
    },
  },
  {
    path: "/PZyukosyaRegist",
    name: "PZyukosyaRegist",
    component: PZyukosyaRegist,
    meta: {
      user_kbn: USER_KBN_P,
      title: '研修受講者推薦',
    },
  },
  {
    path: "/PZyukosyaRegistDL",
    name: "PZyukosyaRegistDL",
    component: PZyukosyaRegistDL,
    props: true,
    meta: {
      user_kbn: USER_KBN_P,
      title: '推薦済研修受講者一覧CSV出力',
    },
  },
  {
    path: "/PKadai",
    name: "PKadai",
    component: PKADAI,
    meta: {
      user_kbn: USER_KBN_P,
      title: '課題ファイル管理',
    },
  },
  {
    path: "/autherror",
    name: "AUTHERROR",
    component: AUTHERROR,
    meta: {
      requiresAuth: true,
      title: 'エラー',
    },
  },
  {
    path: "/sstaymanual",
    name: "SSTAYMANUAL",
    component: SSTAYMANUAL,
    meta: {
      user_kbn: USER_KBN_S,
      title: '施設予約利用案内',
    },
  },
  {
    path: "/sstayregist",
    name: "SSTAYREGIST",
    component: SSTAYREGIST,
    meta: {
      user_kbn: USER_KBN_S,
      title: '施設予約申込',
    },
  },
  {
    path: "/sstaycheck",
    name: "SSTAYCHECK",
    component: SSTAYCHECK,
    meta: {
      user_kbn: USER_KBN_S,
      title: '施設予約確認',
    },
    props: true
  },
  {
    path: "/sstayprint",
    name: "SSTAYPRINT",
    component: SSTAYPRINT,
    meta: {
      user_kbn: USER_KBN_S,
      title: '施設予約完了照会',
    },
  },
  {
    path: "/CError",
    name: "CError",
    component: CError,
    meta: {
      noNeedAuth: true,
      title: 'エラー',
    },
  },
  {
    path: "/senquetekensyu",
    name: "SENQUETEKENSYU",
    component: SENQUETEKENSYU,
    meta: {
      user_kbn: USER_KBN_S,
      title: '研修全般アンケート入力',
    },
  },
  {
    path: "/senquetekoza",
    name: "SENQUETEKOZA",
    component: SENQUETEKOZA,
    meta: {
      user_kbn: USER_KBN_S,
      title: '講座アンケート入力',
    },
  },
  {
    path: "/Tkadai",
    name: "TKADAI",
    component: TKADAI,
    meta: {
      user_kbn: USER_KBN_T,
      title: '講師課題参照',
    },
  },
  {
    path: "/tyosiki",
    name: "TYOSIKI",
    component: TYOSIKI,
    meta: {
      user_kbn: USER_KBN_T,
      title: '講師様式回答',
    },
  },
  {
    path: "/PCreateAccount",
    name: "PCreateAccount",
    component: PCREATEACCOUNT,
    meta: {
      noNeedAuth: true,
      title: '推薦担当者アカウント発行画面',
    },
  },
  {
    path: "/PCreateAcSuisen",
    name: "PCreateAcSuisen",
    component: PCREATEACSUISEN,
    meta: {
      noNeedAuth: true,
      title: '推薦担当者アカウント発行画面',
    },
  },
  {
    path: "/URLError",
    name: "URLError",
    component: URLERROR,
    meta: {
      noNeedAuth: true,
      title: 'エラー画面',
    },
  },
  {
    path: "/SjyukoRegist",
    name: "SjyukoRegist",
    component: SjyukoRegist,
    meta: {
      user_kbn: USER_KBN_S,
      title: '受講者登録',
    },
  },
  {
    path: '/Ptodokede',
    name: 'Ptodokede',
    component: Ptodokede,
    meta: {
      user_kbn: USER_KBN_P,
      title: '届出提出',
    },
    props: true
  }
];


const router = new VueRouter({
  mode: "hash",
  routes
});

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = BASE_TITLE + ' | ' + to.meta.title;
  } else {
    document.title = BASE_TITLE
  }
  // 認証不要の画面
  if (to.meta.noNeedAuth) {
    if (!localStorage.getItem('AUTH_TOKEN')) {
      next();
      return;
    }
    localStorage.clear();
    next();
  } else {
    if (localStorage.getItem("AUTH_TOKEN")) {
      next();
    } else {
      if (localStorage.getItem("USER_KBN") == USER_KBN_P) {
        next({ name: "PLogin" , params: { UnAuth_flg: '1' }});
      } else if (localStorage.getItem("USER_KBN") == USER_KBN_T) {
        next({ name: "TLogin" , params: { UnAuth_flg: '1' }});
      } else {
        next({ name: "SLogin" , params: { UnAuth_flg: '1' }});
      }
    }
  }
});

export default router;
