<script>
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();

  export default {
    name: "Header",
    data() {
      return {
        mainTable: null,
        bTable: null,
        mainCell: null,
        subCell: null,
        warnCell: null,
        infoFlag: false,
        warningFlag: false,
        containerFlag: false,
        filename: null,
        kubun: null,
      };
    },
    beforeMount() {
      if (this.$store.state.Header.fontSize === "secondary") {
        document.body.style.fontSize = "16px";
      } else if (this.$store.state.Header.fontSize === "outline-secondary") {
        document.body.style.fontSize = "18px";
      }

      if (this.$store.state.Header.backGroundColor === "secondary") {
        document.body.style.background = "white";
        document.body.style.color = "black";
      } else if (this.$store.state.Header.backGroundColor === "outline-secondary") {
        document.body.style.background = "black";
        document.body.style.color = "white";
      }
    },
    mounted() {
      this.mainTable = document.getElementById("mainTable");
      this.mainCell = document.getElementsByClassName("mainCell");
      this.subCell = document.getElementsByClassName("subCell");
      this.warnCell = document.getElementsByClassName("warnCell");
      this.mainContainer = document.getElementsByClassName("mainContainer");
      if (this.warnCell.length > 0) {
        this.warningFlag = true;
      }
      if (this.mainContainer.length > 0) {
        this.containerFlag = true;
      }
      if (this.$store.state.Header.backGroundColor === "secondary") {
        this.changeWhiteBackground();
        if (this.mainTable) {
          this.mainTable.style.color = "black";
        }
      } else if (this.$store.state.Header.backGroundColor === "outline-secondary") {
        this.changeBlackBackground();
        if (this.mainTable) {
          this.mainTable.style.color = "white";
        }
      }
    },
    methods: {
      logout() {
        if (this.$route.meta.user_kbn == this.$constants.USER_KBN_P) {
          this.$router.push({ name: "PLogin" });
        } else if (this.$route.meta.user_kbn == this.$constants.USER_KBN_S) {
          this.$router.push({ name: "SLogin" });
        } else {
          this.$router.push({ name: "TLogin" });
        }
      },
      normalFontSize() {
        document.body.style.fontSize = "16px";
        this.$store.commit("Header/setFontSize", "secondary");
      },
      expandFontSize() {
        document.body.style.fontSize = "18px";
        this.$store.commit("Header/setFontSize", "outline-secondary");
      },
      changeWhiteBackground() {
        document.body.style.background = "white";
        document.body.style.color = "black";
        if (this.mainTable) {
          this.mainTable.style.color = "black";
          for (var i = 0; i < this.mainCell.length; i++) {
            if (this.infoFlag == true) {
              this.mainCell[i].classList.remove("bg-primary");
              this.mainCell[i].classList.add("bg-info");
              this.infoFlag = false;
            } else {
              this.mainCell[i].classList.remove("bg-darkGray");
              this.mainCell[i].classList.add("bg-primary");
            }
          }
          for (var j = 0; j < this.subCell.length; j++) {
            this.subCell[j].classList.remove("bg-dark");
            this.subCell[j].classList.add("bg-light");
          }
          if (this.warningFlag == true) {
            for (var k = 0; k < this.warnCell.length; k++) {
              this.warnCell[k].classList.remove("bg-darkBlue");
              this.warnCell[k].classList.add("bg-warning");
            }
          }
          if (this.containerFlag == true) {
            this.mainContainer[0].classList.remove("bg-darkGray");
          }
        }

        this.$store.commit("Header/setBackGroundColor", "secondary");
      },
      changeBlackBackground() {
        document.body.style.background = "black";
        document.body.style.color = "white";
        if (this.mainTable) {
          this.mainTable.style.color = "white";
          for (var i = 0; i < this.mainCell.length; i++) {
            this.mainCell[i].classList.remove("bg-primary");
            if (this.mainCell[i].classList.contains("bg-info")) {
              this.mainCell[i].classList.remove("bg-info");
              this.mainCell[i].classList.add("bg-blue");
              this.infoFlag = true;
            } else {
              this.mainCell[i].classList.add("bg-darkGray");
            }
          }
          for (var j = 0; j < this.subCell.length; j++) {
            this.subCell[j].classList.remove("bg-light");
            this.subCell[j].classList.add("bg-dark");
          }
        }
        if (this.warningFlag == true) {
          for(var k = 0; k < this.warnCell.length; k++) {
            this.warnCell[k].classList.remove("bg-warning");
            this.warnCell[k].classList.add("bg-darkBlue");
          }
        }
        if (this.containerFlag == true) {
            this.mainContainer[0].classList.add("bg-darkGray");
        }

        this.$store.commit("Header/setBackGroundColor", "outline-secondary");
      },
      downloadPdf(result, fileName) {
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(new Blob([result]), fileName);
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      },
      downloadManual(targetFile) {
        var request_data = {
          kubun: targetFile,
        };
        apiService.HeaderexportManual(request_data)
          .then(result => {
            if(request_data != null) {
              switch (request_data['kubun']) {
                  case '0':
                      this.filename = "推薦担当者用マニュアル.pdf";
                      break;

                  case '1':
                      this.filename = "講師用マニュアル.pdf";
                      break;

                  case '2':
                      this.filename = "受講者用マニュアル.pdf";
                      break;

                  default:
                      return;
              }
            }
            this.downloadPdf(result, this.filename);
          })
          .catch(error => {
            this.blogErrorMessages(error, this.filename);
          });
      },
      blogErrorMessages(error, fieldName){
        let errormessage;
        if (
          error.request.responseType === 'blob' &&
          error.data instanceof Blob &&
          error.data.type &&
          error.data.type.toLowerCase().indexOf('json') != -1
        ) {
            errormessage = new Promise((resolve, reject) => {
              let reader = new FileReader();
              reader.onload = () => {
                error.data = JSON.parse(reader.result);
                resolve(Promise.reject(error));
              };
              reader.onerror = () => {
                reject(error);
              };
              reader.readAsText(error.data);
            })
            .then(err => {
              console.log(err.data)
            });
        }
        errormessage.then(success=>{
          console.log(success)
        }).catch(error=>{
           console.log(error.data)
          if (error.data.status == 'error') {
            this.errorStatus = true;
            this.errorMessage = error.data.message;
            this.errorNo = error.data.message_no;
            this.successStatus = false;
          }
          if (error.status == 500) {
            this.errorStatus = true;
            this.errorMessage = fieldName + this.$messages.MSG['E0035'];
            this.errorNo = 'E0035';
            this.successStatus = false;
          }
        });
      }
    },
    computed: {
      navHead: function () {
        if (this.$route.meta.user_kbn == undefined) {
          return "nav-head-blue py-0";
        }
        if (this.$route.meta.user_kbn == this.$constants.USER_KBN_P) {
          return "nav-head-orange py-0";
        } else if (this.$route.meta.user_kbn == this.$constants.USER_KBN_S) {
          return "nav-head-blue py-0";
        } else {
          return "nav-head-green py-0";
        }
      },
      userName() {
        return this.$store.state.Header.loginUserName;
      },
      logoImage() {
        return this.$store.state.Header.backGroundColor === "secondary" ? require("@/assets/logo_black.png") : require("@/assets/logo_white.png");
      },
      headerStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "background-color: #fff !important;" : "background-color: #000 !important;";
      },
      headerMenuStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "color: #000 !important;" : "color: #fff !important;";
      },
      fontSizeVariant1() {
        return this.$store.state.Header.fontSize === "secondary" ? "secondary" : "outline-secondary";
      },
      fontSizeVariant2() {
        return this.$store.state.Header.fontSize === "secondary" ? "outline-secondary" : "secondary";
      },
      backgroundVariant1() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "secondary" : "outline-secondary";
      },
      backgroundVariant2() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "outline-secondary" : "secondary";
      },
      headerDropdownBtnStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "outline-primary" : "outline-secondary";
      },
      headerDropdownListStyle() {
        return this.$store.state.Header.backGroundColor === "secondary" ? "light" : "dark";
      },
    },
  };
</script>
<template>
  <header class="header-height" :style="headerStyle">
    <b-navbar toggleable="lg" align="left" :class="navHead">
      <div class="container-xl">
        <b-row align-v="end" class="m-0" style="width: 100%">
          <b-col cols="12" lg="4" class="text-left">
            <img :src="logoImage" alt="独立行政法人教職員支援機構ロゴ画像" class="d-inline" style="height: 95px" />
          </b-col>
          <b-col v-if="!this.$route.meta.noNeedAuth" cols="12" lg="8">
            <b-navbar-toggle target="nav-collapse" class="mb-2"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto" style="width: 100%">
                <b-row class="m-0" style="width: 100%">
                  <b-col cols="12" class="p-0 text-right mb-2">
                    文字サイズ
                    <b-button @click="normalFontSize" :variant="fontSizeVariant1">標準</b-button>
                    <b-button @click="expandFontSize" :variant="fontSizeVariant2" class="m-1">拡大</b-button>
                    <span>
                      背景色変更
                    </span>
                    <b-button id="bgButton" @click="changeWhiteBackground" :variant="backgroundVariant1">白</b-button>
                    <b-button @click="changeBlackBackground" :variant="backgroundVariant2" class="m-1">黒</b-button>
                    <b-dropdown id="dropdown-1" :variant="headerDropdownBtnStyle" :menu-class="headerDropdownListStyle">
                      <template #button-content>
                        <b-icon icon="person-fill"></b-icon>
                        <span>{{ userName }}</span>
                      </template>
                      <template v-if="
                          this.$route.meta.user_kbn ==
                          this.$constants.USER_KBN_P
                        ">
                        <b-dropdown-item :to="{ name: 'PTantoRegist', params: { login_id: this.$store.state.Header.sessionData['login_id'], account_flg: '0' } }">推薦担当者編集</b-dropdown-item>
                        <b-dropdown-item @click="downloadManual('0')">
                          マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
                      </template>
                      <template v-if="
                          this.$route.meta.user_kbn ==
                          this.$constants.USER_KBN_S
                        ">
                        <b-dropdown-item :to="{ name: 'SjyukoRegist' }">受講者編集</b-dropdown-item>
                        <b-dropdown-item @click="downloadManual('2')">
                          マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
                      </template>
                      <template v-if="
                          this.$route.meta.user_kbn ==
                          this.$constants.USER_KBN_T
                        ">
                        <b-dropdown-item :to="{ name: 'TKousiRegist' }">講師編集</b-dropdown-item>
                        <b-dropdown-item @click="downloadManual('1')">
                          マニュアル<b-icon icon="download" class="ml-2"></b-icon>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout">ログアウト</b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-col>
                  <template v-if="
                      this.$route.meta.user_kbn == this.$constants.USER_KBN_S &&
                      this.$store.state.Header.registFlg == '1'
                    ">
                    <b-col md="12" lg="2" class="p-0 text-right text-lg-center">
                      <b-nav-item :to="{ name: 'SKadai' }"><span :style="headerMenuStyle">課題</span></b-nav-item>
                    </b-col>
                    <b-col md="12" lg="3" class="p-0 text-right text-lg-center">
                      <b-nav-item :to="{ name: 'SSTAYMANUAL' }"><span :style="headerMenuStyle">施設予約</span></b-nav-item>
                    </b-col>
                    <b-col md="12" lg="3" class="p-0 text-right text-lg-center">
                      <b-nav-item :to="{ name: 'SENQUETEKOZA' }"><span :style="headerMenuStyle">講座アンケート</span></b-nav-item>
                    </b-col>
                    <b-col md="12" lg="4" class="p-0 text-right text-lg-center">
                      <b-nav-item :to="{ name: 'SENQUETEKENSYU' }"><span :style="headerMenuStyle">研修全般アンケート</span></b-nav-item>
                    </b-col>
                  </template>
                </b-row>
              </b-navbar-nav>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
    </b-navbar>
  </header>
</template>

<style>
  .nav-head-orange {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(243, 152, 1);
  }

  .nav-head-blue {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(1, 99, 234);
  }

  .nav-head-green {
    border-bottom: solid 1px #aaaaaa;
    border-top: 10px solid rgb(18, 181, 176);
  }

  .nav-link {
    color: black !important;
    text-decoration: underline !important;
  }

  .nav-link:hover {
    color: blue !important;
  }
</style>
q
