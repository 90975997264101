<script>
import AppLayout from "@/components/layouts/Layout.vue";

export default {
  name: "CError",
  data() {
    return {
      error: null,
    };
  },
  components: {
    AppLayout,
  },
};
</script>

<template>
  <AppLayout>
    <main class="contents-height pt-3">
      <div v-if="error" class="col-md-12 bg-err text-center err" style="white-space: pre !important" >{{ error }}</div>
      <div class="container text-left">
        <h4 class="font-weight-bold text-black">
          システムエラーです。
        </h4>
        <h6>
          申し訳ありませんが、ログイン画面からやり直して下さい。
        </h6>
      </div>
    </main>
  </AppLayout>
</template>
