<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import moment from 'moment';
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "Ptodokede",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      nendo: '',
      kensyu_cd: '',
      kaisai_no: '',
      headersection: [],
      fields: [
        {
          key: "no",
          label: "受講者番号",
          class: "text-nowrap text-center",
        },
        {
          key: "jukousyaname",
          label: "受講者名",
          sortable: false,
          thClass: "text-center",
          class: "text-nowrap",
        },
        {
          key: "syubetu",
          label: "届出種別",
          sortable: false,
          thClass: "text-center",
          class: "text-nowrap",
        },
        {
          key: "file",
          label: "届出",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-left text-nowrap file-width",
        },
        {
          key: "torokudate",
          label: "登録日",
          sortable: false,
          class: "text-center text-nowrap ",
        },
        {
          key: "login_id",
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: "tdfk_cd",
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: "tosi_cd",
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: "daigaku_cd",
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: "meisai_no",
          thClass: 'd-none',
          tdClass: 'd-none'
        },
      ],
      isLoading: false,
      fullPage: true,
      items: [],
      import_file:'',
      inputFileLabel :'ファイルを選択してください。',
      yousiki_file_path: '',
      success_message :'',
      success_message_no :'',
      jyuko_list: [],
      list_info: [],
      uploadFileSize: "",
      successStatus:false,
      errorStatus: false,
      error_message: '',
      error_no: '',
      viewKensyuName: "",
    };
  },
  mounted() {
    if(this.$route.params.nendo){
      this.nendo = this.$route.params.nendo;
      this.kensyu_cd = this.$route.params.kensyu_cd;
      this.kaisai_no = this.$route.params.kaisai_no;

      this.$store.commit("Ptodokede/setNendo", this.nendo);
      this.$store.commit("Ptodokede/setKensyuCd", this.kensyu_cd);
      this.$store.commit("Ptodokede/setKaisaiNo", this.kaisai_no);
    } else {
      this.nendo = this.$store.state.Ptodokede.nendo;
      this.kensyu_cd = this.$store.state.Ptodokede.kensyu_cd;
      this.kaisai_no = this.$store.state.Ptodokede.kaisai_no;
    }
    this.getHeaderSection();
    this.getJyukoshaTodokede();
  },
  computed: {
    isDark () {
        return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
    Btn_LightDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? "btn btn-outline-secondary-light shadow ml-1" : "btn btn-outline-secondary-dark shadow ml-1";
    },
  },
  methods: {
    getHeaderSection() {
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
      };
      apiService
        .PTodokedeGetHeaderSection(request_data)
        .then((result) => {
          this.headersection = result.result[0];
          if(this.headersection.kaisai_name){
            this.viewKensyuName = this.headersection.kensyu_name + '　' + this.headersection.kaisai_name;
          }else {
            this.viewKensyuName = this.headersection.kensyu_name;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getJyukoshaTodokede() {
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        tdfk_cd: '',
        tosi_cd: '',
        daigaku_cd: '',
        meisai_no: '',
      };
      apiService.getJyukoshaTodokede(request_data)
      .then(result => {
          if (result.status == "success") {
            this.list_info = result.jyukoshatodokede;
            let i = 0;
            for (i = 0; i < this.list_info.length; i++) {
              if(this.list_info[i].torokudate != null){
                this.list_info[i].torokudate = moment(this.list_info[i].torokudate).format('YYYY/MM/DD HH:mm');
              }
              if (this.list_info[i].file == null) {
                this.list_info[i].Disabled_input_flg = false;
                this.list_info[i].Disabled_Upload_flg = true;
                this.list_info[i].BtnChange_flg = true;
                this.list_info[i].Disabled_Del_flg = true;
                this.list_info[i].import_file = '';
                this.list_info[i].index_no = i;
              } else {
                this.list_info[i].Disabled_input_flg = true;
                this.list_info[i].Disabled_Upload_flg = false;
                this.list_info[i].BtnChange_flg = false;
                this.list_info[i].Disabled_Del_flg = false;
                this.list_info[i].import_file = '';
                this.list_info[i].index_no = i;
              }
            }
            this.uploadFileSize = result.upload_file_size;
          }
          this.isLoading = false;
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false;
      });
    },
    yousikiDownload(file_name) {
      this.isLoading = true;
      var request_data = {
        file_name: file_name
      };
      apiService.yousikiDownload(request_data).then(result => {
        this.download(result,'Ptodokede_' + file_name + '.docx');
      }).catch(error => {
        this.blogErrorMessages(error);
      });
    },
    fileDownload(fileData)
    {
      this.isLoading = true;
      var request_data = {
        file: fileData.item.file,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        tdfk_cd: fileData.item.tdfk_cd,
        tosi_cd: fileData.item.tosi_cd,
        daigaku_cd: fileData.item.daigaku_cd,
        meisai_no: fileData.item.meisai_no
      };
      apiService.fileDownload(request_data).then(result => {
        this.errorStatus = false;
        this.download(result,fileData.item.file);
      }).catch(error => {
        if(error == undefined) {
          this.errorStatus = true;
          this.error_message = this.$messages.MSG['E1027'];
          this.error_no = 'E1027';
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          return
        }
        this.blogErrorMessages(error);
      });
    },
    download(result, fileName) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      }
    },
    blogErrorMessages(error){
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      ) {
          errormessage = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              error.data = JSON.parse(reader.result);
              resolve(Promise.reject(error));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.data);
          })
          .then(err => {
            console.log(err.data)
          });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        if(error.data.status=='error'){
            this.errorStatus = true;
            this.error_message = error.data.message.replace("|","\n");
            this.error_no = error.data.message_no;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      });
    },
    onFileChange(e, fileData) {
      this.import_file = e.target.files[0];
      // document.getElementById(fileData.index).innerHTML = this.import_file.name;
      this.list_info[fileData.index].file = this.import_file.name;
      this.list_info[fileData.index].import_file = this.import_file;
      this.list_info[fileData.index].Disabled_Upload_flg = false;
      var  extension_list = this.$constants.EXTENSION_LIST;
      var ext = this.import_file.name.split('.').pop();
      var filesize = this.import_file.size;
      if(extension_list.indexOf(ext) < 0){
        this.errorStatus = true;
        this.error_no = "E1060";
        this.error_message = this.$messages.MSG["E1060"];
      }else if(filesize > this.uploadFileSize){
        this.errorStatus = true;
        this.error_no = "E1050";
        this.error_message = this.$messages.MSG["E1050"];
      }else {
        this.errorStatus = false;
        this.error_no = "";
        this.error_message = "";
      }

    },
    fileUpload(fileData) {
      this.isLoading = true;
      if(fileData.item.syubetu == null || fileData.item.syubetu == '') {
        this.errorStatus = true;
        this.error_message = this.$messages.MSG['E1014'].replace("{0}","届出種別");
        this.error_no = 'E1014';
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      } else {
        this.errorStatus = false;
        let formData = new FormData();
        let fileindex = fileData.index;
        // formData.append('index_no', fileindex);
        formData.append('index_no', fileData.index);
        console.log(fileData.index);
        console.log(fileindex);
        // formData.append('import_file', this.import_file);
        formData.append("import_file",this.list_info[fileindex].import_file);
        formData.append('login_id', fileData.item.login_id);
        formData.append('nendo',  this.nendo);
        formData.append('kensyu_cd', this.kensyu_cd);
        formData.append('kaisai_no', this.kaisai_no);
        formData.append('tdfk_cd', fileData.item.tdfk_cd);
        formData.append('tosi_cd', fileData.item.tosi_cd);
        formData.append('daigaku_cd', fileData.item.daigaku_cd);
        formData.append('meisai_no', fileData.item.meisai_no);
        formData.append('todokede_type', fileData.item.syubetu);
        console.log(document.getElementById(fileData.index));
        if(this.list_info[fileData.index].file !== this.inputFileLabel) {
          var request_data = {
            nendo: this.nendo,
            kensyu_cd: this.kensyu_cd,
            kaisai_no: this.kaisai_no,
            tdfk_cd: fileData.item.tdfk_cd,
            tosi_cd: fileData.item.tosi_cd,
            daigaku_cd: fileData.item.daigaku_cd,
            meisai_no: fileData.item.meisai_no
          };
          console.log(request_data);
          apiService.PtodokedeFileUpload(request_data)
          .then(result => {
            if(result.status == 'error') {
              this.errorStatus = true;
              this.error_message = result.message.replaceAll("|","\n");
              this.error_no = result.message_no;
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            } else {
              apiService.importFile(formData)
              .then(result => {
                console.log(result);
                if(result.status == 'success') {
                  this.successStatus = true;
                  this.success_message = this.$messages.MSG['I3012'];
                  this.success_message_no = 'I3012';
                  var request_data = {
                    nendo: this.nendo,
                    kensyu_cd: this.kensyu_cd,
                    kaisai_no: this.kaisai_no,
                    tdfk_cd: fileData.item.tdfk_cd,
                    tosi_cd: fileData.item.tosi_cd,
                    daigaku_cd: fileData.item.daigaku_cd,
                    meisai_no: fileData.item.meisai_no,
                    index_no: fileData.index
                  };
                  console.log(request_data);
                  apiService.getJyukoshaTodokede(request_data)
                  .then((result) => {
                    if (result.status == "success") {
                      let index = request_data.index_no;
                      console.log(index);
                      this.list_info[index].torokudate = result.jyukoshatodokede[0].torokudate;
                      this.list_info[index].file = result.jyukoshatodokede[0].file == null ? '' : result.jyukoshatodokede[0].file;
                      this.list_info[index].tdfk_cd = result.jyukoshatodokede[0].tdfk_cd;
                      this.list_info[index].tosi_cd = result.jyukoshatodokede[0].tosi_cd;
                      this.list_info[index].daigaku_cd = result.jyukoshatodokede[0].daigaku_cd;
                      this.list_info[index].meisai_no = result.jyukoshatodokede[0].meisai_no;
                      this.list_info[index].todokede_type = result.jyukoshatodokede[0].syubetu;
                      this.list_info[index].Disabled_input_flg = true;
                      this.list_info[index].Disabled_Upload_flg = true;
                      this.list_info[index].BtnChange_flg = false;
                      this.list_info[index].Disabled_Del_flg = false;
                      this.list_info[index].submission_file = '';
                      this.isLoading = false;
                    } else {
                      this.list_info = [];
                      this.errorStatus = true;
                      this.error_message = result.message.replaceAll("|","\n");
                      this.error_no = result.message_no;
                      this.isLoading = false;
                    }
                  })
                } else {
                  this.errorStatus = true;
                  this.error_message = result.message.replaceAll("|","\n");
                  this.error_no = result.message_no;
                  this.isLoading = false;
                }
                document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              }).catch(error => {
                console.log(error);
                this.isLoading = false;
              });
            }
          }).catch(error => {
            console.log(error);
            this.isLoading = false;
          });
        } else {
          this.errorStatus = true;
          this.error_message = this.$messages.MSG['E1028'];
          this.error_no = 'E1028';
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }
      }
    },
    importFile(formData) {
      console.log(formData.nendo);
      this.isLoading = true;
      apiService.importFile(formData)
      .then(result => {
        console.log(result);
        if(result.status == 'success') {
          this.successStatus = true;
          this.success_message = this.$messages.MSG['I3012'];
          this.success_message_no = 'I3012';
          var request_data = {
            nendo: this.nendo,
            kensyu_cd: this.kensyu_cd,
            kaisai_no: this.kaisai_no,
            tdfk_cd: formData.getJyukoshaTodokede('tdfk_cd'),
            tosi_cd: formData.getJyukoshaTodokede('tosi_cd'),
            daigaku_cd: formData.getJyukoshaTodokede('daigaku_cd'),
            meisai_no: formData.getJyukoshaTodokede('meisai_no'),
            index_no: formData.getJyukoshaTodokede('index_no'),
          };
          console.log(request_data);
          apiService.getJyukoshaTodokede(request_data)
          .then((result) => {
            if (result.status == "success") {
              let index = request_data.index_no;
              console.log(index);
              this.list_info[index].torokudate = result.jyukoshatodokede[0].torokudate;
              this.list_info[index].file = result.jyukoshatodokede[0].file == null ? '' : result.jyukoshatodokede[0].file;
              this.list_info[index].tdfk_cd = result.jyukoshatodokede[0].tdfk_cd;
              this.list_info[index].tosi_cd = result.jyukoshatodokede[0].tosi_cd;
              this.list_info[index].daigaku_cd = result.jyukoshatodokede[0].daigaku_cd;
              this.list_info[index].meisai_no = result.jyukoshatodokede[0].meisai_no;
              this.list_info[index].todokede_type = result.jyukoshatodokede[0].todokede_type;
              this.list_info[index].Disabled_input_flg = true;
              this.list_info[index].Disabled_Upload_flg = true;
              this.list_info[index].BtnChange_flg = false;
              this.list_info[index].Disabled_Del_flg = false;
              this.list_info[index].submission_file = '';
              this.isLoading = false;
            } else {
              this.list_info = [];
              this.errorStatus = true;
              this.error_message = result.message.replaceAll("|","\n");
              this.error_no = result.message_no;
              this.isLoading = false;
            }
          })
        } else {
          this.errorStatus = true;
          this.error_message = result.message.replaceAll("|","\n");
          this.error_no = result.message_no;
          this.isLoading = false;
        }
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    deleteJyukoshaAndFile(item) {
      if(!confirm(this.$messages.MSG["Q0003"])){
        return;
      }
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        login_id: item.login_id,
        tdfk_cd: item.tdfk_cd,
        tosi_cd: item.tosi_cd,
        daigaku_cd: item.daigaku_cd,
        meisai_no: item.meisai_no,
        file: item.file,
        syubetu: item.syubetu,
        torokudate: item.torokudate,
        index_no: item.index_no,
        status_flg: item.status_flg
      };
      apiService.PtodokedeDelete(request_data)
      .then(result => {
        if(result.status == 'success') {
          this.successStatus = true;
          this.success_message = this.$messages.MSG['I3018'];
          this.success_message_no = 'I3018';
          item.file = '';
          item.syubetu = '';
          item.torokudate = '';
          item.status_flg = 0;
          let index = request_data.index_no;
          console.log(index);
          this.list_info[index].Disabled_input_flg = false;
          this.list_info[index].Disabled_Upload_flg = true;
          this.list_info[index].BtnChange_flg = true;
          this.list_info[index].Disabled_Del_flg = true;
          this.list_info[index].submission_file = '';
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        }else {
          this.isLoading = false;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    }
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3 mt-2" id="ptodokede">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="success_message">
          {{ success_message_no }} {{ success_message }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important;" v-if="error_message">
          {{ error_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">受講者：辞退・欠席・変更届登録</h4>

        <div id="mainTable">
          <div class="row">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ viewKensyuName }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">開催日</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ headersection.kaisai_date_start }} ～ {{ headersection.kaisai_date_end }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">推薦機関名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ headersection.case }}</div>
          </div>
        </div>

        <div class="mt-4">
          <td>様式ダウンロード：</td>
          <td width="80"><b-link href="#" class="ml-2" style="text-decoration: underline;" @click="yousikiDownload('jitai')">辞退届</b-link></td>
          <td width="80"><b-link href="#" class="ml-2" style="text-decoration: underline;" @click="yousikiDownload('kesseki')">欠席届</b-link></td>
          <td width="80"><b-link href="#" class="ml-2" style="text-decoration: underline;" @click="yousikiDownload('henko')">変更届</b-link></td>
        </div>
        <div class="row">
          <div class="col">
            <b-table
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="list_info"
              :fields="fields"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
            <template #empty="scope">{{ scope.emptyText }}</template>
            <template #cell(syubetu)="syubetuData">
              <div>
                <select class="custom-select" v-model="syubetuData.item.syubetu" style="width: 100px;">
                  <option value=""></option>
                  <option value="2">辞退届</option>
                  <option value="0">欠席届</option>
                  <option value="1">変更届</option>
                  </select>
              </div>
            </template>
            <template #cell(file)="fileData">
              <div class="form-inline">
                  <div class="custom-file" style="width: 315px">
                    <input
                      type="file"
                      class="custom-file-input"
                      ref="import_file"
                      :id="'file_' + fileData.index"
                      :disabled="fileData.item.Disabled_input_flg == true || fileData.item.status_flg == 1 ? true : false"
                      @change="onFileChange($event,fileData)"
                      @click="(e) => { e.target.value = '' }"
                    />
                    <label
                      v-if="fileData.value !== ''"
                      class="custom-file-label text-left"
                      :for="'file_' + fileData.index"
                      style="width: 315px; display: block"
                      v-html="fileData.value"
                      ></label
                    >
                    <label
                      v-else
                      class="custom-file-label text-left"
                      :for="'file_' + fileData.index"
                      style="width: 315px; display: block"
                      :id="fileData.index.toString()"
                      >{{ inputFileLabel }}</label
                    >
                  </div>
                  <button
                    v-if="fileData.item.BtnChange_flg == false"
                    :class="Btn_LightDark"
                    style="width: 125px"
                    @click="fileDownload(fileData)"
                  >
                    ダウンロード
                  </button>
                  <button
                    v-else
                    class="btn btn-primary shadow ml-1"
                    style="width: 125px"
                    :disabled="fileData.item.Disabled_Upload_flg == true || fileData.item.status_flg == 1 ? true : false"
                    @click="fileUpload(fileData)"
                  >
                    アップロード
                  </button>
                  <!-- <button class="btn btn-secondary shadow ml-1" v-if="fileData.value == ''" @click="deleteJyukoshaAndFile(fileData.item)" disabled>削除</button>
                  <button class="btn btn-secondary shadow ml-1" v-else @click="deleteJyukoshaAndFile(fileData.item)">削除</button> -->
                  <button
                    class="btn btn-secondary shadow ml-1"
                    :disabled="fileData.item.Disabled_Del_flg == true || fileData.item.status_flg == 1 ? true : false"
                    @click="deleteJyukoshaAndFile(fileData.item)"
                  >
                    削除
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-md-left">
            <router-link class="btn btn-secondary shadow" style="width: 140px" :to="{ name: 'PPortal' }">
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style>
#ptodokede .table th,
#ptodokede .table td {
  vertical-align: middle !important;
}
#ptodokede .file-width {
  min-width: 520px;
}
</style>
