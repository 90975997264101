<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
const apiService = new APIService();

  export default {
    name: "TKousiRegist",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        registFlg: '1',
        resend_flg: '',
        isLoading: false,
        fullpage: true,
        passFlg1: true,
        passFlg2: true,
        passFlg3: true,
        login_id: '',
        userKbn: '',
        kousiData: "",
        kousi_name: "",
        kousi_name_kana: "",
        tel: "",
        mail: "",
        shozoku: "",
        yakushoku_name: "",
        daihyou_name: "",
        kinmusaki_post_no: "",
        kinmusaki_address: "",
        souhusaki_post_no: "",
        souhusaki_address: "",
        koukai_memo: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        errors:[],
        error_message:'',
        error_message_no :'',
        errorStatus:false,
        successStatus:false,
        succsssMessageNo:'',
        successMessage:'',
        systemInitialDate: null,
        initial_data: null,
        oldRequestData: {}
      };
    },
    mounted()
    {
      this.initialScreenData();
    },
    methods: {
      initialScreenData: function () {
        this.isLoading = true;
        apiService.getTKousiRegistInitialScreenData()
        .then(result => {
          this.kousiData = result.kousiData;
          this.kousi_name = this.kousiData.kousi_name;
          this.kousi_name_kana = this.kousiData.kousi_name_kana;
          this.tel = this.kousiData.tel;
          this.mail = this.kousiData.mail;
          this.shozoku = this.kousiData.shozoku;
          this.yakushoku_name = this.kousiData.yakushoku_name;
          this.daihyou_name = this.kousiData.daihyou_name;
          this.kinmusaki_post_no = this.kousiData.kinmusaki_post_no;
          this.kinmusaki_address = this.kousiData.kinmusaki_address;
          this.souhusaki_post_no = this.kousiData.souhusaki_post_no;
          this.souhusaki_address = this.kousiData.souhusaki_address;
          this.koukai_memo = this.kousiData.koukai_memo;
          this.login_id = this.kousiData.login_id;
          this.registFlg = this.kousiData.regist_flg;
          this.resend_flg = this.kousiData.resend_flg;
          this.isLoading = false;
          this.setOldRequestData();
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      },
      register: function () {
        this.isLoading = true;
        this.successStatus = false;
        this.errorStatus = false;
        let param = {
          mail: this.mail,
          old_password: this.oldPassword,
          password: this.newPassword,
          password_confirmation: this.confirmNewPassword,
          koukai_memo: this.koukai_memo,
          regist_flg: this.registFlg,
          login_id: this.login_id,
          system_initial_date : this.systemInitialDate
        }
        apiService.TKousiRegistRegister(param)
        .then(result => {
          if(result.status == "success") {
            this.successStatus    = true;
            this.errorStatus      = false;
            this.succsssMessageNo = result.message_no;
            this.successMessage   = result.message;
            this.setOldRequestData();
            if(result.url_cd && result.user_kbn && !alert(this.$messages.MSG["I3002"].replaceAll("|", "\n"))) {
              apiService.logoutUser()
              .then(() => {
                this.isLoading = false;
                this.$router.push({
                  name: 'KeyWordAuth',
                  params: {
                    login_id: this.login_id
                  },
                  query: {
                    url_cd: result.url_cd,
                    user_kbn : result.user_kbn,
                  }
                });
                document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              })
              .catch(error => {
                this.errorMsg = error.message;
                this.errorMsgNo = error.message_no;
                this.isLoading = false;
                document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
              });
              this.registFlg = true;
            } else {
              this.getSystemInitialDate();
            }
          } else {
            this.errorStatus = true;
            this.successStatus = false;
            this.error_message = result.message.replaceAll("|", "\n");
            this.error_message_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }

        })
        .catch(error => {
          this.errorStatus = true
          this.error_message ='';
          this.error_message_no ='';
          if(error.errors) {
            this.errors = error.errors;
          } else {
            this.error_message = error.message;
            this.error_message_no = error.message_no;
          }
          this.isLoading = false;
          document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
        });
      },
      getSystemInitialDate() {
        apiService.GetSystemInitialDate()
          .then(result => {
            if(result.status == 'success'){
              this.systemInitialDate = result.systemdate;
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
        });
      },
      backclick: function () {
        if (this.registFlg == '1') {
          this.$router.push({ name: "TPortal"});
        } else {
          this.$router.push({ name: "TLogin" });
        }
      },
      setOldRequestData() {
        this.oldRequestData 
          = JSON.parse(JSON.stringify({
            mail: this.mail,
            koukai_memo: this.koukai_memo,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            confirmNewPassword: this.confirmNewPassword
          }))
      },
      checkInputChange() {
        var org = JSON.stringify(this.oldRequestData, jsonReplace);
        if (org === '{}') {
          return true;
        }
        var dest = JSON.stringify({
          mail: this.mail,
          koukai_memo: this.koukai_memo,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          confirmNewPassword: this.confirmNewPassword
        }, jsonReplace);
        return org === dest;
      },
    },
    beforeRouteLeave(to, from, next) {
      var input_check = this.checkInputChange();
      var answer = true;
      if (!input_check) {
        answer = window.confirm(this.$messages.MSG['I3005']);
      }
      if (answer) {
        next();
      } else {
        next(false);
      }
    },
  };
  function jsonReplace(key, val) {
    if(typeof val == "string" && val == ""){
      return null;
    }
    return val;
  }
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3">
      <div id="contentTop"></div>
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
      <div class="col-md-12" v-if="successMessage">
        {{ succsssMessageNo }} {{ successMessage }}</div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" v-for="(value,index) in errors" :key="index">
          {{value.slice('')[0].split('/')[1]}} {{value.slice('')[0].split('/')[0]}}
        </div>
        <div class="col-md-12" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div id="mainTable" class="container" style="max-width: 90%;">
        <h4 class="mt-3">講師登録</h4>

        <div class="row mt-4">
          変更がある場合は教職員支援機構までご連絡ください。
        </div>

        <div class="row">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            氏名
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
          ">
            <input
              type="text"
              class="form-control"
              tabindex="1"
              style="width: 400px"
              :value="kousi_name"
              readonly
            />
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            フリガナ
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
          ">
            <input
              type="text"
              class="form-control"
              tabindex="1"
              style="width: 400px"
              :value="kousi_name_kana"
              readonly
            />
          </div>
        </div>

        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            電話番号
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
          ">
            <input
              type="text"
              class="form-control"
              tabindex="1"
              style="width: 400px"
              :value="tel"
              readonly
            />
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            メールアドレス
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
          ">
            <input
              type="text"
              class="form-control bg-required"
              tabindex="1"
              style="width: 400px"
              placeholder="xxxx@xxxx.co.jp"
              v-model="mail"
            />
          </div>
        </div>

        <div class="row">
          <div class="
            mainCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
            row_border
          "
          >
            所属
          </div>

          <div class="col-md-2 col-12">
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
                名称
              </div>
            </div>
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
              役職
              </div>
            </div>
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
              所属長
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12 px-0">
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
            ">
              名称
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="shozoku" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
              row_border
            ">
              役職
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="yakushoku_name" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
              row_border
            ">
              所属長
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="daihyou_name" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="
            mainCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
            row_border
          "
          >
            勤務先
          </div>

          <div class="col-md-2 col-12">
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
                郵便番号
              </div>
            </div>
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
                住所
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12 px-0">
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
            ">
              郵便番号
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="kinmusaki_post_no" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
              row_border
            ">
            住所
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="kinmusaki_address" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="
            mainCell
            col-md-2 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
            row_border
          "
          >
            文書送付先
          </div>

          <div class="col-md-2 col-12">
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
                郵便番号
              </div>
            </div>
            <div class="row d-none d-md-block" style="margin-left: -15px; margin-right: -15px;">
              <div class="
                mainCell
                col-12
                d-flex
                align-items-center
                float-left
                text-center text-md-left
                table_height_sub
                bg-primary
                text-white
                row_border
                border-left
              ">
                住所
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12 px-0">
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
            ">
              郵便番号
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="souhusaki_post_no" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
            <div class="
              col-12
              d-flex
              align-items-center
              float-left
              text-center text-md-left
              table_height_sub
              bg-dark
              text-white
              d-md-none
              row_border
            ">
            住所
            </div>
            <div class="
              subCell
              col-12
              d-flex
              align-items-center
              float-left
              table_height_sub
              text-center text-md-left
              bg-light
              row_border
            ">
              <input v-model="souhusaki_address" type="text" class="form-control" tabindex="1" style="width: 400px" readonly/>
            </div>
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
          メモ
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
            py-2
            px-3
          ">
            <textarea
              class="form-control"
              tabindex="1"
              v-model="koukai_memo"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            ID
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height
            text-center text-md-left
            bg-light
          ">
            <input
              type="text"
              class="form-control"
              tabindex="1"
              style="width: 400px"
              :value="login_id"
              readonly
            />
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
            "
          >
            <font v-if="resend_flg !='1'">旧パスワード</font><font v-else>仮パスワード</font><font color="#ff7777" v-if="resend_flg =='1'">※</font>
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height_sub
            text-center text-md-left
            bg-light
          ">
            <div v-if="passFlg1" style="position: relative">
              <input
                type="password"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="oldPassword"
              />
              <label class="icon-pass" @click="passFlg1 = false">
                <b-icon icon="eye-fill"></b-icon>
              </label>
            </div>
            <div v-else style="position: relative">
              <input
                type="text"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="oldPassword"
              />
              <label class="icon-pass" @click="passFlg1 = true">
                <b-icon icon="eye-slash-fill"></b-icon>
              </label>
            </div>
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            新パスワード
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height_sub
            text-center text-md-left
            bg-light
          ">
            <div v-if="passFlg2" style="position: relative">
              <input
                type="password"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="newPassword"
              />
              <label class="icon-pass" @click="passFlg2 = false">
                <b-icon icon="eye-fill"></b-icon>
              </label>
            </div>
            <div v-else style="position: relative">
              <input
                type="text"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="newPassword"
              />
              <label class="icon-pass" @click="passFlg2 = true">
                <b-icon icon="eye-slash-fill"></b-icon>
              </label>
            </div>
          </div>
        </div>
        <div class="row row_border">
          <div class="
            mainCell
            col-md-4 col-12
            d-flex
            align-items-center
            float-left
            text-center text-md-left
            table_height
            bg-primary
            text-white
          ">
            新パスワード(確認入力)
          </div>
          <div class="
            subCell
            col-md-8 col-12
            d-flex
            align-items-center
            float-left
            table_height_sub
            text-center text-md-left
            bg-light
          ">
            <div v-if="passFlg3" style="position: relative">
              <input
                type="password"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="confirmNewPassword"
              />
              <label class="icon-pass" @click="passFlg3 = false">
                <b-icon icon="eye-fill"></b-icon>
              </label>
            </div>
            <div v-else style="position: relative">
              <input
                type="text"
                class="form-control"
                :class="registFlg == '0' ? 'bg-required' : ''"
                tabindex="1"
                style="width: 400px"
                autocomplete="new-password"
                v-model="confirmNewPassword"
              />
              <label class="icon-pass" @click="passFlg3 = true">
                <b-icon icon="eye-slash-fill"></b-icon>
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div class="col-12">
            パスワードは、数字と英字まじりの８～３０文字を設定してください。
          </div>
          <div class="col-12" style="color: red" v-if="resend_flg =='1'">
            ※仮パスワードを入力してください。
          </div>
        </div>
        <div class="w-100 text-center text-md-right mb-2">
          <button class="btn btn-primary d-inline-block" style="width: 100px" @click="register">
            登録
          </button>

          <button class="btn btn-secondary d-inline-block ml-3" style="width: 100px" @click="backclick">
            戻る
          </button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .border-left {
    border-left: 1px solid #a1a1a1 !important;
  }
</style>
