<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "PKadai",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      nendo: "",
      kensyu_cd: "",
      kaisai_no: "",
      login_id: '',
      headersection: [],
      kadaiUrl: "",
      kadaiandkadaiupload: [],
      detailSectiondisplayitem: [],
      fields1: [
        {
          key: "no",
          label: "No.",
          class: "text-nowrap text-center",
        },
        {
          key: "kadai_name",
          label: "課題名",
          sortable: false,
          thClass: "text-center",
          class: "text-nowrap",
        },
        {
          key: "kadai_file_name",
          label: "提出ファイル",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-left text-nowrap file-width",
        },
        {
          key: "date",
          label: "登録日時",
          sortable: false,
          class: "text-center text-nowrap ",
        },
        {
          key: "simekiri_flg",
          label: "状態",
          sortable: false,
          class: "text-center text-nowrap",
        },
      ],
      fields2: [
        {
          key: "jyuko_no",
          label: "受講者番号",
          class: "text-nowrap text-center",
        },
        {
          key: "jyuko_name",
          label: "受講者",
          sortable: false,
          thClass: "text-center",
          class: "text-nowrap",
        },
      ],
      inputFileLabel: "ファイルを選択してください。",
      recommender_submission_file: "",
      student_submission_file: "",
      successStatus: false,
      success_message_no: "",
      success_message: "",
      errorStatus: false,
      error_no: "",
      error_message: "",
      customFields: [],
      dynamicFields: [],
      tempKadaiFileName: "kadai_file_name",
      uploadFileSize: "",
      viewKensyuName: "",
    };
  },
  mounted() {
    this.login_id = this.$store.state.Header.sessionData['login_id'];

    if(this.$route.params.nendo){
      this.nendo = this.$route.params.nendo;
      this.kensyu_cd = this.$route.params.kensyu_cd;
      this.kaisai_no = this.$route.params.kaisai_no;

      this.$store.commit("PKadai/setNendo", this.nendo);
      this.$store.commit("PKadai/setKensyuCd", this.kensyu_cd);
      this.$store.commit("PKadai/setKaisaiNo", this.kaisai_no);
    } else {
      this.nendo = this.$store.state.PKadai.nendo;
      this.kensyu_cd = this.$store.state.PKadai.kensyu_cd;
      this.kaisai_no = this.$store.state.PKadai.kaisai_no;
    }

    this.getHeaderSection();
    this.getKaidaiSampleFormDownloadURL();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
    Btn_LightDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? "btn btn-outline-secondary-light shadow ml-1" : "btn btn-outline-secondary-dark shadow ml-1";
    },
  },
  methods: {
    clearMessage() {
      this.successStatus = false;
      this.errorStatus = false;
      this.success_message_no = "";
      this.success_message = "";
      this.error_no = "";
      this.error_message = "";
    },
    getHeaderSection() {
      this.isLoading = true;
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        login_id: this.login_id,
      };
      apiService
        .PKadaiGetHeaderSection(request_data)
        .then((result) => {
          this.headersection = result.result[0];
          if(this.headersection.kaisai_name){
            this.viewKensyuName = this.headersection.kensyu_name + '　' + this.headersection.kaisai_name;
          }else {
            this.viewKensyuName = this.headersection.kensyu_name;
          }
          this.uploadFileSize = result.upload_file_size;
          this.getKadaiAndKadaiUpload();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getKaidaiSampleFormDownloadURL() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
      };
      apiService
        .PKadaiGetKaidaiSampleFormDownloadURL(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.kadaiUrl = result.kadaiUrl[0].kadai_url;
          }else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getKadaiAndKadaiUpload() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        recommender_login_id: this.headersection.login_id,
      };
      apiService
        .PKadaiGetKadaiAndKadaiUpload(request_data)
        .then((result) => {
          if (result.status == "success") {
            result.result.forEach((result) => {
              this.kadaiandkadaiupload.push(result);
            });
            this.getDetailSectionDisplayItem();
          } else {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getDetailSectionDisplayItem() {
      var request_data = {
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        tdfk_cd: this.headersection.tdfk_cd,
        tosi_cd: this.headersection.tosi_cd,
        daigaku_cd: this.headersection.daigaku_cd,
        login_id: '',
      };
      apiService
        .PKadaiGetDetailSectionDisplayItem(request_data)
        .then((result) => {
          if (result.status == "success") {
            if (result.maxColCount > 0) {
              for (let index = 1; index <= result.maxColCount; index++) {
                this.fields2.push({
                  key: "kadai_file_name" + index,
                  label: "課題 " + index,
                  sortable: false,
                  thClass: "text-center text-nowrap",
                  tdClass: "text-left text-nowrap file-width",
                });
                this.fields2.push({
                  key: "entry_date" + index,
                  label: "登録日時",
                  sortable: false,
                  class: "text-center text-nowrap ",
                });
                this.fields2.push({
                  key: "simekiri_flg" + index,
                  label: "状態",
                  sortable: false,
                  class: "text-center text-nowrap",
                });
                this.customFields.push("kadai_file_name" + index);
              }
              result.result.forEach((data) => {
                this.detailSectiondisplayitem.push(data);
              });
              this.isLoading = false;
            } else {
              this.fields2.push({
                key: "kadai_file_name1",
                label: "課題 1",
                sortable: false,
                thClass: "text-center text-nowrap",
                tdClass: "text-left text-nowrap file-width",
              });
              this.fields2.push({
                key: "entry_date1",
                label: "登録日時",
                sortable: false,
                class: "text-center text-nowrap ",
              });
              this.fields2.push({
                key: "simekiri_flg1",
                label: "状態",
                sortable: false,
                class: "text-center text-nowrap",
              });
              this.customFields.push("kadai_file_name1");
              this.isLoading = false;
            }
          }else{
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onRecommenderSubmissionFileChange(e, fileData) {
      this.recommender_submission_file = e.target.files[0];
      document.getElementById(
        "recommenderFileLabel" + fileData.index.toString()
      ).innerHTML = this.recommender_submission_file.name;
      this.kadaiandkadaiupload[fileData.index].Disabled_Upload_flg = false;
      var  extension_list = this.$constants.EXTENSION_LIST;
      var ext = this.recommender_submission_file.name.split('.').pop();
      var filesize = this.recommender_submission_file.size;
      if(extension_list.indexOf(ext) < 0){
        this.errorStatus = true;
        this.error_no = "E1060";
        this.error_message = this.$messages.MSG["E1060"];
      }else if(filesize > this.uploadFileSize){
        this.errorStatus = true;
        this.error_no = "E1050";
        this.error_message = this.$messages.MSG["E1050"];
      }else {
        this.errorStatus = false;
        this.error_no = "";
        this.error_message = "";
      }
    },
    clearArray() {
      this.headersection = [];
      this.kadaiandkadaiupload = [];
      this.detailSectiondisplayitem = [];
    },
    uploadRecommenderSubmission(item) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append(
        "recommender_submission_file",
        this.recommender_submission_file
      );
      formData.append("login_id", this.headersection.login_id);
      formData.append("nendo", this.nendo);
      formData.append("kensyu_cd", this.kensyu_cd);
      formData.append("kaisai_no", this.kaisai_no);
      formData.append("oi_no", item.item.oi_no);
      formData.append("taisyo_kbn", item.item.taisyo_kbn);
      formData.append("kadai_file_name", this.recommender_submission_file.name);
      this.clearMessage();
      apiService
        .PKadaiUploadRecommenderSubmission(formData)
        .then((result) => {
          if (result.status == "error") {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          } else {
            this.successStatus = true;
            this.success_message = this.$messages.MSG["I3012"];
            this.success_message_no = "I3012";
            this.isLoading = false;
            this.clearArray();
            this.getHeaderSection();
            this.getKaidaiSampleFormDownloadURL();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    downloadRecommenderSubmission(item) {
      this.isLoading = true;
      var request_data = {
        upload_file_name: item.upload_file_name,
      };

      this.clearMessage();
      apiService
        .PKadaiDownloadRecommenderSubmission(request_data)
        .then((result) => {
          this.download(result, item.kadai_file_name);
        })
        .catch((error) => {
          if (error == undefined) {
            this.errorStatus = true;
            this.error_message = this.$messages.MSG["E1027"];
            this.error_no = "E1027";
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            return;
          }
          this.blogErrorMessages(error);
        });
    },
    download(result, fileName) {
      console.log(fileName);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      }
    },
    blogErrorMessages(error) {
      const blb = new Blob([error], {
        type: "text/application-json",
      });
      const reader = new FileReader();

      // This fires after the blob has been read/loaded.
      reader.addEventListener("loadend", (e) => {
        const text = e.srcElement.result;

        const err = JSON.parse(text);
        this.errorStatus = true;
        this.error_message = err.message.replaceAll("|","\n");
        this.error_no = err.message_no != undefined ? err.message_no : "";
        this.isLoading = false;
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
      // Start reading the blob as text.
      reader.readAsText(blb);
      this.isLoading = false;
    },
    deleteRecommenderSubmission(item) {
      if(!confirm(this.$messages.MSG["Q0003"])){
        return;
      }
      this.isLoading = true;
      var request_data = {
        login_id: this.login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        oi_no: item.item.oi_no,
        recommender_status: true,
        upload_file_name: item.item.upload_file_name,
      };

      this.clearMessage();
      apiService
        .PKadaiDeleteRecommenderSubmission(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.successStatus = true;
            this.success_message = this.$messages.MSG["I3018"];
            this.success_message_no = "I3018";
            this.isLoading = false;
            this.clearArray();
            this.getHeaderSection();
            this.getKaidaiSampleFormDownloadURL();

          }else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onStudentSubmissionFileChange(e, fileData, kadai_file_index) {
      this.student_submission_file = e.target.files[0];
      document.getElementById(
        "studentFileLabel" + fileData.index.toString() + kadai_file_index
      ).innerHTML = this.student_submission_file.name;
      fileData.item['Disabled_Upload_flg' + kadai_file_index] = false;
      var  extension_list = this.$constants.EXTENSION_LIST;
      var ext = this.student_submission_file.name.split('.').pop();
      var filesize = this.student_submission_file.size;
      if(extension_list.indexOf(ext) < 0){
        this.errorStatus = true;
        this.error_no = "E1060";
        this.error_message = this.$messages.MSG["E1060"];
      }else if(filesize > this.uploadFileSize){
        this.errorStatus = true;
        this.error_no = "E1050";
        this.error_message = this.$messages.MSG["E1050"];
      }else {
        this.errorStatus = false;
        this.error_no = "";
        this.error_message = "";
      }
    },
    uploadStudentSubmission(item, kadai_file_index) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("student_submission_file", this.student_submission_file);
      formData.append("login_id", this.detailSectiondisplayitem[item.index].login_id);
      formData.append("nendo", this.nendo);
      formData.append("kensyu_cd", this.kensyu_cd);
      formData.append("kaisai_no", this.kaisai_no);
      formData.append("oi_no", item.item["oi_no" + kadai_file_index]);
      formData.append("taisyo_kbn", item.item["taisyo_kbn" + kadai_file_index]);
      formData.append("kadai_file_name", this.student_submission_file.name);

      this.clearMessage();
      apiService
        .PKadaiUploadStudentSubmission(formData)
        .then((result) => {
          if (result.status == "error") {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          } else {
            this.successStatus = true;
            this.success_message = this.$messages.MSG["I3012"];
            this.success_message_no = "I3012";
            this.isLoading = false;
            this.clearArray();
            this.getHeaderSection();
            this.getKaidaiSampleFormDownloadURL();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    downloadStudentSubmission(item, kadai_file_index) {
      this.isLoading = true;
      var request_data = {
        login_id: this.detailSectiondisplayitem[item.index].login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        taisyo_kbn: '1',
        oi_no: item.item["oi_no" + kadai_file_index],
        recommender_status: false,
      };

      this.clearMessage();
      apiService
        .PKadaiDownloadStudentSubmission(request_data)
        .then((result) => {
          this.download(result, item.item["kadai_file_name" + kadai_file_index]);
        })
        .catch((error) => {
          if (error == undefined) {
            this.errorStatus = true;
            this.error_message = this.$messages.MSG["E1027"];
            this.error_no = "E1027";
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            return;
          }
          this.blogErrorMessages(error);
          this.isLoading = false;
        });
    },
    deleteStudentSubmission(item, kadai_file_index) {
      if(!confirm(this.$messages.MSG["Q0003"])){
        return;
      }
      this.isLoading = true;
      var request_data = {
        login_id: this.detailSectiondisplayitem[item.index].login_id,
        nendo: this.nendo,
        kensyu_cd: this.kensyu_cd,
        kaisai_no: this.kaisai_no,
        oi_no: item.item["oi_no" + kadai_file_index],
        taisyo_kbn: item.item["taisyo_kbn" + kadai_file_index],
      };
      this.clearMessage();
      apiService
        .PKadaiDeleteStudentSubmission(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.successStatus = true;
            this.success_message = this.$messages.MSG["I3018"];
            this.success_message_no = "I3018";

            this.isLoading = false;
            this.clearArray();
            this.getHeaderSection();
            this.getKaidaiSampleFormDownloadURL();
          }else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    downloadKadaiURL() {
      var request_data = {
        kaidai_url: this.kadaiUrl,
      };
      this.clearMessage();
      apiService
        .PKadaiDownloadSampleForm(request_data)
        .then((result) => {
          this.download(result, "フォームサンプル.xlsx");
        })
        .catch((error) => {
          if (error == undefined) {
            this.errorStatus = true;
            this.error_message = this.$messages.MSG["E1027"];
            this.error_no = "E1027";
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            return;
          }
          this.blogErrorMessages(error);
        });
    },
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3 mt-2" id="pkadai">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus = false"
      >
        <div class="col-md-12" style="white-space: pre !important" v-if="success_message">
          {{ success_message_no }} {{ success_message }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus = false"
      >
        <div
          class="col-md-12"
          style="white-space: pre !important"
          v-if="error_message"
        >
          {{ error_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">課題ファイル管理</h4>

        <div id="mainTable">
          <div class="row">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ viewKensyuName }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">開催日</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ headersection.kaisai_date_start }} ～ {{ headersection.kaisai_date_end }}</div>
          </div>
          <div class="row row_border">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">推薦機関名</div>
            <div class="subCell col-md-10 col-12 bg-light text-md-left table_height">{{ headersection.case }}</div>
          </div>
        </div>


        <div class="row mt-4">
          <div class="col col-form-labe px-0">
            推薦者提出用課題
            <a :href="this.kadaiUrl" class="ml-2 float-right"
              >様式ダウンロード</a
            >
          </div>
        </div>

        <div class="row">
          <div class="col px-0">
            <b-table
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="kadaiandkadaiupload"
              :fields="fields1"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #cell(kadai_file_name)="item">
                <div
                  style="width: 315px"
                  class="custom-file"
                >
                  <input
                    type="file"
                    class="custom-file-input"
                    ref="recommender_submission_file"
                    :disabled="item.item.Disabled_input_flg == true || item.item.delete_status == 1 ? true : false"
                    @change="onRecommenderSubmissionFileChange($event, item)"
                    @click="(e) => { e.target.value = '' }"
                  />
                  <label
                    v-if="item.value !== ''"
                    class="custom-file-label text-left"
                    :for="item.index"
                    style="width: 315px"
                    v-html="item.value"
                    ></label
                  >
                  <label
                    v-else
                    class="custom-file-label text-left"
                    :for="item.index"
                    style="width: 315px"
                    :id="'recommenderFileLabel' + item.index.toString()"
                    >{{ inputFileLabel }}</label
                  >
                </div>
                <button
                  v-if="item.item.BtnChange_flg == false"
                  :class="Btn_LightDark"
                  style="width: 125px"
                  @click="downloadRecommenderSubmission(item.item)"
                >
                  ダウンロード
                </button>
                <button
                  v-else
                  class="btn btn-primary shadow ml-1"
                  style="width: 125px"
                  :disabled="item.item.Disabled_Upload_flg == true || item.item.delete_status == 1 ? true : false"
                  @click="uploadRecommenderSubmission(item)"
                >
                  アップロード
                </button>
                <button
                  class="btn btn-secondary shadow ml-1"
                  :disabled="item.item.Disabled_Del_flg == true || item.item.delete_status == 1 ? true : false"
                  @click="deleteRecommenderSubmission(item)"
                >
                  削除
                </button>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col col-form-labe px-0">受講者提出用課題</div>
        </div>

        <div class="row">
          <div class="col px-0">
            <b-table
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="detailSectiondisplayitem"
              :fields="fields2"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
            <template #empty="scope">{{ scope.emptyText }}</template>
              <template
                v-slot:[`cell(${customField})`]="item"
                v-for="customField in customFields"
              >
                <div :key="customField">
                  <div
                    style="width: 315px"
                    :class="
                      item.value !== '' ||
                      item.item[
                        'delete_status' +
                          customField.split('kadai_file_name')[1]
                      ] == 1
                        ? 'custom-file disable-div'
                        : 'custom-file'
                    "
                  >
                    <input
                      type="file"
                      class="custom-file-input"
                      ref="student_submission_file"
                      @change="
                        onStudentSubmissionFileChange(
                          $event,
                          item,
                          customField.split('kadai_file_name')[1].toString()
                        )
                      "
                      :disabled="item.item['Disabled_input_flg' +
                                  customField.split('kadai_file_name')[1]] == true || item.item.delete_status == 1 ? true : false"
                      @click="(e) => { e.target.value = '' }"
                    />
                    <label
                      v-if="item.value !== ''"
                      class="custom-file-label text-left"
                      :for="item.index"
                      style="width: 315px"
                      v-html="item.value"
                      ></label
                    >
                    <label
                      v-else
                      class="custom-file-label text-left"
                      :for="item.index"
                      style="width: 315px"
                      :id="
                        'studentFileLabel' +
                        item.index.toString() +
                        customField.split('kadai_file_name')[1].toString()
                      "
                      >{{ inputFileLabel }}</label
                    >
                  </div>
                  <button
                    v-if="item.item['BtnChange_flg' + customField.split('kadai_file_name')[1]] == false"
                    :class="Btn_LightDark"
                    style="width: 125px"
                    @click="
                      downloadStudentSubmission(
                        item,
                        customField.split('kadai_file_name')[1]
                      )
                    "
                  >
                    ダウンロード
                  </button>
                  <button
                    v-else
                    class="btn btn-primary shadow ml-1"
                    style="width: 125px"
                    :disabled="item.item['Disabled_Upload_flg' +
                                  customField.split('kadai_file_name')[1]] == true || item.item.delete_status == 1 ? true : false"
                    @click="
                      uploadStudentSubmission(
                        item,
                        customField.split('kadai_file_name')[1]
                      )
                    "
                  >
                    アップロード
                  </button>
                  <button
                    class="btn btn-secondary shadow ml-1"
                    :disabled="item.item['Disabled_Del_flg' +
                                  customField.split('kadai_file_name')[1]] == true || item.item.delete_status == 1 ? true : false
                    "
                    @click="
                      deleteStudentSubmission(
                        item,
                        customField.split('kadai_file_name')[1]
                      )
                    "
                  >
                    削除
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            課題をアップロードする際は、１ファイルずつ「参照」し、都度「アップロード」ボタンを押下してください。<br />
            提出済の課題を再度アップロードしたい時は、提出済の課題を削除してからアップロードしてください。
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-md-left">
            <router-link
              class="btn btn-secondary shadow"
              style="width: 140px"
              :to="{ name: 'PPortal' }"
            >
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  #pkadai .table th,
  #pkadai .table td {
    vertical-align: middle !important;
  }
  #pkadai .file-width {
    min-width: 520px !important;
  }
  .disable-div {
    pointer-events: none;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .info-row {
    line-height: 50px;
  }
</style>
