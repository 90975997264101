<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();

export default {
  name: "SKadai",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      kadaiUrl: '',
      kadaiInfo: [],
      kensyu_name: '',
      fields: [
        {
          key: "no",
          label: "No.",
          class: "text-nowrap text-center",
        },
        {
          key: "kadai_name",
          label: "課題名",
          sortable: false,
          thClass: "text-center",
          class: "text-nowrap",
        },
        {
          key: "file",
          label: "提出ファイル",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-left text-nowrap file-width",
        },
        {
          key: "entry_date",
          label: "登録日",
          sortable: false,
          class: "text-center text-nowrap ",
        },
        {
          key: "status",
          label: "状態",
          sortable: false,
          class: "text-center text-nowrap",
        },
      ],
      inputFileLabel: "ファイルを選択してください。",
      submission_file: "",
      uploadFileSize: "",
      successStatus: false,
      success_message_no: "",
      success_message: "",
      errorStatus: false,
      error_no: "",
      error_message: "",
      customFields: [],
      kadai_url: "",
      tempKadaiFileName: "kadai_file_name",
      current_index: "0",
    };
  },
  mounted() {
    this.getKensyuName();
    this.getKadaiInfo();
    this.getYoshikiDownloadURL();
  },
  computed: {
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
  },
  methods: {
    clearMessage() {
      this.successStatus = false;
      this.errorStatus = false;
      this.success_message_no = "";
      this.success_message = "";
      this.error_no = "";
      this.error_message = "";
    },
    clearArray() {
      this.kadaiInfo = [];
    },
    getKensyuName() {
      this.isLoading = true;
      apiService
        .SKadaiGetKensyuName()
        .then((result) => {
          if (result.status == "success") {
            this.kensyu_name = result.result[0].kaisai_name != null ? result.result[0].kensyu_name + '　' + result.result[0].kaisai_name : result.result[0].kensyu_name;
          } else {
            this.kensyu_name = '';
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getKadaiInfo(oi_no = null, isUpdate = true) {
      this.isLoading = true;
      var request_data = {
        oi_no: oi_no,
      };
      apiService
        .SKadaiGetKadaiInfo(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.uploadFileSize = result.upload_file_size;
            if(!oi_no){
              this.kadaiInfo = result.result;
              let i = 0;
              for (i = 0; 0 < this.kadaiInfo.length; i++) {
                if (this.kadaiInfo[i].file == null) {
                  this.kadaiInfo[i].Disabled_input_flg = false;
                  this.kadaiInfo[i].Disabled_Upload_flg = true;
                  this.kadaiInfo[i].BtnChange_flg = true;
                  this.kadaiInfo[i].Disabled_Del_flg = true;
                  this.kadaiInfo[i].submission_file = '';
                  this.kadaiInfo[i].col_no = i;
                } else {
                  this.kadaiInfo[i].Disabled_input_flg = true;
                  this.kadaiInfo[i].Disabled_Upload_flg = false;
                  this.kadaiInfo[i].BtnChange_flg = false;
                  this.kadaiInfo[i].Disabled_Del_flg = false;
                  this.kadaiInfo[i].submission_file = '';
                  this.kadaiInfo[i].col_no = i;
                }
              }
            } else {
              let index = this.current_index;
              this.kadaiInfo[index].kadai_name = result.result[0].kadai_name;
              this.kadaiInfo[index].entry_date = result.result[0].entry_date;
              this.kadaiInfo[index].file = result.result[0].file == null ? '' : result.result[0].file;
              this.kadaiInfo[index].upload_file_name = result.result[0].upload_file_name;
              this.kadaiInfo[index].status = result.result[0].status;
              this.kadaiInfo[index].status_flg = result.result[0].status_flg;
              this.kadaiInfo[index].oi_no = result.result[0].oi_no;
              this.kadaiInfo[index].taisyo_kbn = result.result[0].taisyo_kbn;
              if(isUpdate){
                this.kadaiInfo[index].Disabled_input_flg = true;
                this.kadaiInfo[index].Disabled_Upload_flg = true;
                this.kadaiInfo[index].BtnChange_flg = false;
                this.kadaiInfo[index].Disabled_Del_flg = false;
                this.kadaiInfo[index].submission_file = '';
              } else {
                this.kadaiInfo[index].Disabled_input_flg = false;
                this.kadaiInfo[index].Disabled_Upload_flg = true;
                this.kadaiInfo[index].BtnChange_flg = true;
                this.kadaiInfo[index].Disabled_Del_flg = true;
                this.kadaiInfo[index].submission_file = '';
              }

              this.isLoading = false;
            }
          } else {
            this.kadaiInfo = [];
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onFileChange(e, fileData) {
      this.submission_file = e.target.files[0];
      this.kadaiInfo[fileData.index].file = this.submission_file.name;
      this.kadaiInfo[fileData.index].submission_file = this.submission_file;
      this.kadaiInfo[fileData.index].Disabled_Upload_flg = false;
      var extension_list = this.$constants.EXTENSION_LIST;
      var ext = this.submission_file.name.split('.').pop();
      var filesize = this.submission_file.size;
      if(extension_list.indexOf(ext) < 0){
        this.errorStatus = true;
        this.error_no = "E1060";
        this.error_message = this.$messages.MSG["E1060"];
      }else if(filesize > this.uploadFileSize){
        this.errorStatus = true;
        this.error_no = "E1050";
        this.error_message = this.$messages.MSG["E1050"];
      }else {
        this.errorStatus = false;
        this.error_no = "";
        this.error_message = "";
      }
    },
    upload(item) {
      this.isLoading = true;
      let formData = new FormData();
      this.current_index = item.col_no;
      formData.append("submission_file",this.kadaiInfo[this.current_index].submission_file);
      formData.append("oi_no", item.oi_no);
      formData.append("taisyo_kbn", item.taisyo_kbn);
      formData.append("kadai_file_name", this.kadaiInfo[this.current_index].file);
      formData.append("status", true);
      this.clearMessage();
      apiService
        .SKadaiUpload(formData)
        .then((result) => {
          if (result.status == "error") {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          } else {
            this.successStatus = true;
            this.success_message = result.message.replaceAll("|","\n");
            this.success_message_no = result.message_no;
            this.getKadaiInfo(item.oi_no, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    downloadKadai(item) {
      this.isLoading = true;
      var request_data = {
        upload_file_name: item.upload_file_name,
      };
      this.clearMessage();
      apiService
        .SKadaiDownloadKadai(request_data)
        .then((result) => {
          var checkKadaiExt = item.file.split('.').pop();
          var checkUploadExt = item.upload_file_name.split('.').pop();
          //if kadai_file_name ext is null
          if(checkKadaiExt == checkUploadExt) {
            this.download(result, item.file);
          } else {
            var fileExt = item.upload_file_name.split('.').pop();
            this.download(result, item.file + '.' + fileExt);
          }
        })
        .catch((error) => {
          this.blogErrorMessages(error);
        });
    },
    getYoshikiDownloadURL() {
      apiService
        .SKadaiGetYoshikiDownloadURL()
        .then((result) => {
          if (result.status == "success") {
            this.kadai_url = result.kadaiPath[0].kadai_url;
          } else {
            this.kadai_url = '';
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    deleteKadai(item) {
      this.isLoading = true;
      this.current_index = item.col_no;
      var request_data = {
        oi_no: item.oi_no,
        upload_file_name: item.upload_file_name,
      };
      this.clearMessage();
      apiService
        .SKadaiDelete(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.successStatus = true;
            this.success_message = result.message.replaceAll("|","\n");
            this.success_message_no = result.message_no;
            this.getKadaiInfo(item.oi_no, false);
          }else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    download(result, fileName) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
        window.URL.revokeObjectURL(url);
      }
    },
    blogErrorMessages(error){
      this.isLoading = false;
      let errormessage;
      if (
        error.request.responseType === 'blob' &&
        error.data instanceof Blob &&
        error.data.type &&
        error.data.type.toLowerCase().indexOf('json') != -1
      )
      {
        errormessage = new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };
          reader.onerror = () => {
            reject(error);
          };
          reader.readAsText(error.data);
        })
        .then(err => {
          console.log(err.data)
        });
      }
      errormessage.then(success=>{
        console.log(success)
      }).catch(error=>{
        if(error.data.status=='error'){
          this.errorStatus = true;
          this.error_message = error.data.message;
          this.error_no = error.data.message_no;
        }
        if (error.status == 500) {
          this.errorStatus = true;
          this.errorMessage = this.$messages.MSG['E1027'];
          this.errorNo = 'E1027';
          this.successStatus = false;
        }
        document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
      });
    },
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <div id="contentTop"></div>
    <main class="mb-3 mt-2" id="skadai">
      <b-alert
        dismissible
        fade
        :show="successStatus"
        @dismissed="successStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="success_message">
          {{ success_message_no }} {{ success_message }}
        </div>
      </b-alert>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important;" v-if="error_message">
          {{ error_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">受講者課題提出</h4>

        <div id="mainTable" class="row info-row text-center text-md-left border-bottom">
          <div class="mainCell col-12 col-md-2 bg-primary text-white">
            <label for class="col-form-label">研修名</label>
          </div>
          <div class="subCell col-12 col-md-10 bg-light">
            <label for class="col-form-label">{{kensyu_name}}</label>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col col-form-labe px-0">
            受講者提出用課題
            <a v-bind:href="kadai_url" target="_blank" class="ml-2 float-right">様式ダウンロード</a>
          </div>
        </div>

        <div class="row">
          <div class="col px-0">
            <b-table
              id="bTable"
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="kadaiInfo"
              :fields="fields"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #cell(file)="fileData">
                <div class="form-inline" style="width: 550px;">
                  <div class="custom-file" style="width: 350px;">
                    <input
                      type="file"
                      class="custom-file-input"
                      ref="submission_file"
                      :id="'file_' + fileData.index"
                      :disabled="fileData.item.Disabled_input_flg == true || fileData.item.status_flg == 1 ? true : false"
                      @change="onFileChange($event, fileData)"
                      @click="(e) => { e.target.value = '' }"
                    />
                    <label
                      v-if="fileData.value !== ''"
                      class="custom-file-label text-left"
                      :for="'file_' + fileData.index"
                      style="width: 350px; display: block"
                      v-html="fileData.value"
                      ></label
                    >
                    <label
                      v-else
                      class="custom-file-label text-left"
                      :for="'file_' + fileData.index"
                      style="width: 350px; display: block"
                      :id="'assignmentFileLabel' + fileData.index.toString()"
                      >{{ inputFileLabel }}</label
                    >
                  </div>
                  <button
                    v-if="fileData.item.BtnChange_flg == false"
                    class="btn btn-outline-secondary shadow ml-1"
                    style="width: 125px"
                    @click="downloadKadai(fileData.item)"
                  >
                    ダウンロード
                  </button>
                  <button
                    v-else
                    class="btn btn-primary shadow ml-1"
                    style="width: 125px"
                    :disabled="fileData.item.Disabled_Upload_flg == true || fileData.item.status_flg == 1 ? true : false"
                    @click="upload(fileData.item)"
                  >
                    アップロード
                  </button>
                  <button
                    class="btn btn-secondary shadow ml-1"
                    :disabled="fileData.item.Disabled_Del_flg == true || fileData.item.status_flg == 1 ? true : false"
                    @click="deleteKadai(fileData.item)"
                  >
                    削除
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            課題をアップロードする際は、１ファイルずつ「参照」し、都度「アップロード」ボタンを押下してください。<br />
            提出済の課題を再度アップロードしたい時は、提出済の課題を削除してからアップロードしてください。
          </div>
        </div>
        <div class="row">
          <div class="col text-center text-md-left">
            <router-link class="btn btn-secondary shadow" style="width: 140px" :to="{ name: 'SPortal' }">
              戻る
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>

  #skadai .table th,
  #skadai .table td {
    vertical-align: middle !important;
  }
  #skadai .file-width {
    min-width: 520px;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .info-row {
    line-height: 50px;
  }
  </style>
