<script>
  import AppLayout from "@/components/layouts/Layout.vue";
  import Loading from "@/components/layouts/Loading.vue";
  import { APIService } from "@/apiService.js";
  const apiService = new APIService();
  import moment from 'moment';
  //import axios from 'axios';
  export default {
    name: "TPortal",
    components: {
      AppLayout,
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullpage: true,
        fields1: [
          {
            key: "kensyuname",
            label: "開催名",
            sortable: true,
            class: "text-nowrap",
          },
          {
            key: "kaisai_no",
            label: "開催番号",
            thClass: 'd-none',
            tdClass: 'd-none'
          },
          { key: "kaisaikijitsu", label: "開催期日", sortable: true, class: "text-nowrap",},
          {
            key: "jyuko_teiin",
            label: "受講定員",
            sortable: true,
            class: "text-nowrap",
          },
          { key: "kaisaibasyo", label: "開催場所", sortable: true, class: "text-nowrap",},
          { key: "kadaihensyu", label: "課題参照", sortable: true, class: "text-nowrap",},
        ],
        kensyu_list: {},
        kaisai_data: [],
        login_id: '',
        fields2: [
          {
            key: "kozaname",
            label: "講座名",
            sortable: true,
          },
          {
            key: "meisai_no",
            label: "講座番号",
            thClass: 'd-none',
            tdClass: 'd-none'
          },
          {
            key: "koza_type",
            label: "講座タイプ",
            thClass: 'd-none',
            tdClass: 'd-none'
          },
          {
            key: "type_kbn",
            label: "タイプ内区分",
            thClass: 'd-none',
            tdClass: 'd-none'
          },
          { key: "jisshibi", label: "実施日", sortable: true },
        ],
        yousiki_no: '',
        sonota_yousiki_no: [],
        portal_info: [],
        selected: {
          nendo: '',
          kensyu_cd: ''
        },
        kadaiInfo: {},
        yousikiData: {},
        kozaData: [],
        error_message:'',
        error_message_no :'',
        errorStatus:false,
        hiddenYousikiNo:[],
        selectedKaisaiNo:""
      };
    },
    mounted() {
      this.selected.nendo = this.$route.params.nendo;
      this.selected.kensyu_cd = this.$route.params.kensyu_cd;
      this.login_id = this.$store.state.Header.sessionData['login_id'];
      this.getKensyuList();
      this.getPortalInfo();
    },
    methods: {
      getKensyuList() {
        this.isLoading = true;
        // screen login id
        apiService.getKensyuData()
          .then(result => {
            this.kensyu_list = result.kensyuData;
            if (this.selected.nendo == null && this.selected.kensyu_cd == null) {
              this.selected.nendo = this.kensyu_list[0].nendo;
              this.selected.kensyu_cd = this.kensyu_list[0].kensyu_cd;
            }
            this.getKaisaiInfo();
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
          });
      },
      getKaisaiInfo() {
        this.isLoading = true;
        let kaisai_data = [];
        let $index = 0;
        let kensyu_name;
        let kaisai_date;
        let jyuko_su;
        var request_data = {
          nendo: this.selected.nendo,
          kensyu_cd: this.selected.kensyu_cd
        }
        apiService.getKaisaiData(request_data)
          .then(result => {
            result.kaisaiData.forEach(function(data) {
              if(data.kaisai_name == null) {
                kensyu_name = data.kensyu_name;
              } else {
                kensyu_name = data.kaisai_name;
              }
              if(data.kaisai_date_start != null){
                data.kaisai_date_start = moment(data.kaisai_date_start).format('YYYY/MM/DD');
              }
              if(data.kaisai_date_end != null){
                data.kaisai_date_end = moment(data.kaisai_date_end).format('YYYY/MM/DD');
              }
              kaisai_date = data.kaisai_date_start + '(' +data.kaisai_date_start_jp_day+ ')~' + data.kaisai_date_end
              +'(' +data.kaisai_date_end_jp_day+ ')';
              if(data.jyuko_su1 == 0) {
                jyuko_su = data.jyuko_su2;
              } else {
                jyuko_su = data.jyuko_su1;
              }
              kaisai_data[$index] = {
                'kensyuname' : kensyu_name,
                'kaisai_no' : data.kaisai_no,
                'kaisaikijitsu' : kaisai_date,
                'jyuko_teiin'    : jyuko_su,
                'kaisaibasyo': data.kaisai_basyo
              }
              $index++;
            });
            this.kaisai_data = kaisai_data;
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.err = error.message.replaceAll("|","\n")
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          });
      },
      kaidaiInfo(data) {
        this.isLoading = true;
        var request_data = {
          nendo: this.selected.nendo,
          kensyu_cd: this.selected.kensyu_cd,
          kaisai_no: data.item.kaisai_no
        }
        apiService.getKadaiInfo(request_data)
          .then(result => {
            this.kadaiInfo = result.kadaiInfo;
            if (this.kadaiInfo) {
              this.$store.commit("TPortal/setNendo", this.selected.nendo);
              this.$store.commit("TPortal/setKensyuCd", this.selected.kensyu_cd);
              this.$store.commit("TPortal/setKaisaiNo", data.item.kaisai_no);
              this.isLoading = false;
              this.$router.push({
                name: 'TKADAI'
              });
            } else {
              this.errorStatus = true
              this.error_message = result.message.replaceAll("|","\n");
              this.error_message_no = result.message_no;
              this.isLoading = false;
              document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
            }
          })
          .catch(error => {
            console.log(error)
            this.err = error.message.replaceAll("|","\n")
            this.isLoading = false;
          });
      },
      rowClicked(item) {
        this.fields2 = this.fields2.slice(0,5);
        this.kozaData = [];
        this.getYousikiData(item);
      },
      getKozaData(item) {
        let koza_data = [];
        let $index=0;
        var request_data = {
          login_id: this.login_id,
          nendo: this.selected.nendo,
          kensyu_cd: this.selected.kensyu_cd,
          kaisai_no: item.kaisai_no
        }
        let hiddenYousikiNo = this.hiddenYousikiNo;
        apiService.getKozaData(request_data)
        .then(result => {
          result.kozaData.forEach(function(data) {
             koza_data[$index] = {
                    'kozaname': data.koza_name,
                    'meisai_no': data.meisai_no,
                    'koza_type': data.koza_type,
                    'type_kbn': data.type_kbn,
                    'jisshibi': moment(data.jissi_date).format('YYYY/MM/DD')+'('+data.jissi_date_jp_day+')'+data.koza_time_start.slice(0,2)+":"+data.koza_time_start.slice(2)+"~"+data.koza_time_end.slice(0,2)+":"+data.koza_time_end.slice(2),
                    'kyodakusyo': data.yousiki_no,
                    'yousiki': data.sonota_yousiki_no
             };
              // check array sonota_yousiki_no includes header yousiki_no
              if(data.sonota_yousiki_no)
              {
                let sonota_yousiki_no = data.sonota_yousiki_no.replace(/\s+/g, "");
                let i = 1;
                hiddenYousikiNo.forEach(function(value){
                  let yousikiname = 'yosiki'+i;
                  koza_data[$index][yousikiname] = null;
                  if(sonota_yousiki_no.split(",").includes(value.toString())){
                    koza_data[$index][yousikiname] = hiddenYousikiNo.indexOf(value)==i?value:null;
                  }
                  i++;
                });
              }
              $index++;
          });
          this.kozaData = koza_data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      },
      getYousikiData(item) {
        this.isLoading = true;
        let yousiki_data = [];
        let $index=1;
        let key;
        let label;
        let field = this.fields2;
        var request_data = {
          login_id: this.login_id,
          nendo: this.selected.nendo,
          kensyu_cd: this.selected.kensyu_cd,
          kaisai_no: item.kaisai_no
        }
        this.selectedKaisaiNo= item.kaisai_no
        let vm = this;
        apiService.getYousikiData(request_data)
        .then(result => {
          if(result.status == 'success'){
          this.yousikiData = result.yousikiData;
            result.yousikiData.forEach(function(data) {
              if(data.yosuiki_kbn == '0'){
                field.push({'key': 'kyodakusyo', 'label': '許諾書', sortable: true });
              }
              else{
                yousiki_data[$index] = {
                  'key' : 'yosiki' + $index,
                  'label' : data.yousiki_name
                };
                key = yousiki_data[$index]['key'];
                label = yousiki_data[$index]['label'];
                field.push({'key': key, 'label': label, sortable: true });
              }
              vm.hiddenYousikiNo[$index]=data.yousiki_no;
              $index++;
            });
          }
          this.getKozaData(item)
        })
        .catch(error => {
          console.log(error)
          this.err = error.message.replaceAll("|","\n")
          this.isLoading = false;
        });
      },
      getPortalInfo() {
        let portalInfo = [];
        let $index = 0;
        let kensyuName = "";
        let $name_flg = 0;
        var request_data = {login_id: this.login_id};
        apiService.getPortalInfo(request_data)
        .then(result => {

          if(result.status=='success' && result.portalInfo.length > 0) {
              result.portalInfo.forEach(function(data) {
                if($index == 0 || kensyuName != data.kensyu_name){
                  $name_flg = 1;
                }
                portalInfo[$index] = {
                  'kensyu_name' : data.kensyu_name,
                  'keisai_date_start' : data.keisai_date_start,
                  'keisai_date_start_jp_day' : data.keisai_date_start_jp_day,
                  'message' : data.message,
                  'link_name' : data.link_name,
                  'file_name' : data.file_name,
                  'name_flg' : $name_flg,
                }
                kensyuName = data.kensyu_name;
                  $name_flg = 0;
                $index++;
              });
            this.portal_info = portalInfo;
          }else {
            this.portal_info = result;
          }
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
          this.err = error.message.replaceAll("|","\n")
        });
      },
      clickKyodakusyo(row){
        this.$store.commit("TPortal/setNendo", this.selected.nendo);
        this.$store.commit("TPortal/setKensyuCd", this.selected.kensyu_cd);
        this.$store.commit("TPortal/setKaisaiNo",this.selectedKaisaiNo);
        this.$store.commit("TPortal/setKozaNo",row.item.meisai_no)
        this.$store.commit("TPortal/setKozaType",row.item.koza_type)
        this.$store.commit("TPortal/setTypeKbn",row.item.type_kbn)
        this.$store.commit("TPortal/setYoushikoNo",row.item.kyodakusyo)
        this.$store.commit("TPortal/setYushikiKbn",0)
        this.$router.push({ name: 'TYOSIKI' });
      },
      clickYosiki(row){
        this.$store.commit("TPortal/setNendo", this.selected.nendo);
        this.$store.commit("TPortal/setKensyuCd", this.selected.kensyu_cd);
        this.$store.commit("TPortal/setKaisaiNo",this.selectedKaisaiNo);
        this.$store.commit("TPortal/setKozaNo",row.item.meisai_no)
        this.$store.commit("TPortal/setKozaType",row.item.koza_type)
        this.$store.commit("TPortal/setTypeKbn",row.item.type_kbn)
        this.$store.commit("TPortal/setYoushikoNo",row.value)
        this.$store.commit("TPortal/setYushikiKbn",1)
        this.$router.push({ name: 'TYOSIKI' });
      },
      downloadItem ({ file_name }) {
        this.isLoading = true;
        var request_data = {
          file_name
        }
        apiService.getDownloadFile(request_data).then(result => {
            var newBlob = new Blob([result], {type: 'blob'})
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob)
              this.isLoading = false;
              return
            }
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = file_name
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 100);
            this.isLoading = false;
        }).catch(error=>{
          console.log(error)
          this.isLoading = false;
        });
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      isDark () {
        return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
      },
      Btn_LightDark () {
        return this.$store.state.Header.backGroundColor === "secondary" ? "btn btn-md btn-outline-primary-light shadow w-100" : "btn btn-md btn-outline-primary-dark shadow w-100";
      },
    },
  };
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3">
      <div id="contentTop"></div>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">
          {{ error_message_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3">{{selected.nendo}}年度 教職員支援機構主催研修実施予定</h4>

        <div id="mainTable">
          <div class="row border-bottom">
            <div class="mainCell col-md-2 col-12 bg-primary text-white table_height">研修名</div>
            <div class="subCell col-md-10 col-12 bg-light text-center text-md-left table_height" style="align-items: center; display: flex">
              <select class="custom-select form-control" v-model="selected" @change="getKaisaiInfo()">
                <option v-for="kensyulist in kensyu_list" v-bind:key="kensyulist.kensyu_cd" v-bind:value="{ nendo: kensyulist.nendo, kensyu_cd: kensyulist.kensyu_cd }">{{ kensyulist.nendo + '年度：' + kensyulist.kensyu_name }}</option>
              </select>
            </div>
          </div>

          <div class="row mt-4">
            <b-table bordered hover sticky-header="280px" :items="kaisai_data" :fields="fields1" :head-variant="'dark'" small striped
              :dark="isDark" class="web-stacked-table" selectable @row-clicked="rowClicked" select-mode="single" responsive show-empty :empty-text="$messages.MSG['I0008']">
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template v-slot:cell(kadaihensyu)="data">
                <div class="text-left text-md-center px-0">
                  <button :class="Btn_LightDark" @click="kaidaiInfo(data)">詳細</button>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col font-weight-bold text-center text-md-left pl-0">
            <h5>講座情報</h5>
          </div>
        </div>
        <div class="row overflow-auto mb-3">
          <b-table bordered hover :items="kozaData" :fields="fields2" :head-variant="'dark'" small striped stacked="md"
            :dark="isDark" class="web-stacked-table overflow-auto mb-0" show-empty :empty-text="$messages.MSG['I0008']">
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #cell(kyodakusyo)="rowKyodakusyo">
              <div class="text-left text-md-center px-0" v-show="rowKyodakusyo.value">
                <button :class="Btn_LightDark+' d-inline'" @click="clickKyodakusyo(rowKyodakusyo)">編集</button>
              </div>
            </template>

            <template v-slot:[`cell(yosiki${k})`]="data" v-for="k in this.hiddenYousikiNo.length">
              <div class="text-left text-md-center px-0" v-show="data.value" :key="k">
                <button :class="Btn_LightDark+' d-inline'" @click="clickYosiki(data)">編集</button>
              </div>
            </template>

          </b-table>
        </div>

        <div class="row mb-4">
          <div v-if="portal_info.status == 'error'" class="text-left font-weight-bold">お知らせ</div>
          <div class="col-12 p-0" v-for="(info,index) in portal_info" v-bind:key="index">
            <div v-if="info.kensyu_name" class="text-left font-weight-bold">お知らせ({{info.kensyu_name}})</div>
            <!-- <div v-if="info.name_flg == '1'" class="text-left font-weight-bold">お知らせ({{info.kensyu_name}})</div> -->
            <div class="text-left ml-2">
              <span v-if="info.kensyu_name">{{info.keisai_date_start}}({{info.keisai_date_start_jp_day}})掲載・・・・・{{info.message}}</span>
              <a class="ml-2" style="text-decoration: underline"
                v-if="info.link_name" v-bind:href="info.file_name" @click.prevent="downloadItem(info)">{{info.link_name}}</a>
            </div>
          </div>
            <div>
              <span v-if="portal_info.status == 'error'">{{portal_info.message}}</span>
            </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .edit-button {
    text-align: left;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .tportal-title {
    font-size: 1.625rem;
  }

  #tportal th,
  #tportal td {
    white-space: nowrap;
    vertical-align: middle !important;
  }

  @media (max-width: 767.98px) {

    #tportal .table-bordered th,
    #tportal .table-bordered td {
      border: 0px;
      border-bottom: 1px solid #dee2e6;
      line-height: 40px;
    }

    #tportal td {
      white-space: normal;
    }

    #tportal td div {
      display: flex !important;
    }
  }
</style>
