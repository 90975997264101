
const defaultState = {
    nendo:'',
    kensyu_cd :'',
    kaisai_no :'',
    meisai_no :'',
    koza_type:'',
    type_kbn :'',
    youshiki_no:'',
    yosuiki_kbn: ''
};
  export default {
    namespaced: true,
    state: defaultState,
    mutations: {
      setNendo(state, result) {
        state.nendo = result;
      },
      setKensyuCd(state, result) {
        state.kensyu_cd = result;
      },
      setKaisaiNo(state, result) {
        state.kaisai_no = result;
      },
      setKozaNo(state, result) {
        state.meisai_no = result;
      },
      setKozaType(state, result) {
        state.koza_type = result;
      },
      setTypeKbn(state, result) {
        state.type_kbn = result;
      },
      setYoushikoNo(state, result) {
        state.youshiki_no = result;
      },
      setYushikiKbn(state, result) {
        state.yosuiki_kbn = result;
      }
    },
    actions : {
    },
    getters : {
    }
  };