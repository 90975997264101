<template>
  <div class="AppLayout aaa">
    <!-- PC用 -->
    <Header class="d-none d-lg-block" />
    <!-- スマホ用 -->
    <HeaderS class="d-block d-lg-none" />
    <div id="bodyDiv" style="overflow-y: auto">
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
  import Header from "@/components/layouts/Header.vue";
  import HeaderS from "@/components/layouts/HeaderS.vue";
  import Footer from "@/components/layouts/Footer.vue";

  export default {
    name: "Applayout",
    components: {
      Header,
      HeaderS,
      Footer,
    }
  };
</script>

<style scoped>
  .aaa {
    height: 100%;
    min-height: 100vh;
  }
</style>
