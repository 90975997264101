const MSG = {

    //########################################################################
    //### 情報メッセージ                                                   ###
    //########################################################################

    'I0008': "対象のデータは0件です。",
    'I0115': '{0}の終了日が開始日以前の日付になっています。|前日泊か、駐車場・バス利用のみ利用できます。',
    'I3001': 'パスワードの変更を行ってください。',
    'I3002': 'ご登録いただいているメールアドレスにキーワードを送信しました。|キーワード認証画面でキーワード認証を実施してください。',
    'I3003': 'パスワードを変更しました。',
    'I3004': '資料のダウンロードを開始します。',
    'I3005': '入力した情報は破棄されます。よろしいですか。',
    'I3006': '入力した情報を登録します。よろしいですか。',
    'I3007': '「アップロード」ボタンを押下することでファイルのアップロードを実行します。',
    'I3008': '推薦担当者情報を登録しました。',
    'I3009': '確定登録を行いました。',
    'I3010': '仮登録を行いました。',
    'I3011': '入力した情報を登録します。確定後は「仮登録」と「確定」が出来なくなります。よろしいですか。',
    'I3012': 'ファイルのアップロードが完了しました。',
    'I3013': '成果活用アンケートの回答を登録しました。',
    'I3014': '入力した情報を登録します。登録は一度のみで変更は出来ません。よろしいですか。',
    'I3015': 'お知らせはございません。',
    'I3016': 'ダウンロードできるファイルはございません。',
    'I3017': '講座アンケートの回答を登録（更新）しました。',
    'I3018': 'アップロードしたファイルを削除しました。',
    'I3019': 'メールアドレスを更新します。よろしいですか。',
    'I3021': 'キーワード認証が完了しました。|ログイン画面に遷移します。',
    'I3100': '初回ログイン時は、担当者登録が必須です。',
    'I3022': '管理者によって宿泊{0}されています。|宿泊情報の変更は出来ず、駐車場・バス利用のみ変更出来ます。',
    'I3023': '現在入力されている研修受講者データがアップロードしたデータで上書きされます。よろしいですか。',
    'I3024': 'サーバーとの接続が切れたため、入力した情報は送信されずに破棄され、|ログイン画面に戻ります。|入力した情報をコピーしたい場合は、キャンセルを選択してください。',
    'I3025': 'サーバーとの接続が切れたため、ログイン画面へ遷移しました。|直前の操作は実行されていない為ご注意ください。',


    //########################################################################
    //### 警告メッセージ                                                   ###
    //########################################################################

    'K_userId': 'ユーザIDは',
    'K_passWord': 'パスワードは',
    'K_keyWord': 'キーワードは',
    'K_oldPassWord': '旧パスワードは',
    'K_newPassWord1': '新パスワードは',
    'K_newPassWord2': '新パスワード（確認入力）は',
    'K_tantoName': '担当者氏名は',
    'K_tantoKana': 'フリガナは',
    'K_zipNo': '所属郵便番号は',
    'K_address': '所属所在地は',
    'K_syozokuName': '所属名称は',
    'K_tel': '所属電話番号は',
    'K_telBiko': '所属電話番号(備考用)は',
    'K_fax': '所属FAX番号',
    'K_mail': 'メールアドレス',
    'K_tosiCd': '都道府県市名は',
    'K_jyukoName': '受講者名は',
    'K_jyukoKana': '受講者名カナは',
    'K_age': '年齢は',
    'K_seibetuCd': '性別は',
    'K_syokuCd': '所属職名１は',
    'K_syozokuSyoku2': '所属職名２は',
    'K_syozokuZipNo': '所属郵便番号は',
    'K_syozokuAddress': '所属住所は',
    'K_syozokuKikanSyuCd': '所属機関種別は',
    'K_hanasi': '話し方は',
    'K_susume': '進め方は',
    'K_setumei': '講座の内容は',
    'K_sougou': '総合評価は',
    'K_parkingRiyoDateS': '駐車場利用期間開始は',
    'K_parkingRiyoDateE': '駐車場利用期間終了は',
    'K_carNo1': '車のナンバー(地名)は',
    'K_carNo2': '車のナンバー(分類番号)は',
    'K_carNo3': '車のナンバー(ひらがな)は',
    'K_carNo4': '車のナンバー(車両番号)は',
    'K_syozokuTel': '勤務先TELは',
    'K_syozokuFax': '勤務先FAXは',
    'K_sykuType': '部屋タイプ選択は',
    'K_fileName': 'ファイル名は',
    'K_comment': 'コメントは',
    'K_kaizenYobo': '改善要望は',


    //########################################################################
    //### エラーメッセージ                                                   ###
    //########################################################################

    'E0062': '入力された{0}は存在しません。',
    'E0069': '予約情報が入力されていません。|宿泊タイプ、駐車場利用、復路直通バス利用のいずれかは入力する必要があります。',
    'E1001': 'ユーザＩＤが存在しないかパスワードが間違っています。',
    'E1002': '該当アカウントは無効です。',
    'E1003': '入力されたIDのアカウントはロックされています。',
    'E1004': 'キーワードが間違っています。',
    'E1005': 'パスワード有効期限が過ぎています。',
    'E1006': '新パスワードと新パスワード（確認入力）が一致しません。',
    'E1007': '新パスワードには「半角数字」「半角英字」混じりの8～40桁の文字を入力してください。',
    'E1008': '旧パスワードと新パスワードは違うパスワードを入力してください。',
    'E1009': '課題が登録されていません。',
    'E1010': '受講者登録受付期間外です。',
    'E1011': '講座アンケートが登録されていません。',
    'E1012': '成果活用アンケートが登録されていません。',
    'E1013': '成果活用アンケートは行えません。',
    'E1014': '{0}入力必須です。',
    'E1015': '{0}全角カタカナ以外は入力できません。',
    'E1016': '郵便番号が正しい形式でないか使用できない文字が含まれています。',
    'E1017': '電話番号が正しい形式でないか使用できない文字が含まれています。',
    'E1018': 'FAX番号が正しい形式でないか使用できない文字が含まれています。',
    'E1019': 'メールアドレスが正しい形式でないか使用できない文字が含まれています。',
    'E1020': 'このメールアドレスは登録できません。',
    'E1021': '旧パスワードが間違っています。',
    'E1022': 'アップロードするファイル名は必須です。',
    'E1023': '推薦者提出用課題が無いか、教職員支援機構にて未登録です。',
    'E1024': '受講者提出用課題が無いか、教職員支援機構にて未登録です。',
    'E1025': 'ファイルのアップロードに失敗しました。',
    'E1026': 'ファイルが研修受講者推薦用フォーマットではありません。',
    'E1027': 'ファイルのダウンロードに失敗しました。',
    'E1028': 'アップロードするファイルが存在しません。',
    'E1029': '対象ファイルが存在しません。',
    'E1030': 'すでにアップロード済みです。',
    'E1031': '{0}全て全角文字で入力してください。',
    'E1032': '{0}全て半角文字で入力してください。',
    'E1033': 'キーワードが発行されています。ログインし直してください。',
    'E1034': 'メールの送信に失敗しました。',
    'E1035': '最大登録行数を超えています。',
    'E1036': '{0}日付の形式として正しくありません。',
    'E1037': '{0}全て全角数字で入力してください。',
    'E1038': '復路直通バス利用と駐車場利用はどちらか一方しか利用できません。',
    'E1039': '他で施設予約登録（更新）が行われました。画面を戻ってやり直してください。',
    'E1040': '入力項目に誤りがあります。前画面に戻ります。',
    'E1041': '予約受付期間外です。',
    'E1042': '不正な処理が行われました。ログインからやり直してください。',
    'E1043': '駐車場利用期間の開始日より終了日が前の日付になっています。',
    'E1044': '駐車場利用期間が研修期間から外れています。',
    'E1045': '選択した宿泊室タイプは満室です。選択し直してください。',
    'E1046': '駐車場は満車のため利用できません。',
    'E1047': '開催期間外のため、アンケート回答登録は出来ません。',
    'E1048': '他でアンケートの回答登録（更新）が行われました。画面を戻ってやり直して下さい。',
    'E1049': '他で更新が行われました。画面を戻ってやり直してください。',
    'E1050': 'ファイルサイズが制限を超えています。',
    'E1051': '{0}全て半角数字で入力してください。',
    'E1052': 'アンケートは既に回答済みのため登録はできません。',
    'E1053': '削除するファイルが存在しません。',
    'E1054': '一つ以上の項目を選択してください。',
    'E1055': '対象データがありません。',
    'E1056': '{0}入力文字数(桁数)が制限を超えています。',
    'E1057': '講座アンケートは締切られました。',
    'E1060': '許可されていない拡張子のファイルです',
    'E1100': '施設予約は登録されていません。',
    'E1114': '{0}は現在アカウント作成を受け付けておりません。',
    'E1110': '講座アンケートは実施しておりません。',
    'E9003': '処理が異常終了しました。',

    //########################################################################
    //### 質問メッセージ                                               ###
    //########################################################################
    "Q0003" : "削除します。よろしいですか？",
    "Q0129" : "宿泊室タイプが選択されていません。|このまま進めると宿泊施設の予約が行われませんが、よろしいですか？",

  };

  export default {
    MSG
  }
