<script>
import AppLayout from "@/components/layouts/Layout.vue";
import Loading from "@/components/layouts/Loading.vue";
import { APIService } from "@/apiService.js";
import moment from 'moment';
const apiService = new APIService();

export default {
  name: "SSTAYMANUAL",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      screenData:{
        manage: [],
        screen: [],
        yoyakuCnt: 0
      },
      registFlag: false,
      errors:[],
      error_message:'',
      error_message_no :'',
      errorStatus:false,
      systemInitialDate:'',
    };
  },
  mounted() {
    moment.locale("ja");
    this.getInitialScreenData();
    this.getSystemInitialDate();
  },
  methods: {
    clickRegist: function(){
      if(this.screenData.yoyakuCnt == 0){
        this.$router.push({
          name: "SSTAYREGIST",
        });
      }
      else{
        this.$router.push({
          name: "SSTAYPRINT",
        });
      }

    },
    getInitialScreenData(){
      this.isLoading = true;
      apiService.SStayManualInitialScreenData()
        .then(result => {
              if(result.status == 'success'){
                this.screenData.manage = result.manage
                this.screenData.screen = result.screen;
                this.screenData.yoyakuCnt = result.yoyakuCnt;
                this.registFlag = !result.registFlag
              }else if(result.status == 'error'){
                this.errorStatus = true;
                this.error_message_no = result.message_no;
                this.error_message = result.message;
                this.registFlag = true;
              }else{
                this.screenData.yoyakuCnt = 0;
              }
              this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
      });
    },
    getSystemInitialDate(){
        apiService.GetSystemInitialDate()
          .then(result => {
                if(result.status == 'success'){
                  this.systemInitialDate = result.systemdate;
                }else {
                  this.isLoading = false;
                }
            })
            .catch(error => {
              console.log(error)
              this.isLoading = false;
        });
    }
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="mb-3">
      <b-alert variant="danger" dismissible fade :show="errorStatus" @dismissed="errorStatus=false">
        <div class="col-md-12" style="white-space: pre !important" v-if="error_message">{{ error_message_no }} {{ error_message }}</div>
        <div class="col-md-12" style="white-space: pre !important" v-for="error in errors" :key="error.index">
          {{error.slice('')[0].split('/')[1]}} {{error.slice('')[0].split('/')[0]}}
        </div>
      </b-alert>
      <div class="container" style="margin-top: 20px">
        <div class="row">
          <h4>１．予約方法</h4>
        </div>
        <div class="row mx-1" style="margin-top: 10px" v-show="screenData.manage.yoyaku_houho">
          <div class="col-12 manual-content">
            <p v-html="screenData.manage.yoyaku_houho"/>
          </div>
        </div>

        <div class="row" style="margin-top: 40px">
          <h4>２．予約申込期間のお知らせ</h4>
        </div>
        <div class="row mx-1" style="margin-top: 10px" v-show="screenData.screen.kensyu_cd">
          <div class="col-12 manual-content">
            <div class="row" style="margin-top: 10px">
              <div class="col-md-3 col-12">研修コード：</div>
              <div class="col-md-9 col-12 pl-4">{{screenData.screen.kensyu_cd}}</div>
            </div>
            <div class="row" style="margin-top: 10px">
              <div class="col-md-3 col-12">研修名：</div>
              <div class="col-md-9 col-12 pl-4">{{screenData.screen.kaisai_name}}</div>
            </div>
            <div class="row" style="margin-top: 10px">
              <div class="col-md-3 col-12">宿泊申込期間：</div>
              <div class="col-md-9 col-12 pl-4">{{screenData.screen.syku_uke_date}}</div>
            </div>
            <div class="row" style="margin-top: 10px">
              <div class="col-md-3 col-12">研修期間：</div>
              <div class="col-md-9 col-12 pl-4">{{screenData.screen.kaisai_date}}</div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 40px">
          <h4>３．宿泊室、駐車料金(食事代別)</h4>
        </div>
        <div class="row mx-1" style="margin-top: 10px;" v-show="screenData.manage.ryokin_annai">
          <div class="col-12 manual-content">
            <p v-html="screenData.manage.ryokin_annai" />
          </div>
        </div>

        <div class="row" style="margin-top: 40px">
          <h4>４．施設概要</h4>
        </div>
        <div class="row mx-1" style="margin-top: 10px" v-show="screenData.manage.shisetsu_gaiyo">
          <div class="col-12 manual-content">
            <p v-html="screenData.manage.shisetsu_gaiyo"/>
          </div>
        </div>

        <div class="row" style="margin-top: 40px">
          <h4>５．その他</h4>
        </div>
        <div class="row mx-1" style="margin-top: 10px" v-show="screenData.manage.sonota">
          <div class="col-12 manual-content">
            <p v-html="screenData.manage.sonota"/>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-center text-lg-left">
            <button
              class="btn btn-primary shadow"
              role="button"
              style="width: 140px"
              type="button"
              :disabled="registFlag"
              @click="clickRegist()"
            >
              宿泊予約受付へ
            </button>

            <router-link class="btn btn-secondary shadow ml-2" style="width: 140px" :to="{ name: 'SPortal' }">
              戻る
            </router-link>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <p v-html="screenData.manage.toiawase"/>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
  .manual-content {
    border: 1px solid #A5A5A5;
    border-radius: 6px;
  }
</style>
