<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import Loading from "@/components/layouts/Loading.vue";
import moment from "moment";
const apiService = new APIService();
export default {
  name: "PPortal",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      portal_info: "",
      portal_initial_info: null,
      portal_notice: "",
      fields: [
        { key: "kaisai_name", label: "開催名", sortable: true, class: "text-nowrap", },
        { key: "kaisai_date_start", label: "開催期日", sortable: true, class: "text-nowrap",},
        { key: "jyuko_su", label: "受講定員", sortable: true,class: "text-nowrap",},
        { key: "kaisai_basyo", label: "開催場所", sortable: true, class: "text-nowrap",},
        { key: "kadaihensyu", label: "課題編集", sortable: false ,class: "text-nowrap",},
        { key: "jyuko", label: "受講者", sortable: false , class: "text-nowrap",},
        { key: "kigen", label: "届出", sortable: false , class: "text-nowrap",},
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      portalInfo: [],
      portalNotice: [],
      url: "",
      portal_info_edit: "",
      nendo: "",
      kensyu_name: "",
      suisen_kikan_name: "",
      tdfk_cd: "",
      tosi_cd: "",
      daigaku_cd: "",
      jukoshaEditList: "",
      kadaiEditStatus: "",
      errorStatus: false,
      error_message: '',
      error_no: '',
    };
  },
  mounted() {
    this.getPortalInfoDownload();
    this.getPortalInfoList();
  },
  methods: {
    getPortalInfoDownload() {
      this.isLoading = true;
      apiService
        .portalInfoDownload()
        .then((result) => {
          this.portalInfo = result;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
      apiService
        .portalDownloadNotice()
        .then((result) => {
          if (result.status == "error") {
            this.portalNotice = result;
          } else {
            this.portalNotice = result;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getPortalInfoList() {
      apiService
        .portalinfolist()
        .then((result) => {
          this.portal_initial_info = result.portal_info;
          if(result.portal_info.length > 0){
            this.nendo = result.portal_info[0].nendo;
            this.kensyu_name = result.portal_info[0].kensyu_name;
            this.suisen_kikan_name = result.portal_info[0].suisen_kikan_name;
            this.tdfk_cd = result.portal_info[0].tdfk_cd,
            this.tosi_cd = result.portal_info[0].tosi_cd,
            this.daigaku_cd = result.portal_info[0].daigaku_cd
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    kadaiEditButton(nendo, kensyu_cd, kaisai_no) {
      this.isLoading = true;
      var request_data={
        nendo: nendo,
        kensyu_cd: kensyu_cd,
        kaisai_no: kaisai_no,
      }
      console.log(request_data);
      apiService
        .kadaiEdit(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.errorStatus = false;
            this.isLoading = false;
            this.$router.push({
              name: 'PKadai',
              params: {
                nendo: nendo,
                kensyu_cd: kensyu_cd,
                kaisai_no: kaisai_no,
              }
            })
          } else {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    jukoshaEditButton(acceptance_period, kensyu_cd, kaisai_no) {
      this.isLoading = true;
      var request_data = {
        uke_period: acceptance_period,
      };
      apiService
        .jukoshaEdit(request_data)
        .then((result) => {
          if (result.status == "success") {
            this.jukoshaEditList = result;
            this.errorStatus = false;
            this.$store.commit("PPortal/setKensyuCd", kensyu_cd);
            this.$store.commit("PPortal/setKaisaiNo", kaisai_no);
            this.isLoading = false;
            this.$router.push({
              name: 'PZyukosyaRegist',
              params: {
                kensyu_cd: kensyu_cd,
                kaisai_no: kaisai_no,
              }
            })
          } else {
            this.errorStatus = true;
            this.error_message = result.message.replaceAll("|","\n");
            this.error_no = result.message_no;
            this.isLoading = false;
            document.getElementById('contentTop').scrollIntoView({behavior: 'smooth',block: 'start'});
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    downloadNoticeExcel(file_name) {
      if(!file_name){
        return;
      }
      this.isLoading = true;
      console.log(file_name);
      let name = file_name.split("/");
      let index = name.length-1;
      let fileName = name[index];
      var request_data = {
        file_name: file_name
      }
      apiService
        .exportExcelForNoticeDownload(request_data)
        .then((result) => {
          if (result.type == 'application/json') {
            this.isLoading = false;
            return;
          }
          this.download(result, fileName);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    DownloadExcel(paramForDownload) {
      this.isLoading = true;
      if(paramForDownload.kinou_id == "0001"){
        let vm = this;
        let request_data = {
          'nendo'    : paramForDownload.nendo,
          'kensyuCd' : paramForDownload.kensyu_cd,
          'kaisaiNo' : paramForDownload.kaisai_no,
          'tdfkCd'   : paramForDownload.tdfk_cd,
          'tosiCd'   : paramForDownload.tosi_cd,
          'daigakuCd' : paramForDownload.daigaku_cd,
        }
        apiService.PPportal001exportExcel(request_data)
        .then(result => {
              var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
              vm.download(result,'研修受講決定者名簿_' + date + '.xlsx');
        }).catch(error => {
              this.blogErrorMessages(error);
              this.isLoading = false;
        });
      }
      else if(paramForDownload.kinou_id == "0002"){
        let vm = this;
        let request_data_array = [];
        request_data_array[0] = {
          'tdfkCd'   : paramForDownload.tdfk_cd,
          'tosiCd'   : paramForDownload.tosi_cd,
          'daigakuCd' : paramForDownload.daigaku_cd
        }
        let request_data = {
          'nendo'    : paramForDownload.nendo,
          'kensyuCd' : paramForDownload.kensyu_cd,
          'kaisaiNo' : paramForDownload.kaisai_no,
          request_data_array
        }
        apiService.PTSE030A003exportExcel(request_data)
        .then(result => {
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          vm.download(result,'システム利用通知書（受講者）_' + request_data['kensyuCd'] + '_' + request_data['kaisaiNo'] + '_'+  date + '.xlsx');
        }).catch(error => {
          this.blogErrorMessages(error);
          this.isLoading = false;
        });
      }
      else if(paramForDownload.kinou_id == "0003"){
        let request_data =
        {
            nendo: paramForDownload.nendo,
            kensyuCd: paramForDownload.kensyu_cd,
            kaisaiNo: paramForDownload.kaisai_no,
            tdfkCd: paramForDownload.tdfk_cd,
            tosiCd: paramForDownload.tosi_cd,
            daigakuCd: paramForDownload.daigaku_cd,
        }
        apiService.PPportal002exportExcel(request_data)
        .then(result => {
              var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
              this.download(result,'研修受講修了者名簿_' + date + '.xlsx');
        }).catch(error => {
              console.log(error);
              this.isLoading = false;
        });
      }
    },
    download(result, fileName) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([result]), fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      }
    },
    currentYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    },
    exportPPortal003(){
      let request_data = {
        nendo: this.nendo,
        tdfk_cd: this.tdfk_cd,
        tosi_cd: this.tosi_cd,
        daigaku_cd: this.daigaku_cd
      };
      this.isLoading = true;
      apiService.PPportal003exportExcel(request_data)
        .then(result => {
          this.isLoading = false;
          var date = moment(String(new Date())).format('YYYYMMDDHHmmss');
          this.download(result,'推薦機関別推薦担当者一覧_' + request_data.nendo + '_'+  date + '.xlsx');
        }).catch(error => {
          this.isLoading = false;
          this.blogErrorMessages(error);
        });
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    isDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? false : true;
    },
    Btn_LightDark () {
      return this.$store.state.Header.backGroundColor === "secondary" ? "btn btn-md btn-outline-secondary-light shadow" : "btn btn-md btn-outline-secondary-dark shadow";
    },
  },
};
</script>
<template>
  <AppLayout>
    <Loading :isLoading='isLoading' :fullPage='fullPage' />
    <main class="contents-height">
      <div id="contentTop"></div>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="errorStatus"
        @dismissed="errorStatus=false"
        >
        <div class="col-md-12" style="white-space: pre !important;" v-if="error_message">
          {{ error_no }} {{ error_message }}
        </div>
      </b-alert>
      <div class="container" style="max-width: 90%;">
        <h4 class="mt-3"> {{ nendo }}年度  教職員支援機構主催研修実施予定</h4>

        <div id="mainTable" class="my-3">
          <div class="row border-bottom">
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">研修名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ kensyu_name }}</div>
            <div class="mainCell col-md-2 col-12 bg-primary text-white text-center table_height">推薦機関名</div>
            <div class="subCell col-md-4 col-12 bg-light text-center text-md-left table_height">{{ suisen_kikan_name }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col px-0">
            <b-table
              id="bTable"
              sticky-header="500px"
              bordered
              hover
              striped
              small
              :dark="isDark"
              class="web-stacked-table"
              :items="this.portal_initial_info"
              :fields="fields"
              :head-variant="'dark'"
              show-empty
              :empty-text="$messages.MSG['I0008']"
            >
              <template #empty="scope">{{ scope.emptyText }}</template>
              <template #cell(kadaihensyu)="row">
                <div class="text-left px-0">
                  <span>
                    <b-link
                      :class="Btn_LightDark"
                      style="width: 58px"
                      @click="kadaiEditButton(row.item.nendo, row.item.kensyu_cd, row.item.kaisai_no)"
                    >
                      編集
                    </b-link>
                  </span>
                </div>
              </template>
              <template #cell(jyuko)="row">
                <div class="pl-0 float-left">
                  <span>
                    <b-link
                      :class="Btn_LightDark"
                      style="width: 58px"
                      :disabled="row.item.suisen_simekiri_flg == 1"
                      @click="
                        jukoshaEditButton(
                          row.item.jyuko_uke_date_start,
                          row.item.kensyu_cd,
                          row.item.kaisai_no
                        )
                      "
                    >
                      編集
                    </b-link>
                  </span>
                </div>
                <div class="ml-2 d-inline-block text-left">
                  {{ row.item.jyuko }}
                </div>
              </template>

              <template #cell(kigen)="row">
                <div class="col-md-4 pl-0 float-left">
                  <router-link
                    :class="Btn_LightDark"
                    style="width: 58px"
                    :to="{
                      name: 'Ptodokede',
                      params: {
                        nendo: row.item.nendo,
                        kensyu_cd: row.item.kensyu_cd,
                        kaisai_no: row.item.kaisai_no,
                      },
                    }"
                  >
                    編集
                  </router-link>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="text-right font-weight-bold w-100 float-left">
              <button
                class="btn btn-outline-primary"
                type="button"
                style="width:220px"
                @click="exportPPortal003()">推薦機関別推薦担当者一覧</button>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div>
              <div class="text-left font-weight-bold">お知らせ</div>
            </div>
            <div class="text-left ml-2" v-if="portalNotice.status == 'success'">
              <div class="text-left ml-2" v-for="(portalNoti, index) in portalNotice.portal_notice" v-bind:key="index">
                <span>{{ portalNoti.keisai_date_start }}掲載・・・・・{{portalNoti.message}}</span>
                <b-link class="ml-2" href="#" style="text-decoration: underline;" v-if="portalNoti.link_name" @click="downloadNoticeExcel(portalNoti.file_name)">{{portalNoti.link_name}}</b-link>
              </div>
            </div>
            <div class="text-left ml-2">
              <span v-if="portalNotice.status == 'error'">{{
                portalNotice.message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <div class="text-left font-weight-bold">ダウンロード</div>
            <div class="text-left ml-2" v-if="portalInfo.status == 'success'">
              <div class="text-left ml-2" v-for="(portalInfoItem, index) in portalInfo.portal_info" v-bind:key="index">
                <span>{{ portalInfoItem.keisai_date_start }}掲載・・・・・</span>
                <b-link class="ml-2" href="#" style="text-decoration: underline;" @click="DownloadExcel(portalInfoItem)">{{portalInfoItem.link_name}}</b-link>
              </div>
            </div>
            <span v-if="portalInfo.status == 'error'">{{
              portalInfo.message
            }}</span>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
</style>
