<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: "SSRegMsg",
    data() {
      return {
        modes: ['multi', 'single', 'range'],

      };
    },
    mounted() {
    },
    methods: {
      setClose() {
      },
    },
  });
</script>
<template>
  <main>
    前泊を利用できない宿泊室タイプの為、選択を取り消しました。<br>
    再度宿泊室タイプを選択してください。
    <!-- <b-table sticky-header="450px" bordered hover striped small :items="items" :fields="fields" :head-variant="'dark'" 
      :tbody-tr-class="rowClass" selectable :select-mode="'single'" @row-selected="onRowSelected" show-empty 
      :empty-text="$messages.MSG['I0008']">
      <template #empty="scope">{{ scope.emptyText }}</template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table> -->
  </main>
</template>
