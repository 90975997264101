
const defaultState = {
  kaisai_no: ''
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setKaisaiNo(state, result) {
      state.kaisai_no = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  },
  actions: {
  },
  getters: {
  }
};
