<script>
  export default {
    name: "Footer",
    data() {
      return {
        visible: false,
        visibleFooter: false,
      };
    },
    methods: {
      scrollTop: function () {
        // let app = document.getElementById("app");
        // console.log(app.scrollHeight);
        this.intervalId = setInterval(() => {
          if (window.pageYOffset === 0) {
            clearInterval(this.intervalId);
          }
          window.scroll(0, window.pageYOffset - 50);

        }, 20);
      },
      scrollListener: function () {
        let scrollY = window.scrollY || window.pageYOffset;
        let bodyHeight = document.body.clientHeight // bodyの高さを取得
        let element = document.getElementById("bodyDiv");
        let elementRect = element.getBoundingClientRect();

        this.visible = scrollY > 150;
        this.visibleFooter = (scrollY + bodyHeight - 100) > elementRect.height;
      },
    },
    mounted: function () {
      this.scrollListener();
      window.addEventListener("scroll", this.scrollListener, true);
      window.addEventListener('resize', this.scrollListener); // 一覧のサイズを変更するようresizeイベント処理を登録
    },
    beforeDestroy: function () {
      window.removeEventListener("scroll", this.scrollListener);
      window.removeEventListener("resize", this.scrollListener);
    },
    computed: {
      footerInfo: function () {
        if (this.$route.meta.user_kbn == undefined) {
          return "p-2 footer-copylight";
        }
        if (this.$route.meta.user_kbn == this.$constants.USER_KBN_P) {
          return "p-2 footer-copylight-orange";
        } else if (this.$route.meta.user_kbn == this.$constants.USER_KBN_S) {
          return "p-2 footer-copylight";
        } else {
          return "p-2 footer-copylight-green";
        }
      },
    },
  };
</script>
<template>
  <footer class="text-center footer-height">
    <a @click="scrollTop" v-show="visible" class="bottom-right">
      <a class="btn btn-light">TOP</a>
    </a>
    <div :class="footerInfo" v-show="visibleFooter" class="bottom-footer">
      <span>Copylight&copy; NITS. All Rights Reserved.</span>
    </div>
  </footer>
</template>
<style scoped>
  .bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .bottom-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }

  .btn {
    border-radius: 8px;
    background-color: rgb(201 188 188 / 55%);
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }
</style>
