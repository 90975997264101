<script>
import Loading from 'vue-loading-overlay';

export default {
  props:{
    "isLoading": Boolean,
    "fullPage": Boolean
  },
  components: {
    Loading
  }
}
</script>
<template>
  <loading :active.sync="isLoading" :is-full-page="fullPage">
    <div style="vertical-align: middle;">
      <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
      <h4 style="dipslay:inline-block; float:left; color:#3399F3">処理中...</h4>
    </div>
  </loading>
</template>